import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useClientUser } from '@/hooks';
import { Row, SectionSubtitle2 } from '@/styles/blocks';
import { Grid, TextField } from '@material-ui/core';
import { Body2, OnwardToggle, RadioLabelInfo } from '@/components/ShipmentForm/blocks';
import dateFns from '@/utilities/dateFns';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { asDateInTZ } from '@/utilities/convertToISO';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import DatePickerModal from '../../DatePickerModal';
import { Alert } from '@material-ui/lab';

const DeliveryDate = ({ isDirty, order, hasError, callbacks, opt }) => {
    const { circles } = useClientUser();

    const [openPrefDate, setOpenPrefDate] = useState(false);
    const [openAltDate, setOpenAltDate] = useState(false);
    const [openDelDate, setOpenDelDate] = useState(false);
    const [openDelRangeStart, setOpenDelRangeStart] = useState(false);
    const [openDelRangeEnd, setOpenDelRangeEnd] = useState(false);

    const { zip } = useSwappedAttributes(order);

    const orderTZ = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }, [order, zip]);

    return (
        <>
            <Grid
                item
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <SectionSubtitle2>Delivery Date</SectionSubtitle2>
            </Grid>
            {opt.customerAvailabilityToggle && !circles?.['disable-customer-availability'] && (
                <Row>
                    <Grid
                        container
                        item
                        css={css`
                            flex: 0;
                            flex-basis: 0;
                            justify-content: center;
                        `}
                    >
                        <OnwardToggle
                            css={css`
                                margin: 0;
                            `}
                            value={!order.known_availability}
                            onChange={(e) => {
                                callbacks.modifyOrder({
                                    known_availability: !e.target.checked,
                                    selected_type: e.target.checked ? 'manual' : 'auto',
                                    preferred_delivery_date: null,
                                    alternative_delivery_dates: null,
                                });
                            }}
                        />
                    </Grid>
                    <Grid
                        direction="column"
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-left: 12px;
                            justify-content: center;
                        `}
                    >
                        <Grid container item>
                            <RadioLabelInfo
                                css={css`
                                    color: #000;
                                `}
                            >
                                Reach out to customers via text/email to receive preferred alternative delivery dates.
                            </RadioLabelInfo>
                        </Grid>
                    </Grid>
                </Row>
            )}
            {(order?.known_availability || !opt.customerAvailabilityToggle) && (
                <Row>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={6}>
                            <TextField
                                type="text"
                                variant="outlined"
                                fullWidth
                                label="Preferred Delivery"
                                multiline={false}
                                disabled={opt.customerAvailabilityToggle && !order.known_availability}
                                error={hasError.preferred_delivery_date}
                                value={
                                    order.preferred_delivery_date
                                        ? dateFns.formatDate(new Date(order.preferred_delivery_date), 'MM/dd/yyyy')
                                        : null
                                }
                                onClick={() => {
                                    if (!opt.customerAvailabilityToggle || order.known_availability) {
                                        setOpenPrefDate(true);
                                    }
                                }}
                            />
                            <DatePickerModal
                                open={openPrefDate}
                                handleClose={() => setOpenPrefDate(false)}
                                orderTZ={orderTZ}
                                disabled={opt.customerAvailabilityToggle && !order.known_availability}
                                onChange={(date) => {
                                    callbacks.modifyOrder({
                                        preferred_delivery_date: date,
                                    });
                                    callbacks.makeDirty(['preferred_delivery_date']);
                                    setOpenPrefDate(false);
                                }}
                                selectedDates={order.preferred_delivery_date}
                                maxSelections={1}
                                minDate={
                                    order.first_available_date
                                        ? asDateInTZ(order.first_available_date, orderTZ)
                                        : undefined
                                }
                            />
                            {(!opt.customerAvailabilityToggle || order.known_availability) && (
                                <Body2>Required to send to Onward.</Body2>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="text"
                                variant="outlined"
                                fullWidth
                                label="Alternative Delivery Dates"
                                multiline={false}
                                disabled={opt.customerAvailabilityToggle && !order.known_availability}
                                error={hasError.alternative_delivery_dates}
                                value={
                                    order.alternative_delivery_dates
                                        ? order.alternative_delivery_dates
                                              ?.map((date) => dateFns.formatDate(new Date(date), 'MM/dd/yyyy'))
                                              .join(', ')
                                        : null
                                }
                                onClick={() => {
                                    if (!opt.customerAvailabilityToggle || order.known_availability) {
                                        setOpenAltDate(true);
                                    }
                                }}
                            />
                            {(!opt.customerAvailabilityToggle || order.known_availability) && (
                                <Body2>3 additional dates required to send to Onward.</Body2>
                            )}
                        </Grid>
                        <DatePickerModal
                            open={openAltDate}
                            handleClose={() => setOpenAltDate(false)}
                            orderTZ={orderTZ}
                            disabled={opt.customerAvailabilityToggle && !order.known_availability}
                            onChange={(dates) => {
                                callbacks.modifyOrder({
                                    alternative_delivery_dates: dates,
                                });
                                callbacks.makeDirty(['alternative_delivery_dates']);
                            }}
                            selectedDates={order.alternative_delivery_dates}
                            maxSelections={21}
                            minDate={
                                order.first_available_date ? asDateInTZ(order.first_available_date, orderTZ) : undefined
                            }
                        />
                    </Grid>
                </Row>
            )}
            <Row>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            label="Estimated Delivery Start Date (optional)"
                            multiline={false}
                            value={
                                order.estimated_delivery_range_start
                                    ? dateFns.formatDate(new Date(order.estimated_delivery_range_start), 'MM/dd/yyyy')
                                    : null
                            }
                            onClick={() => {
                                setOpenDelRangeStart(true);
                            }}
                            error={
                                hasError.estimated_delivery_range_start &&
                                (opt.startDirty || isDirty.estimated_delivery_range_start)
                            }
                        />
                        <DatePickerModal
                            open={openDelRangeStart}
                            handleClose={() => setOpenDelRangeStart(false)}
                            orderTZ={orderTZ}
                            onChange={(date) => {
                                callbacks.modifyOrder({
                                    estimated_delivery_range_start: date,
                                });
                                callbacks.makeDirty(['estimated_delivery_range_start']);
                                setOpenDelRangeStart(false);
                            }}
                            selectedDates={order.estimated_delivery_range_start}
                            maxSelections={1}
                            minDate={
                                order.first_available_date ? asDateInTZ(order.first_available_date, orderTZ) : undefined
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            label="Estimated Delivery End Date (optional)"
                            multiline={false}
                            value={
                                order.estimated_delivery_range_end
                                    ? dateFns.formatDate(new Date(order.estimated_delivery_range_end), 'MM/dd/yyyy')
                                    : null
                            }
                            onClick={() => {
                                setOpenDelRangeEnd(true);
                            }}
                            error={
                                hasError.estimated_delivery_range_end &&
                                (opt.startDirty || isDirty.estimated_delivery_range_end)
                            }
                        />
                        <DatePickerModal
                            open={openDelRangeEnd}
                            handleClose={() => setOpenDelRangeEnd(false)}
                            orderTZ={orderTZ}
                            onChange={(date) => {
                                callbacks.modifyOrder({
                                    estimated_delivery_range_end: date,
                                });
                                callbacks.makeDirty(['estimated_delivery_range_end']);
                                setOpenDelRangeEnd(false);
                            }}
                            selectedDates={order.estimated_delivery_range_end}
                            maxSelections={1}
                            minDate={
                                order.first_available_date ? asDateInTZ(order.first_available_date, orderTZ) : undefined
                            }
                        />
                    </Grid>
                </Grid>
            </Row>
            <Row>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            label="Scheduled Delivery Date (optional)"
                            multiline={false}
                            disabled={order.routes?.length}
                            value={
                                order.delivery_date
                                    ? dateFns.formatDate(new Date(order.delivery_date), 'MM/dd/yyyy')
                                    : null
                            }
                            onClick={() => {
                                setOpenDelDate(true);
                            }}
                            error={hasError.delivery_date && (opt.startDirty || isDirty.delivery_date)}
                        />
                        <DatePickerModal
                            open={openDelDate}
                            handleClose={() => setOpenDelDate(false)}
                            orderTZ={orderTZ}
                            onChange={(date) => {
                                callbacks.modifyOrder({
                                    delivery_date: date,
                                });
                                callbacks.makeDirty(['delivery_date']);
                            }}
                            selectedDates={order.delivery_date}
                            maxSelections={1}
                            minDate={
                                order.first_available_date ? asDateInTZ(order.first_available_date, orderTZ) : undefined
                            }
                        />
                        {opt.customerAvailabilityToggle &&
                            !circles?.['disable-customer-availability'] &&
                            order.delivery_date &&
                            !order.known_availability && (
                                <Alert
                                    severity="warning"
                                    css={css`
                                        margin-top: 8px;
                                    `}
                                >
                                    You have opted to collect the customer's availability via text/email. Customer will
                                    not be contacted if a scheduled delivery date is already set here.
                                </Alert>
                            )}
                        {order.routes?.length > 0 && (
                            <Alert
                                severity="warning"
                                css={css`
                                    margin-top: 8px;
                                `}
                            >
                                Cannot change delivery date of an order that is already assigned to a route. Remove the
                                order from the route and send it back to unassigned to edit.
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </Row>
        </>
    );
};

export default DeliveryDate;
