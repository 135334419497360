import { asDateInTZ } from '@/utilities/convertToISO';
import { genAttributes } from '@onward-delivery/core';
import { formatInTimeZone } from 'date-fns-tz';
import { parse } from 'date-fns';
import { startCase } from 'lodash';
import zipcode_to_timezone from 'zipcode-to-timezone';

export const FILTER_ATTRS = [
    'startDate',
    'endDate',
    'schedDelStart',
    'schedDelEnd',
    'estShipStart',
    'estShipEnd',
    'actualReceivedStart',
    'actualReceivedEnd',
    'warehouseStartDate',
    'warehouseEndDate',
    'warehouseStatus',
    'manufacturer',
    'orderType',
    'ownerChip',
    'pickup',
    'dropoff',
    'volume',
    'distance',
    'scheduledStatus',
    'shipper',
    'middleMileStatus',
    'serviceType',
    'locationType',
    'orderStatus',
];

export const CSV_EXPORT_COLUMNS = [
    {
        header: 'Order Number',
        value: ({ order, item }) => {
            return order.order_number;
        },
    },
    {
        header: 'PO Number',
        value: ({ order, item }) => {
            return order.po_number;
        },
    },
    {
        header: 'Shipper',
        value: ({ order, item, revenue, owner }) => {
            return order?.order_shipper?.business_name || '-';
        },
    },
    {
        header: 'Scheduled Date',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return order.delivery_date
                ? formatInTimeZone(asDateInTZ(order.delivery_date, tz), tz, 'EEE, MMM d, yyyy')
                : '';
        },
    },
    {
        header: 'Target Date',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return order.target_delivery_date
                ? formatInTimeZone(asDateInTZ(order.target_delivery_date, tz), tz, 'EEE, MMM d, yyyy')
                : '';
        },
    },
    {
        header: 'Preferred Date',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return order.preferred_delivery_date
                ? formatInTimeZone(asDateInTZ(order.preferred_delivery_date, tz), tz, 'EEE, MMM d, yyyy')
                : '';
        },
    },
    {
        header: 'Alt Preferred Dates',
        value: ({ order, item }) => {
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

            return (order.alternative_delivery_dates || [])
                .map((date) => {
                    return date ? formatInTimeZone(asDateInTZ(date, tz), tz, 'EEE, MMM d, yyyy') : '';
                })
                .filter((val) => val)
                .join(', ');
        },
    },
    {
        header: 'Type',
        value: ({ order, item }) => {
            return item.is_return ? 'Return' : 'Delivery';
        },
    },
    {
        header: 'Item',
        value: ({ order, item }) => {
            const itemInfo = ['item_type_details', 'description', 'sku'];
            return itemInfo
                .map((attr) => item[attr])
                .filter((val) => val)
                .join(' - ');
        },
    },
    {
        header: 'Quantity',
        value: ({ order, item }) => {
            return item.quantity;
        },
    },
    {
        header: 'Weight (lb)',
        value: ({ order, item }) => {
            return item.weight;
        },
    },
    {
        header: 'L (in)',
        value: ({ order, item }) => {
            return item.length;
        },
    },
    {
        header: 'W (in)',
        value: ({ order, item }) => {
            return item.width;
        },
    },
    {
        header: 'H (in)',
        value: ({ order, item }) => {
            return item.height;
        },
    },
    {
        header: 'Origin Name',
        value: ({ order, item }) => {
            return order.order_type === 'return' ? order.dropoff_name : order.pickup_name;
        },
    },
    {
        header: 'Origin Email',
        value: ({ order, item }) => {
            return order.order_type === 'return' ? order.dropoff_email : order.pickup_email;
        },
    },
    {
        header: 'Origin Phone',
        value: ({ order, item }) => {
            return order.order_type === 'return' ? order.dropoff_phone : order.pickup_phone;
        },
    },
    {
        header: 'Origin Address',
        value: ({ order, item }) => {
            return ['pickup_street_address', 'pickup_unit']
                .map((attr) => order[attr])
                .filter((val) => val)
                .join(', ');
        },
    },
    {
        header: 'Origin City',
        value: ({ order, item }) => {
            return order.pickup_city;
        },
    },
    {
        header: 'Origin State',
        value: ({ order, item }) => {
            return order.pickup_state;
        },
    },
    {
        header: 'Origin Zip',
        value: ({ order, item }) => {
            return order.pickup_zip;
        },
    },
    {
        header: 'Destination Name',
        value: ({ order, item }) => {
            return order.order_type !== 'return' ? order.dropoff_name : order.pickup_name;
        },
    },
    {
        header: 'Destination Email',
        value: ({ order, item }) => {
            return order.order_type !== 'return' ? order.dropoff_email : order.pickup_email;
        },
    },
    {
        header: 'Destination Phone',
        value: ({ order, item }) => {
            return order.order_type !== 'return' ? order.dropoff_phone : order.pickup_phone;
        },
    },
    {
        header: 'Destination Address',
        value: ({ order, item }) => {
            return ['dropoff_street', 'dropoff_unit']
                .map((attr) => order[attr])
                .filter((val) => val)
                .join(', ');
        },
    },
    {
        header: 'Destination City',
        value: ({ order, item }) => {
            return order.dropoff_city;
        },
    },
    {
        header: 'Destination State',
        value: ({ order, item }) => {
            return order.dropoff_state;
        },
    },
    {
        header: 'Destination Zip',
        value: ({ order, item }) => {
            return order.dropoff_zip;
        },
    },
    {
        header: 'Order Price',
        value: ({ order, item, revenue, owner }) => {
            if (owner === 'Shipper') {
                return revenue || 0;
            }
            return '-';
        },
    },
    {
        header: 'Order Revenue',
        value: ({ order, item, revenue, owner }) => {
            if (owner === 'Carrier' || owner === 'Internal') {
                return revenue || 0;
            }
            return '-';
        },
    },
    {
        header: 'Type',
        value: ({ order, item, revenue, owner }) => {
            if (owner === 'Shipper') {
                return 'Marketplace Shipper';
            } else if (owner === 'Carrier') {
                return 'Marketplace Carrier';
            } else if (owner === 'Internal') {
                return 'Internal';
            }
            return '-';
        },
    },
    {
        header: 'Consignee Signature Name',
        value: ({ order, item }) => {
            return order.customer_signature_meta?.name || '';
        },
    },
    {
        header: 'Consignee Signature Date',
        value: ({ order, item }) => {
            // return order.customer_signature_meta?.date || '';
            const { zip } = genAttributes(order);
            const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';
            return order.customer_signature_meta?.date
                ? formatInTimeZone(asDateInTZ(order.customer_signature_meta?.date, tz), tz, 'MM/dd/yyyy hh:mm a')
                : '';
        },
    },
];

const validDate = (datestring) => {
    return !datestring || !isNaN(new Date(datestring).valueOf());
};

export const CSV_BULK_UPDATE_COLUMNS = [
    {
        key: 'order_number',
        header: 'Order Number',
        value: (val) => val,
        valid: (row) => true,
        errorMsg: 'Either Order Number or PO Number is required',
    },
    {
        key: 'po_number',
        header: 'PO Number',
        value: (val) => val,
        valid: (row) => row.order_number || row.po_number,
        errorMsg: 'PO Number is required if Order Number not provided',
    },
    {
        key: 'received_date',
        header: 'Received Date',
        value: (val) => (val ? new Date(val).toISOString() : null),
        valid: (row) => validDate(row.received_date),
        errorMsg: 'Received Date must be a valid date',
    },
    {
        key: 'delivery_date',
        header: 'Delivery Date',
        value: (val) => (val ? new Date(val).toISOString() : null),
        valid: (row) => validDate(row.delivery_date),
        errorMsg: 'Delivery Date must be a valid date',
    },
    {
        key: 'del_window_start',
        header: 'Delivery Time Start',
        value: (val, row) =>
            val && row?.delivery_date ? parse(val, 'hh:mm a', new Date(row.delivery_date)).toISOString() : null,
        valid: (row) =>
            !row.del_window_start ||
            !row.del_window_end ||
            new Date(row.del_window_start) <= new Date(row.del_window_end),
        errorMsg: `Delivery Time Start must be a valid time in 'hh:mm a' format and before Delivery Time End`,
    },
    {
        key: 'del_window_end',
        header: 'Delivery Time End',
        value: (val, row) =>
            val && row?.delivery_date ? parse(val, 'hh:mm a', new Date(row.delivery_date)).toISOString() : null,
        valid: (row) =>
            !row.del_window_start ||
            !row.del_window_end ||
            new Date(row.del_window_end) >= new Date(row.del_window_start),
        errorMsg: `Delivery Time End must be a valid time in 'hh:mm a' format and after Delivery Time Start`,
    },
    {
        key: 'ltl_pickup_completed',
        header: 'Pickup Time',
        value: (val) => (val ? parse(val, 'MM/dd/yyyy HH:mm:ss', new Date()).toISOString() : null),
        valid: (row) => validDate(row.ltl_pickup_completed),
        errorMsg: `Pickup Time must be a valid date and time in 'MM/dd/yyyy HH:mm:ss' format`,
    },
    {
        key: 'ltl_dropoff_arrival',
        header: 'Arrival Time',
        value: (val) => (val ? parse(val, 'MM/dd/yyyy HH:mm:ss', new Date()).toISOString() : null),
        valid: (row) => validDate(row.ltl_dropoff_arrival),
        errorMsg: `Arrival Time must be a valid date and time in 'MM/dd/yyyy HH:mm:ss' format`,
    },
    {
        key: 'completion_time',
        header: 'Completion Time',
        value: (val) => (val ? parse(val, 'MM/dd/yyyy HH:mm:ss', new Date()).toISOString() : null),
        valid: (row) => validDate(row.completion_time),
        errorMsg: `Completion Time must be a valid date and time in 'MM/dd/yyyy HH:mm:ss' format`,
    },
];
