import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import FooterComponent from '../Footer';
import * as Sentry from '@sentry/react';
import Form from 'react-bootstrap/Form';
import { getAuth, signOut } from 'firebase/auth';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Card, CardContent, Grid, Button, Tooltip, CircularProgress } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getAnalytics, logEvent } from 'firebase/analytics';
import PhoneNumberInput from '../PhoneNumberInput';
import track from '../../utilities/track';
import withErrorBoundary from '../ErrorBoundary';
import { useNavigate } from 'react-router-dom';
import { postUnauth } from '@/utilities/onwardClient';
import { CREATE_CLIENT } from '@/constants/apiRoutes';
import { Alert, AlertTitle } from '@material-ui/lab';
import { colors } from '@/styles';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import useQuery from '@/utilities/useQuery';
import verifyToken from '../CarrierSignUp/queries/verifyToken';
import { captureException } from '@sentry/react';

const DetailsText = styled.span`
    font-weight: 500;
    font-size: 14px;
`;

const InfoText = styled.span`
    font-weight: 700;
    font-size: 16px;
`;

const ShipperSignUp = () => (
    <>
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            `}
        >
            <div
                css={css`
                    margin-top: 70px;
                `}
            >
                <Grid
                    container
                    direction="row"
                    spacing={4}
                    css={css`
                        margin-bottom: auto;
                        justify-content: center;
                    `}
                >
                    {/* Signup Features */}
                    <Grid
                        item
                        container
                        direction="column"
                        xs={10}
                        sm={5}
                        css={css`
                            max-width: 480px;
                        `}
                    >
                        <Card>
                            <CardContent
                                css={css`
                                    padding: 2rem !important;
                                `}
                            >
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    css={css`
                                        margin-bottom: 40px;
                                    `}
                                >
                                    <InfoText>Sign up now for FREE</InfoText>
                                    <DetailsText>
                                        No credit card required. Pay only when you submit deliveries.{' '}
                                    </DetailsText>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction="column"
                                    css={css`
                                        margin-bottom: 40px;
                                    `}
                                >
                                    <InfoText>Farther and Faster, for Cheaper</InfoText>
                                    <DetailsText>
                                        Onward gets your items delivered faster than ever, while expanding your customer
                                        coverage at the industry’s lowest rates.
                                    </DetailsText>
                                </Grid>

                                <Grid item container direction="column">
                                    <InfoText>Join thousands of businesses</InfoText>
                                    <DetailsText>
                                        Onward gives you instant access to our entire network of professional delivery
                                        crews who are ready to serve you now.
                                    </DetailsText>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Signup Details */}
                    <Grid
                        item
                        container
                        direction="column"
                        xs={10}
                        sm={5}
                        css={css`
                            max-width: 480px;
                        `}
                    >
                        <Card>
                            <CardContent
                                css={css`
                                    padding: 0 !important;
                                `}
                            >
                                <ShipperSignUpFormBase />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <FooterComponent />
        </div>
    </>
);

function ShipperSignUpFormBase(props) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [business, setBusiness] = useState('');
    const [phone, setPhone] = useState('');
    const [fleetSize, setFleetSize] = useState(null);
    const [termsAndConditionsCheck, setTermsAndConditionsCheck] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [termsAndConditionalsModal, setTermsAndConditionsModal] = useState(false);
    const [partnerCarriers, setPartnerCarriers] = useState('');
    const [clientId, setClientId] = useState(null);
    // Checked on events so that each event is only tracked once per user landing
    const [analyticsLogTracker, setAnalyticsLogTracker] = useState({
        landed: false,
        enteredAllInfo: false,
        openedTerms: false,
        acceptedTerms: false,
        clickedSignUp: false,
        signedUpSuccessfully: false,
        errorSigningUp: false,
    });
    const analytics = getAnalytics();

    const navigate = useNavigate();

    const [verify, { loading }] = useQuery(verifyToken, {
        onComplete: (result) => {
            setClientId(result?.verified?.client_id);
            setEmail(result?.verified?.email);
            setBusiness(result?.verified?.name);
        },
        onError: (err) => captureException(err),
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('invite_token');

        if (token?.length > 0) {
            verify(token);
        }
    }, [location?.search]);

    useEffect(() => {
        if (!!passwordOne && !!passwordTwo && passwordOne === passwordTwo) {
            setError({ error: null });
            return;
        }

        if (!passwordOne || !passwordTwo) return;

        const error = { message: 'Password and password confirmation must match' };

        setError(error);
    }, [passwordOne, passwordTwo]);

    useEffect(() => {
        if (!analyticsLogTracker.landed) {
            logEvent(analytics, 'shipper_signup_page_landed', {});
            setAnalyticsLogTracker({ ...analyticsLogTracker, landed: true });
        }
    }, []);

    useEffect(() => {
        let currUser = getAuth()?.currentUser;
        if (currUser) {
            if (currUser.uid === 'anonymous-user') {
                signOut(getAuth());
            } else {
                navigate(ROUTES.ACCOUNT);
            }
        }
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!analyticsLogTracker.clickedSignUp) {
            logEvent(analytics, 'shipper_signup_clicked_sign_up', {
                username: username,
                email: email,
                business: business,
                phone: phone,
            });
            setAnalyticsLogTracker({ ...analyticsLogTracker, clickedSignUp: true });
        }

        try {
            return postUnauth(CREATE_CLIENT, {
                clientType: 'shipper',
                clientId,
                username,
                email,
                business,
                phone,
                fleet_size: fleetSize,
                password: passwordOne,
                partnerCarriers: partnerCarriers.toLowerCase().replace(/\s/g, '').split(','),
            })
                .then(() => {
                    setSuccess(true);
                    track('User Registered', {
                        accountType: 'Shipper',
                    });
                    if (!analyticsLogTracker.signedUpSuccessfully) {
                        logEvent(analytics, 'shipper_signup_successful_signup', {
                            username: username,
                            email: email,
                            business: business,
                            phone: phone,
                        });
                        setAnalyticsLogTracker({ ...analyticsLogTracker, signedUpSuccessfully: true });
                    }
                    window.analytics.identify({
                        username,
                        email,
                        company: business,
                        accountType: 'Shipper',
                        createdAt: new Date().toISOString(),
                    });
                })
                .catch(({ data }) => {
                    if (!analyticsLogTracker.errorSigningUp) {
                        logEvent(analytics, 'shipper_signup_error_signing_up', {
                            username: username,
                            email: email,
                            business: business,
                            phone: phone,
                            error: data?.error,
                        });
                        setAnalyticsLogTracker({ ...analyticsLogTracker, errorSigningUp: true });
                    }
                    setError({ message: data?.error || 'Something went wrong. Please try again.' });
                });
        } catch (err) {
            console.log(err);
            if (!analyticsLogTracker.errorSigningUp) {
                logEvent(analytics, 'shipper_signup_error_signing_up', {
                    username: username,
                    email: email,
                    business: business,
                    phone: phone,
                    error: err,
                });
                setAnalyticsLogTracker({ ...analyticsLogTracker, errorSigningUp: true });
            }
            setError(err);
            Sentry.captureException(err);
        }
    };

    const acceptTerms = () => {
        setTermsAndConditionsCheck(true);
        setTermsAndConditionsModal(false);
    };

    const signupButtonDisabled = useMemo(() => {
        return (
            username === '' ||
            email === '' ||
            passwordOne === '' ||
            passwordTwo === '' ||
            passwordOne !== passwordTwo ||
            business === '' ||
            phone === '' ||
            termsAndConditionsCheck === false ||
            typeof fleetSize !== 'number'
        );
    }, [email, passwordOne, passwordTwo, business, phone, termsAndConditionsCheck, fleetSize]);

    // Logs event for when user enters all sign up info into boxes
    useEffect(() => {
        if (!analyticsLogTracker.enteredAllInfo) {
            const missingInfo =
                username === '' ||
                email === '' ||
                passwordOne === '' ||
                passwordTwo === '' ||
                passwordOne !== passwordTwo ||
                business === '' ||
                phone === '';
            if (!missingInfo) {
                setAnalyticsLogTracker({ ...analyticsLogTracker, enteredAllInfo: true });
                logEvent(analytics, 'shipper_signup_entered_signup_info', {
                    username: username,
                    email: email,
                    business: business,
                    phone: phone,
                });
            }
        }
    }, [email, passwordOne, passwordTwo, business, phone]);

    // Logs event for when user opens terms and conditions
    useEffect(() => {
        if (!analyticsLogTracker.openedTerms && termsAndConditionalsModal) {
            setAnalyticsLogTracker({ ...analyticsLogTracker, openedTerms: true });
            logEvent(analytics, 'shipper_signup_opened_terms_and_conditions', {
                username: username,
                email: email,
                business: business,
                phone: phone,
            });
        }
    }, [termsAndConditionalsModal]);

    // Logs event for when user accepts terms and conditions
    useEffect(() => {
        if (!analyticsLogTracker.acceptedTerms && termsAndConditionsCheck) {
            setAnalyticsLogTracker({ ...analyticsLogTracker, acceptedTerms: true });
            logEvent(analytics, 'shipper_signup_accepted_terms_and_conditions', {
                username: username,
                email: email,
                business: business,
                phone: phone,
            });
        }
    }, [termsAndConditionsCheck]);

    return (
        <>
            <div>
                {success ? (
                    <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        Shipper Account Created! - click <a href="/signin">here</a> to sign in
                    </Alert>
                ) : loading ? (
                    <CircularProgress />
                ) : (
                    <Form onSubmit={(e) => onSubmit(e)}>
                        <Grid
                            container
                            direction="column"
                            spacing={2}
                            css={css`
                                padding: 2rem 2rem 1rem 2rem;
                            `}
                        >
                            <Grid
                                item
                                css={css`
                                    text-align: center;
                                `}
                            >
                                <span
                                    css={css`
                                        font-weight: 800;
                                        font-size: 20px;
                                    `}
                                >
                                    Create your Onward Shipper Account
                                </span>
                            </Grid>

                            <Grid item>
                                <Form.Control
                                    name="username"
                                    type="text"
                                    placeholder="Full Name"
                                    onChange={(e) => setUsername(e.target.value)}
                                    value={username}
                                />
                            </Grid>

                            <Grid item>
                                <Form.Control
                                    name="email"
                                    type="input"
                                    placeholder="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </Grid>

                            <Grid item>
                                <Form.Control
                                    name="business"
                                    type="text"
                                    placeholder="Business Name"
                                    onChange={(e) => setBusiness(e.target.value)}
                                    value={business}
                                />
                            </Grid>

                            <Grid item>
                                <PhoneNumberInput
                                    value={phone}
                                    name={'phone'}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="Phone Number"
                                    className="form-control"
                                />
                            </Grid>

                            <Grid item>
                                <Form.Control
                                    name="fleetSize"
                                    type="text"
                                    placeholder="Number of trucks in fleet"
                                    onChange={(e) => {
                                        let parsed = e.target.value.replace(/[^0-9]/g, '');
                                        if (parsed === '') {
                                            setFleetSize('');
                                        } else {
                                            setFleetSize(Number(parsed));
                                        }
                                    }}
                                    value={fleetSize}
                                />
                            </Grid>

                            <Grid item>
                                <Form.Control
                                    name="passwordOne"
                                    type="password"
                                    placeholder="Password"
                                    onChange={(e) => setPasswordOne(e.target.value)}
                                    value={passwordOne}
                                />
                            </Grid>

                            <Grid
                                item
                                css={css`
                                    margin-bottom: 4px;
                                `}
                            >
                                <Form.Control
                                    name="passwordTwo"
                                    type="password"
                                    placeholder="Confirm Password"
                                    onChange={(e) => setPasswordTwo(e.target.value)}
                                    value={passwordTwo}
                                />
                            </Grid>

                            {/*

                            <Grid
                                item
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                css={css`
                                    margin-bottom: 4px;
                                `}
                            >
                                <Grid item xs={11}>
                                    <Form.Control
                                        name="partnerCarriers"
                                        type="input"
                                        placeholder="Invite Carriers (optional)"
                                        onChange={(e) => setPartnerCarriers(e.target.value)}
                                        value={partnerCarriers}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    css={css`
                                        padding-left: 10px;
                                    `}
                                >
                                    <Tooltip title="Here you can invite carriers that you like to partner with to the Onward platform. Enter their emails here (separated by commas if more than one). We will then send them an invite, and they will be automatically linked with your account once activated.">
                                        <InfoOutlinedIcon fontSize="small" color="success" />
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            */}

                            <Grid item>
                                <Form.Check
                                    name="termsAndConditions"
                                    type="checkbox"
                                    inline
                                    aria-label="checkbox-1"
                                    onChange={(e) => setTermsAndConditionsCheck(!termsAndConditionsCheck)}
                                    checked={termsAndConditionsCheck}
                                    disabled={!termsAndConditionsCheck}
                                />
                                <span>
                                    I have read and agree to the{' '}
                                    <a
                                        css={css`
                                            color: ${colors.greens.primary};
                                            cursor: pointer;
                                        `}
                                        onClick={() => setTermsAndConditionsModal(true)}
                                    >
                                        Terms and Conditions
                                    </a>
                                </span>
                            </Grid>

                            <Grid
                                item
                                css={css`
                                    margin-bottom: 14px;
                                `}
                            >
                                <span
                                    css={css`
                                        color: ${colors.greys.primary};
                                        font-size: 14px;
                                        font-weight: 500;
                                    `}
                                >
                                    You must open the Terms and Conditions to accept.
                                </span>
                            </Grid>

                            <Grid item>
                                <Button
                                    onClick={(e) => onSubmit(e)}
                                    disabled={signupButtonDisabled}
                                    css={css`
                                        width: 100%;
                                        background-color: ${signupButtonDisabled
                                            ? colors.greys.subtitle
                                            : colors.greens.primary} !important;
                                        color: ${signupButtonDisabled
                                            ? colors.white.secondary
                                            : colors.white.primary} !important;
                                    `}
                                >
                                    <span
                                        css={css`
                                            font-weight: 700;
                                            font-size: 14px;
                                        `}
                                    >
                                        Sign Up
                                    </span>
                                </Button>
                            </Grid>

                            {error?.message && (
                                <Grid item>{error && <p style={{ color: 'red' }}>{error.message}</p>}</Grid>
                            )}

                            <Grid
                                item
                                css={css`
                                    text-align: center;
                                    margin-top: 14px;
                                `}
                            >
                                <span
                                    css={css`
                                        font-weight: 500;
                                        font-size: 14px;
                                        color: ${colors.greys.secondary};
                                    `}
                                >
                                    Already have an account?{' '}
                                    <Link
                                        to={ROUTES.SHIPPER_SIGN_IN}
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                    >
                                        <b>Sign In</b>
                                    </Link>
                                </span>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    margin-bottom: 14px;
                                `}
                            >
                                <span
                                    css={css`
                                        color: ${colors.greys.primary};
                                        font-size: 14px;
                                        font-weight: 500;
                                    `}
                                >
                                    * By providing a telephone number and submitting the form you are consenting to be
                                    contacted by SMS text message. Message & data rates may apply. Reply STOP to opt out
                                    of further messaging.
                                </span>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </div>
            <TermsAndConditionsModal
                open={termsAndConditionalsModal}
                acceptTerms={acceptTerms}
                onClose={() => setTermsAndConditionsModal(false)}
            />
        </>
    );
}

export default compose(withErrorBoundary)(ShipperSignUp);
