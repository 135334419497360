import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Checkbox, Chip, Grid, IconButton, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { OnwardChip } from '@/styles/blocks';
import { DeleteForeverOutlined, SaveOutlined } from '@material-ui/icons';

const ONWARD_TYPES = {
    curbside: 'Curbside',
    firstDrySpace: 'First Dry Space / Threshold',
    roomOfChoice: 'Room of Choice',
    whiteGlove: 'White Glove',
};

export const ONWARD_TAGS = {
    '2_man_team': '2 Man Team',
    '3_man_team': '3 Man Team',
    '4_man_team': '4 Man Team',
    no_contact: 'No Contact',
    assembly_required: 'Assembly Required',
};

const ServiceLevel = ({ serviceLevel, customTags = [], callbacks, loading }) => {
    const [updates, setUpdates] = useState({});

    const updated = useMemo(() => {
        return {
            ...serviceLevel,
            ...updates,
        };
    }, [serviceLevel, updates]);

    const tags = useMemo(() => {
        const { onward = {}, custom = {} } = updated.tags || {};
        return [
            ...Object.entries(onward)
                .filter(([_, enabled]) => !!enabled)
                .map(([key]) => ({ type: 'onward', key, label: ONWARD_TAGS[key] })),
            ...Object.entries(custom)
                .filter(([_, enabled]) => !!enabled)
                .map(([key]) => ({ type: 'custom', key, label: key })),
        ];
    }, [updated]);

    const options = useMemo(() => {
        return [
            ...Object.entries(ONWARD_TAGS).map(([key, label]) => ({ type: 'onward', key, label })),
            ...Object.entries(customTags)
                .filter(([_, enabled]) => !!enabled)
                .map(([tag]) => ({ type: 'custom', key: tag, label: tag })),
        ];
    }, [ONWARD_TAGS, customTags]);

    return (
        <Grid
            container
            css={css`
                flex-wrap: nowrap;
            `}
        >
            <Grid
                item
                css={css`
                    width: 250px;
                `}
            >
                <TextField
                    variant="outlined"
                    label="Name"
                    value={updated.service_level || ''}
                    onChange={(e) => setUpdates((prev) => ({ ...prev, service_level: e.target.value }))}
                    fullWidth
                />
            </Grid>
            <Grid
                item
                css={css`
                    width: 250px;
                    margin-left: 2rem;
                `}
            >
                <TextField
                    select
                    variant="outlined"
                    label="Tariff Service Type"
                    value={updated.location_type || ''}
                    onChange={(e) => setUpdates((prev) => ({ ...prev, location_type: e.target.value }))}
                    fullWidth
                >
                    {Object.entries(ONWARD_TYPES).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid
                item
                css={css`
                    width: 250px;
                    margin-left: 2rem;
                `}
            >
                <Autocomplete
                    multiple
                    value={tags}
                    limitTags={1}
                    disableCloseOnSelect
                    onChange={(e, value) => {
                        let tags = { custom: [], onward: [] };
                        for (const tag of value) {
                            const { type, key } = tag;
                            tags[type].push(key);
                        }
                        setUpdates((prev) => ({
                            ...prev,
                            tags: {
                                custom: Object.fromEntries(tags.custom.map((tag) => [tag, true])),
                                onward: Object.fromEntries(tags.onward.map((tag) => [tag, true])),
                            },
                        }));
                    }}
                    options={options}
                    getOptionSelected={(option, value) => {
                        return option.key === value.key;
                    }}
                    getOptionLabel={(option) => option.label || option.key}
                    renderTags={(options, getTagProps) => {
                        const first = options.length ? (
                            <OnwardChip key={options[0].key} label={options[0].label} />
                        ) : (
                            ''
                        );
                        const more =
                            options.length > 1 ? (
                                <span
                                    css={css`
                                        font-weight: 500;
                                    `}
                                >
                                    {' '}
                                    +{options.length - 1}
                                </span>
                            ) : (
                                ''
                            );
                        return (
                            <span>
                                {first}
                                {more}
                            </span>
                        );
                    }}
                    renderOption={(option, { selected }) => {
                        return (
                            <li key={option.key}>
                                <Checkbox color="primary" checked={selected} />
                                {option.label || option.key}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label="Order Tags" placeholder="Tags" />
                    )}
                />
            </Grid>
            <Grid
                item
                css={css`
                    width: 400px;
                    margin-left: 2rem;
                `}
            >
                <TextField
                    variant="outlined"
                    label="Special Instructions"
                    value={updated.instructions || ''}
                    onChange={(e) => setUpdates((prev) => ({ ...prev, instructions: e.target.value }))}
                    fullWidth
                />
            </Grid>
            <Grid
                item
                css={css`
                    margin-left: 1rem;
                `}
            >
                <IconButton disabled={loading} onClick={() => callbacks.onSave(updated)}>
                    <SaveOutlined fontSize="large" color={loading ? 'disabled' : 'primary'} />
                </IconButton>
            </Grid>
            <Grid
                item
                css={css`
                    margin-left: 1rem;
                `}
            >
                <IconButton disabled={loading} onClick={() => callbacks.onDelete(serviceLevel.service_level_id)}>
                    <DeleteForeverOutlined fontSize="large" color={loading ? 'disabled' : 'error'} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default ServiceLevel;
