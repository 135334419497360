import { gql } from '@apollo/client';

import { CORE_CLIENT_ACCOUNT_FIELDS } from '../../graphql/fragments/clients';
import { USER_FIELDS } from '@/graphql/queries/users';
import { LOCATION_FIELDS } from '@/graphql/queries/locations';
import { TRUCK_FIELDS } from '@/graphql/queries/trucks';
import { SHIPPING_PARTNER_FIELDS } from '@/graphql/queries/shipping_partners';
import { PARTNERSHIP_FIELDS } from '@/graphql/fragments/partnerships';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { RETAILER_FIELDS } from '@/graphql/fragments/retailers';
import { TEAMMATE_FIELDS } from '@/graphql/queries/teammates';

export const UPDATE_SHIPPER_CLIENT_INFO = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation UpdateClienttInfo(
        $client_id: uuid!
        $username: String
        $secondary_username: String
        $email: String
        $secondary_email: String
        $business_phone: String
        $secondary_phone: String
        $support_email: String
        $support_phone: String
        $fleet_size: Int
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                username: $username
                secondary_username: $secondary_username
                email: $email
                secondary_email: $secondary_email
                business_phone: $business_phone
                secondary_phone: $secondary_phone
                support_email: $support_email
                support_phone: $support_phone
                fleet_size: $fleet_size
            }
        ) {
            ...CoreClientAccountFields
        }
    }
`;

export const UPDATE_CARRIER_CLIENT_INFO = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation UpdateClienttInfo(
        $client_id: uuid!
        $username: String
        $business_name: String
        $billing_city: String
        $billing_zip: String
        $billing_state: String
        $billing_address: String
        $mc_num: String
        $business_ein: String
        $dot_number: String
        $email: String
        $business_phone: String
        $support_phone: String
        $support_email: String
        $fleet_size: Int
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                username: $username
                business_name: $business_name
                billing_city: $billing_city
                billing_zip: $billing_zip
                billing_state: $billing_state
                billing_address: $billing_address
                mc_num: $mc_num
                business_ein: $business_ein
                dot_number: $dot_number
                email: $email
                business_phone: $business_phone
                support_phone: $support_phone
                support_email: $support_email
                fleet_size: $fleet_size
            }
        ) {
            ...CoreClientAccountFields
        }
    }
`;

export const UPDATE_USER_EMAIL = gql`
    mutation UpdateUserEmail($user_id: uuid!, $email: String) {
        update_users(where: { user_id: { _eq: $user_id } }, _set: { email: $email }) {
            affected_rows
            returning {
                user_id
                email
            }
        }
    }
`;

export const ADD_BILLING_INFO_TO_CLIENT = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation AddBillingInfoToClient(
        $client_id: uuid!
        $billing_address: String
        $billing_city: String
        $billing_state: String
        $billing_zip: String
        $billing_email: String
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                billing_address: $billing_address
                billing_city: $billing_city
                billing_state: $billing_state
                billing_zip: $billing_zip
                billing_email: $billing_email
            }
        ) {
            ...CoreClientAccountFields
        }
    }
`;

export const UPDATE_CARRIER_DOCUMENT = gql`
    mutation UpdateCarrierDocument($client_id: uuid = "", $_set: clients_set_input = {}) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: $_set) {
            w_form
            w_form_uploaded_at
            workers_comp_form
            workers_comp_form_uploaded_at
            insurance_form
            insurance_form_uploaded_at
            onward_form
            onward_form_uploaded_at
            client_id
            insurance_form_expiration
            workers_comp_form_expiration
        }
    }
`;

export const UPDATE_CARRIER_BLACKLIST = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation UpdateCarrierBlacklist($client_id: uuid, $blacklistArray: [jsonb]) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: { blacklisted_clients: $blacklistArray }) {
            ...CoreClientAccountFields
            blacklisted_clients
            blacklist
        }
    }
`;

export const UPDATE_CARRIER_DEFAULT_STATE_FILTER = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation UpdateCarrierDefaultStateFilter($client_id: uuid!, $defaultStateFilter: Boolean) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: { default_filter_by_my_state: $defaultStateFilter }
        ) {
            ...CoreClientAccountFields
            blacklisted_clients
            blacklist
            default_filter_by_my_state
        }
    }
`;

export const UPDATE_DELIVERY_FILTERS = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation UpdateDeliveryFilters($client_id: uuid!, $delivery_filters: jsonb, $default_filter_by_my_state: Boolean) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: { delivery_filters: $delivery_filters, default_filter_by_my_state: $default_filter_by_my_state }
        ) {
            ...CoreClientAccountFields
            blacklisted_clients
            blacklist
            delivery_filters
            default_filter_by_my_state
        }
    }
`;

export const UPDATE_USER_CIRCLES = gql`
    ${USER_FIELDS}
    mutation UpdateUserCircles($user_id: uuid!, $circles: jsonb) {
        update_users(where: { user_id: { _eq: $user_id } }, _set: { circles: $circles }) {
            affected_rows
            returning {
                user_id
                circles
            }
        }
    }
`;

export const UPDATE_CLIENT_DATA_SHARE = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation UpdateClientDataShare($user_id: uuid!, $data_share: Boolean!) {
        update_clients_by_pk(pk_columns: { client_id: $user_id }, _set: { data_share: $data_share }) {
            ...CoreClientAccountFields
        }
    }
`;

export const UPDATE_TEAMMATE = gql`
    ${TEAMMATE_FIELDS}
    mutation UpdateTeammate($teammate_id: uuid!, $update: teammates_set_input!) {
        update_teammates(where: { teammate_id: { _eq: $teammate_id } }, _set: $update) {
            affected_rows
            returning {
                ...TeammateFields
            }
        }
    }
`;

export const REMOVE_TEAMMATE = gql`
    mutation UpdateTeammateRoles($teammate_id: uuid!) {
        update_teammates(
            where: { teammate_id: { _eq: $teammate_id } }
            _set: { team_leader_id: null, team_leader_type: null, roles: { viewer: false } }
        ) {
            affected_rows
            returning {
                teammate_id
                team_leader_id
                team_leader_type
                roles
            }
        }
    }
`;

export const UPDATE_CLIENT_WITH_STRIPE_ID = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation UpdateClientWithStripeID($client_id: uuid!, $stripe_user_id: String) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: { stripe_user_id: $stripe_user_id }) {
            ...CoreClientAccountFields
            stripe_user_id
        }
    }
`;

export const ADD_DEFAULT_STORE_TO_CLIENT = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation AddDefaultStoreToClient(
        $client_id: uuid!
        $default_store_hours: jsonb
        $xmas: Boolean
        $xmas_eve: Boolean
        $new_years_eve: Boolean
        $new_years_day: Boolean
        $labor_day: Boolean
        $memorial_day: Boolean
        $thanksgiving: Boolean
        $july_fourth: Boolean
        $default_pickup_comments: String
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                default_store_hours: $default_store_hours
                xmas: $xmas
                xmas_eve: $xmas_eve
                new_years_eve: $new_years_eve
                new_years_day: $new_years_day
                labor_day: $labor_day
                memorial_day: $memorial_day
                thanksgiving: $thanksgiving
                july_fourth: $july_fourth
                default_pickup_comments: $default_pickup_comments
            }
        ) {
            ...CoreClientAccountFields
        }
    }
`;

export const ADD_LOCATION = gql`
    ${LOCATION_FIELDS}
    mutation AddLocation($locations_input_object: [locations_insert_input!]! = []) {
        insert_locations(objects: $locations_input_object) {
            ...LocationFields
        }
    }
`;

export const UPDATE_LOCATION = gql`
    ${LOCATION_FIELDS}
    mutation UpdateLocation($location_id: uuid!, $location_update_object: locations_set_input = {}) {
        update_locations_by_pk(pk_columns: { location_id: $location_id }, _set: $location_update_object) {
            ...LocationFields
        }
    }
`;

export const REMOVE_LOCATION = gql`
    mutation RemoveLocation($location_id: uuid!) {
        delete_locations_by_pk(location_id: $location_id) {
            location_id
        }
    }
`;

export const ADD_FIRST_STORE_TO_CLIENT = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    ${LOCATION_FIELDS}
    mutation AddDefaultStoreToClient(
        $client_id: uuid!
        $default_store_hours: jsonb
        $default_pickup_comments: String
        $xmas: Boolean
        $xmas_eve: Boolean
        $new_years_eve: Boolean
        $new_years_day: Boolean
        $labor_day: Boolean
        $memorial_day: Boolean
        $thanksgiving: Boolean
        $july_fourth: Boolean
        $locations_input_object: [locations_insert_input!]! = []
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                default_store_hours: $default_store_hours
                xmas: $xmas
                xmas_eve: $xmas_eve
                new_years_eve: $new_years_eve
                new_years_day: $new_years_day
                labor_day: $labor_day
                memorial_day: $memorial_day
                thanksgiving: $thanksgiving
                july_fourth: $july_fourth
                default_pickup_comments: $default_pickup_comments
            }
        ) {
            ...CoreClientAccountFields
        }
        insert_locations(objects: $locations_input_object) {
            ...LocationFields
        }
    }
`;

export const ADD_CARRIER_LOCATION = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    ${LOCATION_FIELDS}
    mutation AddCarrierLocation(
        $client_id: uuid!
        $xmas: Boolean
        $xmas_eve: Boolean
        $new_years_eve: Boolean
        $new_years_day: Boolean
        $labor_day: Boolean
        $memorial_day: Boolean
        $thanksgiving: Boolean
        $july_fourth: Boolean
        $default_store_hours: jsonb
        $locations_input_object: [locations_insert_input!]! = []
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                default_store_hours: $default_store_hours
                xmas: $xmas
                xmas_eve: $xmas_eve
                new_years_eve: $new_years_eve
                new_years_day: $new_years_day
                labor_day: $labor_day
                memorial_day: $memorial_day
                thanksgiving: $thanksgiving
                july_fourth: $july_fourth
            }
        ) {
            ...CoreClientAccountFields
        }
        insert_locations(objects: $locations_input_object) {
            ...LocationFields
        }
    }
`;

export const UPDATE_CARRIER_LOCATION = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    ${LOCATION_FIELDS}
    mutation UpdateCarrierLocation(
        $client_id: uuid!
        $location_id: uuid!
        $xmas: Boolean
        $xmas_eve: Boolean
        $new_years_eve: Boolean
        $new_years_day: Boolean
        $labor_day: Boolean
        $memorial_day: Boolean
        $thanksgiving: Boolean
        $july_fourth: Boolean
        $default_store_hours: jsonb
        $locations_input_object: locations_set_input = []
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                default_store_hours: $default_store_hours
                xmas: $xmas
                xmas_eve: $xmas_eve
                new_years_eve: $new_years_eve
                new_years_day: $new_years_day
                labor_day: $labor_day
                memorial_day: $memorial_day
                thanksgiving: $thanksgiving
                july_fourth: $july_fourth
            }
        ) {
            ...CoreClientAccountFields
        }
        update_locations_by_pk(pk_columns: { location_id: $location_id }, _set: $locations_input_object) {
            ...LocationFields
        }
    }
`;

export const UPDATE_TEAMMATE_PERSONAL_INFO = gql`
    mutation UpdateTeammatePersonalInfo($teammate_id: uuid!, $username: String, $email: String, $phone: String) {
        update_teammates(
            where: { teammate_id: { _eq: $teammate_id } }
            _set: { username: $username, email: $email, phone: $phone }
        ) {
            affected_rows
            returning {
                teammate_id
                username
                email
                phone
            }
        }
    }
`;

export const UPDATE_TEAMMATE_USER_PERSONAL_INFO = gql`
    mutation UpdateTeammateUserPersonalInfo($user_id: uuid!, $username: String, $email: String) {
        update_users(where: { user_id: { _eq: $user_id } }, _set: { username: $username, email: $email }) {
            affected_rows
            returning {
                user_id
                username
                email
            }
        }
    }
`;

export const TEAMMATE_UPDATE_MAIN_CARRIER_INFO = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation TeammateUpdateMainCarrierInfo(
        $client_id: uuid!
        $business_phone: String
        $support_phone: String
        $business_name: String
        $billing_city: String
        $billing_zip: String
        $billing_state: String
        $billing_address: String
        $mc_num: String
        $business_ein: String
        $dot_number: String
        $fleet_size: Int
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                business_phone: $business_phone
                support_phone: $support_phone
                business_name: $business_name
                billing_city: $billing_city
                billing_zip: $billing_zip
                billing_state: $billing_state
                billing_address: $billing_address
                mc_num: $mc_num
                business_ein: $business_ein
                dot_number: $dot_number
                fleet_size: $fleet_size
            }
        ) {
            ...CoreClientAccountFields
        }
    }
`;

export const TEAMMATE_UPDATE_MAIN_SHIPPER_INFO = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation TeammateUpdateMainShipperInfo(
        $client_id: uuid!
        $username: String
        $secondary_username: String
        $secondary_email: String
        $business_phone: String
        $secondary_phone: String
        $support_email: String
        $support_phone: String
        $fleet_size: Int
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                client_id: $client_id
                username: $username
                secondary_username: $secondary_username
                secondary_email: $secondary_email
                business_phone: $business_phone
                secondary_phone: $secondary_phone
                support_email: $support_email
                support_phone: $support_phone
                fleet_size: $fleet_size
            }
        ) {
            ...CoreClientAccountFields
        }
    }
`;

export const UPDATE_CLIENT_CC = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    mutation AddDefaultStoreToClient($client_id: uuid!, $cc_valid: Boolean, $stripe_payment_method: String) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: { cc_valid: $cc_valid, stripe_payment_method: $stripe_payment_method }
        ) {
            ...CoreClientAccountFields
        }
    }
`;

export const UPSERT_CLIENTS = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    ${LOCATION_FIELDS}

    mutation UpsertClients($clients: [clients_insert_input!]!, $locations: [locations_insert_input!]!) {
        insert_clients(
            objects: $clients
            on_conflict: {
                constraint: clients_pkey
                update_columns: [
                    default_store_hours
                    default_pickup_comments
                    july_fourth
                    xmas_eve
                    xmas
                    new_years_eve
                    new_years_day
                    labor_day
                    memorial_day
                    thanksgiving
                ]
            }
        ) {
            returning {
                ...CoreClientAccountFields
            }
        }
        insert_locations(
            objects: $locations
            on_conflict: {
                constraint: locations_pkey
                update_columns: [
                    address
                    contact_name
                    contact_email
                    contact_phone
                    business_address
                    business_city
                    business_state
                    business_unit
                    business_zip
                    days_closed
                    default_end_location
                    enable_for_wms
                    hours_open
                    july_fourth
                    labor_day
                    lat
                    lng
                    location_code
                    location_info
                    location_name
                    location_type
                    memorial_day
                    new_years_day
                    new_years_eve
                    preferred_inbound
                    qbo_class
                    shipper_pu_comments
                    thanksgiving
                    xmas
                    xmas_eve
                ]
            }
        ) {
            returning {
                ...LocationFields
            }
        }
    }
`;

export const ADD_FIRST_STORE = gql`
    ${CORE_CLIENT_ACCOUNT_FIELDS}
    ${LOCATION_FIELDS}

    mutation AddFirstStore(
        $client_id: uuid!
        $hours: jsonb
        $xmas: Boolean
        $xmas_eve: Boolean
        $new_years_eve: Boolean
        $new_years_day: Boolean
        $labor_day: Boolean
        $memorial_day: Boolean
        $thanksgiving: Boolean
        $july_fourth: Boolean
        $locations_input_object: [locations_insert_input!]! = []
    ) {
        update_clients_by_pk(
            pk_columns: { client_id: $client_id }
            _set: {
                default_store_hours: $hours
                xmas: $xmas
                xmas_eve: $xmas_eve
                new_years_eve: $new_years_eve
                new_years_day: $new_years_day
                labor_day: $labor_day
                memorial_day: $memorial_day
                thanksgiving: $thanksgiving
                july_fourth: $july_fourth
            }
        ) {
            ...CoreClientAccountFields
        }
        insert_locations(objects: $locations_input_object) {
            ...LocationFields
        }
    }
`;

export const UPSERT_TRUCK = gql`
    ${TRUCK_FIELDS}
    mutation UpsertTruck($truck: trucks_insert_input!) {
        insert_trucks_one(
            object: $truck
            on_conflict: {
                constraint: trucks_pkey
                update_columns: [truck_capacity, truck_name, truck_size, truck_weight_limit, days_available]
            }
        ) {
            ...TruckFields
        }
    }
`;

export const DELETE_TRUCK = gql`
    mutation DeleteTruck($truck_id: uuid!) {
        delete_trucks_by_pk(truck_id: $truck_id) {
            truck_id
        }
    }
`;

export const UPSERT_SHIPPING_PARTNER = gql`
    ${PARTNERSHIP_FIELDS}
    ${CLIENT_FIELDS}
    ${RETAILER_FIELDS}
    mutation UpsertShippingPartner($partnership: partnership_insert_input!) {
        insert_partnerships_one(object: $partnership) {
            ...PartnershipFields
            shipper {
                ...ClientFields
            }
            retailer {
                ...RetailerFields
            }
        }
    }
`;

export const CREATE_SHIPPING_PARTNER = gql`
    ${CLIENT_FIELDS}
    mutation CreateShippingPartner($user: users_insert_input!) {
        insert_users_one(object: $user) {
            user_id
            client {
                ...ClientFields
            }
        }
    }
`;

export const CONNECT_SHIPPING_PARTNER = gql`
    ${PARTNERSHIP_FIELDS}
    ${CLIENT_FIELDS}
    ${RETAILER_FIELDS}
    mutation ConnectShippingPartner($partnership: partnerships_insert_input!) {
        insert_partnerships_one(object: $partnership) {
            ...PartnershipFields
            shipper {
                ...ClientFields
            }
            retailer {
                ...RetailerFields
            }
        }
    }
`;

export const UPDATE_SHIPPING_PARTNER = gql`
    ${PARTNERSHIP_FIELDS}
    ${CLIENT_FIELDS}
    mutation UpdateShippingPartner($partnership_id: uuid!, $update: partnerships_set_input!) {
        update_partnerships(where: { partnership_id: { _eq: $partnership_id } }, _set: $update) {
            returning {
                ...PartnershipFields
                shipper {
                    ...ClientFields
                }
                carrier {
                    ...ClientFields
                }
            }
        }
    }
`;

export const DELETE_SHIPPING_PARTNER = gql`
    mutation DeleteShippingPartner($partnership_id: uuid!) {
        delete_partnerships_by_pk(partnership_id: $partnership_id) {
            partnership_id
        }
    }
`;

export const UPDATE_PROFILE_IMAGE = gql`
    mutation UpdateProfileImage($client_id: uuid!, $_set: clients_set_input = {}) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: $_set) {
            client_id
            profile_img
            profile_img_color
        }
    }
`;

export const UPDATE_QBO_TAGS = gql`
    mutation UpdateQBOTags($client_id: uuid!, $qbo_tags: jsonb) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: { qbo_tags: $qbo_tags }) {
            client_id
            qbo_tags
        }
    }
`;
