import React, { useContext, useMemo } from 'react';
import { UserContext } from '@/components/App';
import {
    DEFAULT_DELIVERY_TIMEFRAME,
    DEFAULT_SERVICE_TIME,
    DEFAULT_SERVICE_TIME_C,
    DEFAULT_SERVICE_TIME_FDS,
    DEFAULT_SERVICE_TIME_INCREMENT,
    DEFAULT_SERVICE_TIME_ROC,
    DEFAULT_SERVICE_TIME_WG,
    DEFAULT_TIMEFRAME_BUFFER,
} from '@/constants/deliveryTimeframes';
import { QUERY_BY_ID } from '@/graphql/queries/users';
import { captureException } from '@sentry/react';
import { useQuery } from '@apollo/client';

export const useClientUser = () => {
    const { user_id, user: staleUser, superUser } = useContext(UserContext);

    const { data } = useQuery(QUERY_BY_ID, {
        variables: { user_id: user_id },
        skip: !user_id,
        onError: (err) => {
            console.error(err);
        },
    });

    return useMemo(() => {
        const user = data?.user || staleUser;

        if (user?.roles?.SHIPPER || user?.roles?.CARRIER) {
            const clientUser = {
                email: user.email,
                user_id: user.user_id,
                roles: user.roles,
                accountType: user.roles.SHIPPER ? 'shipper' : 'carrier',
                userType: user.roles.SHIPPER ? 'shipper' : 'carrier',
                circles: user.circles,
                businessName: user.client?.business_name,
                businessPhone: user.client.business_phone,
                businessState: user.client.business_state || user.client.billing_state,
                businessZip: user?.client?.business_zip || user?.client?.billing_zip,
                approved: user.client.approved,
                test_acc: user.client.test_acc,
                blacklistedClients: user.client.blacklisted_clients,
                cc_valid: user.client.cc_valid,
                payment_type: user.client.payment_type,
                stripe_user_id: user.client.stripe_user_id,
                stripe_payment_method: user.client.stripe_payment_method,
                username: user.client.username,
                client_id: user.client.client_id,
                teammates: user.teammatesByLeaderId || [],
                drivers: user.teammatesByLeaderId?.filter((tm) => tm.roles?.driver) || [],
                helpers: user.teammatesByLeaderId?.filter((tm) => tm.roles?.helper) || [],
                auto_assigned_carrier: user.client.auto_assigned_carrier,
                trucks: user?.client?.trucks || [],
                locations: user?.client?.locations || [],
                shipping_partners: user?.client?.carrier_partnerships || [],
                partner_carriers: user?.client?.shipper_partnerships || [],
                middle_mile_integration_type: user?.client?.middle_mile_integration_type,
                middle_mile_integration_meta: user?.client?.middle_mile_integration_meta,
                dispatch_integration_type: user?.client?.dispatch_integration_type,
                dispatch_integration_meta: user?.client?.dispatch_integration_meta,
                service_levels: user?.client?.service_levels || [],
                order_tags: user?.client?.order_tags || {},
                customer_messages: user?.client?.customer_messages || {},

                adminLoggedIn: user.adminLoggedIn,

                preferences_service_time: user.client.preferences_service_time || DEFAULT_SERVICE_TIME,
                preferences_use_service_default_as_minimum:
                    user.client.preferences_use_service_default_as_minimum || false,
                preferences_service_time_incr:
                    user.client.preferences_service_time_incr || DEFAULT_SERVICE_TIME_INCREMENT,
                preferences_service_time_curbside:
                    user.client.preferences_service_time_curbside || DEFAULT_SERVICE_TIME_C,
                preferences_service_time_first_dry_space:
                    user.client.preferences_service_time_first_dry_space || DEFAULT_SERVICE_TIME_FDS,
                preferences_service_time_room_of_choice:
                    user.client.preferences_service_time_room_of_choice || DEFAULT_SERVICE_TIME_ROC,
                preferences_service_time_white_glove:
                    user.client.preferences_service_time_white_glove || DEFAULT_SERVICE_TIME_WG,
                preferences_delivery_timeframe:
                    user.client.preferences_delivery_timeframe || DEFAULT_DELIVERY_TIMEFRAME,
                preferences_timeframe_buffer: user.client.preferences_timeframe_buffer || DEFAULT_TIMEFRAME_BUFFER,
                preferences_timeframe_buffer_apply: user.client.preferences_timeframe_buffer_apply || false,
                preferences_timeframe_round: user.client.preferences_timeframe_round || false,
                preferences_route_time_limit: user.client.preferences_route_time_limit || 0,
                preferences_route_eod_limit: user.client.preferences_route_eod_limit || 0,
                preferences_route_eod_limit_next_day: user.client.preferences_route_eod_limit_next_day || false,
                preferences_start_estimate: user.client.preferences_start_estimate || false,
                preferences_end_estimate: user.client.preferences_end_estimate || false,
                preferences_warehouse_return_estimate: user.client.preferences_warehouse_return_estimate || false,
                preferences_next_day_returns: user.client?.preferences_next_day_returns || false,
                preferences_bypass_optimize: user.client?.preferences_bypass_optimize || false,
                savedShapes: user.client.map_shapes,
                default_end_location: user.client.locations?.find((l) => l.default_end_location),
                commingled_internal_mandatory: user.client.commingled_internal_mandatory,
                qbo_tags: user.client.qbo_tags,
            };

            return {
                isImposter: !!superUser?.user_id,
                isOnwardAdmin: !!user?.roles?.ADMIN,
                ...clientUser,
                ...(user.roles.CARRIER
                    ? {
                          warehouseAddress: user.client.locations?.length
                              ? user.client.locations?.[0]?.address ||
                                `${user.client.locations?.[0]?.business_address}, ${user.client.locations?.[0]?.business_city}, ${user.client.locations?.[0]?.business_state}, ${user.client.locations?.[0]?.business_zip}`
                              : '',
                          warehouseLocationInfo: user.client.locations?.[0]?.location_info || '',
                          warehouseLocationType: user.client.locations?.[0]?.location_type || '',
                      }
                    : {}),
            };
        } else if (user?.roles?.TEAMMATE) {
            const teamLeader = user?.teammate?.userByTeamLeaderId;
            return {
                isImposter: !!superUser?.user_id,
                isOnwardAdmin: !!user?.roles?.ADMIN,
                email: teamLeader.email,
                user_id: teamLeader.user_id,
                roles: user.roles,
                accountType: teamLeader.roles.SHIPPER ? 'shipper' : 'carrier',
                userType: 'teammate',
                circles: teamLeader.circles,
                businessName: teamLeader.client?.business_name,
                businessPhone: teamLeader.client.business_phone,
                businessState: teamLeader.client.business_state || teamLeader.client.billing_state,
                businessZip: teamLeader.client?.business_zip || teamLeader.client?.billing_zip,
                approved: teamLeader.client.approved,
                test_acc: teamLeader.client.test_acc,
                teammateRoles: user?.teammate?.roles,
                blacklistedClients: teamLeader.client.blacklisted_clients,
                cc_valid: teamLeader.client.cc_valid,
                payment_type: teamLeader.client.payment_type,
                stripe_user_id: teamLeader.client.stripe_user_id,
                username: teamLeader.username,
                client_id: teamLeader.client.client_id,
                default_end_location: teamLeader.client.locations?.find((l) => l.default_end_location),
                adminLoggedIn: user.adminLoggedIn,
                teammates: teamLeader.teammatesByLeaderId || [],
                drivers: teamLeader.teammatesByLeaderId?.filter((tm) => tm.roles?.driver) || [],
                helpers: teamLeader.teammatesByLeaderId?.filter((tm) => tm.roles?.helper) || [],
                auto_assigned_carrier: teamLeader.client.auto_assigned_carrier,
                trucks: teamLeader?.client?.trucks || [],
                locations: teamLeader?.client?.locations || [],
                shipping_partners: teamLeader?.client?.carrier_partnerships || [],
                partner_carriers: teamLeader?.client?.shipper_partnerships || [],
                middle_mile_integration_type: teamLeader?.client?.middle_mile_integration_type,
                middle_mile_integration_meta: teamLeader?.client?.middle_mile_integration_meta,
                dispatch_integration_type: teamLeader?.client?.dispatch_integration_type,
                dispatch_integration_meta: teamLeader?.client?.dispatch_integration_meta,
                service_levels: teamLeader?.client?.service_levels || [],
                order_tags: teamLeader?.client?.order_tags || {},
                customer_messages: teamLeader?.client?.customer_messages || {},

                preferences_service_time: teamLeader.client.preferences_service_time || DEFAULT_SERVICE_TIME,
                preferences_use_service_default_as_minimum:
                    teamLeader.client.preferences_use_service_default_as_minimum || false,
                preferences_service_time_incr:
                    teamLeader.client.preferences_service_time_incr || DEFAULT_SERVICE_TIME_INCREMENT,
                preferences_service_time_curbside:
                    teamLeader.client.preferences_service_time_curbside || DEFAULT_SERVICE_TIME_C,
                preferences_service_time_first_dry_space:
                    teamLeader.client.preferences_service_time_first_dry_space || DEFAULT_SERVICE_TIME_FDS,
                preferences_service_time_room_of_choice:
                    teamLeader.client.preferences_service_time_room_of_choice || DEFAULT_SERVICE_TIME_ROC,
                preferences_service_time_white_glove:
                    teamLeader.client.preferences_service_time_white_glove || DEFAULT_SERVICE_TIME_WG,
                preferences_delivery_timeframe:
                    teamLeader.client.preferences_delivery_timeframe || DEFAULT_DELIVERY_TIMEFRAME,
                preferences_timeframe_buffer:
                    teamLeader.client.preferences_timeframe_buffer || DEFAULT_TIMEFRAME_BUFFER,
                preferences_timeframe_buffer_apply: teamLeader.client.preferences_timeframe_buffer_apply || false,
                preferences_timeframe_round: teamLeader.client.preferences_timeframe_round || false,
                preferences_route_time_limit: teamLeader.client?.preferences_route_time_limit || 0,
                preferences_route_eod_limit: teamLeader.client?.preferences_route_eod_limit || 0,
                preferences_route_eod_limit_next_day: teamLeader?.client.preferences_route_eod_limit_next_day || false,
                preferences_start_estimate: teamLeader.client?.preferences_start_estimate || false,
                preferences_end_estimate: teamLeader.client?.preferences_end_estimate || false,
                preferences_warehouse_return_estimate:
                    teamLeader.client?.preferences_warehouse_return_estimate || false,
                preferences_next_day_returns: teamLeader.client?.preferences_next_day_returns || false,
                preferences_bypass_optimize: teamLeader.client?.preferences_bypass_optimize || false,
                savedShapes: teamLeader.client.map_shapes,
                qbo_tags: teamLeader.client.qbo_tags,
            };
        } else if (user?.roles?.CORPORATE_ADMIN) {
            return {
                isImposter: !!superUser?.user_id,
                isOnwardAdmin: !!user?.roles?.ADMIN,
                email: user.email,
                user_id: user.user_id,
                roles: user.roles,
                circles: user.circles,
            };
        }

        return {};
    }, [data, staleUser, superUser]);
};
