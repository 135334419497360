import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Row, Container, Card, Form, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import {
    Button,
    InputAdornment,
    IconButton,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Menu,
    MenuItem,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
    Grid,
    Checkbox,
    Typography,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Table } from 'reactstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import * as Sentry from '@sentry/react';
import { teammateRoles } from '../../constants/teammateRoles';
import './ShipperAccount/styles.css';
import { UserContext } from '../App';
import { useClientUser } from '@/hooks';
import { USER_BY_EMAIL } from './queries';
import { REMOVE_TEAMMATE, UPDATE_TEAMMATE } from './mutations';
import { useLazyQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { post } from '@/utilities/onwardClient';
import { INVITE_TEAMMATE } from '@/constants/apiRoutes';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { MoreVert } from '@material-ui/icons';
import { css } from '@emotion/react';
import FileDropZone from '@/components/OrderDetailsPage/FileDropZone';
import {
    ModalContent,
    PrimaryButton,
    ResponsiveSidebarDialog,
    SecondaryButton,
    SectionTitle,
    StickyModalActions,
} from '@/styles/blocks';
import { OnwardToggle } from '../ShipmentForm/blocks';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import anonProfile from 'src/assets/anonProfile.jpg';

const capitalize = (string = '') => string.slice(0, 1).toUpperCase() + string.slice(1);

const TeammateRow = ({ invoiceAccess, teamLeaderType, teammate, callbacks }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const role = useMemo(() => {
        return Object.keys(teammateRoles).find((role) => teammate.roles?.[role]);
    }, [teammate]);

    const menuOptions = useMemo(
        () => [
            {
                display: 'Edit',
                callback: () => {
                    callbacks.setTeammateToEdit(teammate);
                    setAnchorEl(null);
                },
            },
            {
                display: 'Remove Teammate',
                callback: () => {
                    callbacks.removeTeammate(teammate.teammate_id);
                    setAnchorEl(null);
                },
            },
        ],
        [teammate, callbacks]
    );

    return (
        <tr key={teammate.teammate_id}>
            <td>
                {' '}
                {teammate.profile_image ? (
                    <Image
                        src={teammate.profile_image}
                        css={css`
                            height: 2.5rem;
                            width: 2.5rem;
                        `}
                        roundedCircle
                    />
                ) : (
                    <Image
                        src={anonProfile}
                        css={css`
                            height: 2.5rem;
                            width: 2.5rem;
                        `}
                        roundedCircle
                    />
                )}
            </td>
            <td>{teammate.username}</td>
            <td style={{ textAlign: 'start' }}>{teammate.email}</td>
            <td style={{ textAlign: 'start' }}>{teammate.teammate_type ? teammate.teammate_type : '-'}</td>
            <td style={{ textAlign: 'start' }}>{teammate.hourly_rate ? `$${teammate.hourly_rate.toFixed(2)}` : '-'}</td>
            <td>{role ? capitalize(role) : ''}</td>
            <td style={{ textAlign: 'start' }}>
                {capitalize(teammate.user?.device_settings?.location?.foreground.status || 'Undetermined')}
            </td>
            <td style={{ textAlign: 'start' }}>
                {capitalize(teammate.user?.device_settings?.location?.background.status || 'Undetermined')}
            </td>
            {teamLeaderType !== 'shipper' && (
                <td style={{ textAlign: 'start' }}>{`${teammate?.roles?.invoicing ? 'On' : 'Off'}`}</td>
            )}
            <td style={{ textAlign: 'start' }}>
                <>
                    <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <MoreVert />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                        {menuOptions.map((option, i) => (
                            <MenuItem
                                key={i}
                                onClick={(e) => {
                                    option.callback();
                                }}
                                disabled={option.disabled}
                            >
                                {option.display}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            </td>
        </tr>
    );
};

export default function Teammates(props) {
    const { currentUser, userId, teamLeaderType } = props;
    const [teammateEmail, setTeammateEmail] = useState('');
    const [teammateRole, setTeammateRole] = useState('none');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [teammateToRemoveUid, setTeammateToRemoveUid] = useState(null);
    const { user, superUser } = useContext(UserContext);
    const { user_id, circles, teammates } = useClientUser();
    const [teammateToEdit, setTeammateToEdit] = useState(null);
    const [update, setUpdate] = useState({});
    const [rolesAnchor, setRolesAnchor] = useState(null);

    const invoiceAccess =
        user?.roles['ADMIN'] || user?.roles['CARRIER'] || (superUser?.adminLoggedIn && teamLeaderType !== 'shipper')
            ? true
            : false;

    const [updateTeammate] = useMutation(UPDATE_TEAMMATE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const [removeTeammate] = useMutation(REMOVE_TEAMMATE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
        update: (cache, { data: { update_teammates } }) => {
            const removed = update_teammates?.returning?.[0];
            cache.evict(cache.identify(removed));
        },
    });

    const handleClickOpen = (_uid) => {
        setDialogOpen(true);
        setTeammateToRemoveUid(_uid);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setTeammateToRemoveUid(null);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    const [getUserByEmail] = useLazyQuery(USER_BY_EMAIL);

    const sendEmailInvite = async () => {
        if (!teammateEmail) {
            setError('You must enter an email!');
            return;
        }

        setLoading(true);

        const userResponse = await getUserByEmail({ variables: { email: teammateEmail.toLowerCase().trim() } });
        if (userResponse?.data?.users?.length > 0) {
            setError('A user with this email already exists!');
            setLoading(false);
            return;
        }

        setSuccessMessage('Sending invite ...');
        return post(INVITE_TEAMMATE, {
            teamLeaderId: user_id,
            teammateRoles: { [teammateRole]: true },
            teamLeaderType,
            teammateEmail: teammateEmail.trim(),
        })
            .then(() => {
                setError(null);
                setSuccessMessage(`Your email invite was successfully sent to ${teammateEmail}!`);
            })
            .catch(({ response }) => {
                setError(
                    response?.data?.error || `Error inviting teammate` + `. Contact your Onward rep for assistance`
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleRemoveTeammate = () => {
        removeTeammate({
            variables: {
                teammate_id: teammateToRemoveUid,
            },
            onCompleted: () => {
                handleClose();
            },
        });
    };

    useEffect(() => {
        setUpdate({
            roles: teammateToEdit?.roles || {},
            teammate_type: teammateToEdit?.teammate_type || '',
            hourly_rate: teammateToEdit?.hourly_rate || 0,
            profile_image: teammateToEdit?.profile_image,
        });
    }, [teammateToEdit]);

    const role = useMemo(() => {
        return Object.keys(teammateRoles).find((role) => update?.roles?.[role]);
    }, [update]);

    const handleFileAdd = async (file, fileType) => {
        if (file) {
            try {
                const fileUrl = URL.createObjectURL(file);
                const fbFileUrl = await uploadPhotoAsync(fileUrl, 'profile_image', fileType);
                const fbFile = fbFileUrl;

                setUpdate((prev) => ({ ...prev, profile_image: fbFile }));
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        }
    };

    return (
        <Card className="tabCard">
            <ThemeProvider theme={theme}>
                <Card.Body className="">
                    <Container fluid>
                        <Form>
                            <Row className="d-flex flex-column mt-3">
                                <h5>
                                    Invite new teammates
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip placement="right">
                                                Use the text input below to invite a new team member to help you manage
                                                your Onward Delivery account. Privilages can be granted post
                                                registration.
                                            </Tooltip>
                                        }
                                    >
                                        <InfoCircleFill size={17} className="mx-1 mb-1" />
                                    </OverlayTrigger>
                                </h5>

                                <Form.Group>
                                    <TextField
                                        type="text"
                                        value={teammateEmail}
                                        variant="outlined"
                                        label="Teammate Email"
                                        onChange={(e) => setTeammateEmail(e.target.value)}
                                    />

                                    <br />
                                    <br />
                                    <FormLabel component="legend" style={{ color: 'black' }}>
                                        <b>Roles</b>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="roles"
                                        name="roles"
                                        defaultValue={teammateRole}
                                        onChange={(_, role) => setTeammateRole(role)}
                                    >
                                        {Object.entries(teammateRoles).map(([role, description]) => (
                                            <FormControlLabel
                                                key={role}
                                                value={role}
                                                control={<Radio color="primary" />}
                                                label={`${capitalize(role)}: ${description}`}
                                            />
                                        ))}
                                    </RadioGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Button
                                        onClick={sendEmailInvite}
                                        className="my-1 account-btn"
                                        disabled={loading}
                                        style={{ fontWeight: '600' }}
                                    >
                                        Send Email Invite
                                    </Button>
                                    {error && (
                                        <p
                                            style={{
                                                color: 'red',
                                                width: 'fit-content',
                                                paddingTop: '6px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {error}
                                        </p>
                                    )}

                                    {successMessage && (
                                        <p
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'green',
                                                width: 'fit-content',
                                                padding: '6px',
                                                borderRadius: '5px',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {successMessage}
                                        </p>
                                    )}
                                </Form.Group>
                            </Row>

                            {!!teammates.length && (
                                <>
                                    <h5 className="mt-5">Manage your Teammates</h5>
                                    <Row>
                                        <Table hover style={{ width: '100%' }}>
                                            <thead>
                                                <tr
                                                    style={{
                                                        textAlign: 'start',
                                                        whitespace: 'nowrap',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    <th></th>
                                                    <th>Teammate</th>
                                                    <th>Email</th>
                                                    <th>Type</th>
                                                    <th>Hourly Rate</th>
                                                    <th>Current Role</th>
                                                    <th>Foreground Location Permission</th>
                                                    <th>Background Location Permission</th>
                                                    {teamLeaderType !== 'shipper' && <th>Invoicing Permissions</th>}
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {teammates.map((teammate) => (
                                                    <TeammateRow
                                                        invoiceAccess={invoiceAccess}
                                                        teamLeaderType={teamLeaderType}
                                                        teammate={teammate}
                                                        callbacks={{
                                                            updateTeammate: (teammate_id, update) =>
                                                                updateTeammate({ variables: { teammate_id, update } }),
                                                            removeTeammate: (teammate_id) =>
                                                                handleClickOpen(teammate_id),
                                                            setTeammateToEdit: (teammate) =>
                                                                setTeammateToEdit(teammate),
                                                        }}
                                                    />
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </Container>
                </Card.Body>
                <Dialog open={dialogOpen} onClose={handleClose}>
                    <DialogTitle id="alert-dialog-title">Are you sure you want to remove this teammate?</DialogTitle>
                    <DialogActions>
                        <Button className="account-btn" onClick={handleRemoveTeammate}>
                            Yes
                        </Button>
                        <Button className="account-btn" onClick={handleClose} autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
                <ResponsiveSidebarDialog open={teammateToEdit} onClose={() => setTeammateToEdit(null)}>
                    <ModalContent width={400}>
                        <Grid
                            css={css`
                                margin-bottom: 24px;
                            `}
                        >
                            <SectionTitle>Edit Teammate</SectionTitle>
                        </Grid>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Type"
                                    variant="outlined"
                                    size="small"
                                    value={update?.teammate_type || ''}
                                    onChange={(e) => setUpdate((prev) => ({ ...prev, teammate_type: e.target.value }))}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CurrencyTextField
                                    fullWidth
                                    label="Hourly Rate"
                                    variant="outlined"
                                    size="small"
                                    value={update?.hourly_rate || 0}
                                    onChange={(e) => setUpdate((prev) => ({ ...prev, hourly_rate: e.target.value }))}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                            >
                                <Button
                                    className="account-btn"
                                    fullWidth
                                    onClick={(e) => setRolesAnchor(e.currentTarget)}
                                >
                                    Edit Roles
                                </Button>

                                <Menu
                                    anchorEl={rolesAnchor}
                                    open={!!rolesAnchor}
                                    onClose={() => setRolesAnchor(null)}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    <MenuItem>
                                        <RadioGroup
                                            aria-label="roles"
                                            name="roles00"
                                            value={role}
                                            onChange={(e) =>
                                                setUpdate((prev) => ({
                                                    ...prev,
                                                    roles: {
                                                        [e.target.value]: true,
                                                        invoicing: !!teammateToEdit.roles.invoicing,
                                                    },
                                                }))
                                            }
                                        >
                                            {!!teamLeaderType && (
                                                <>
                                                    {Object.entries(teammateRoles).map(([role, description]) => (
                                                        <FormControlLabel
                                                            key={role}
                                                            value={role}
                                                            control={<Radio color="primary" />}
                                                            label={capitalize(role)}
                                                        />
                                                    ))}
                                                </>
                                            )}
                                        </RadioGroup>
                                    </MenuItem>
                                </Menu>
                            </Grid>
                            {teamLeaderType !== 'shipper' && (
                                <Grid item xs={6}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <OnwardToggle
                                            value={!!update?.roles?.invoicing}
                                            onChange={(e) => {
                                                setUpdate((prev) => ({
                                                    ...prev,
                                                    roles: {
                                                        ...(update.roles || {}),
                                                        invoicing: e.target.checked,
                                                    },
                                                }));
                                            }}
                                        />
                                        <div style={{ marginLeft: '10px', flexShrink: 1 }}>
                                            Invoice editing permission
                                        </div>
                                    </div>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                                {update?.profile_image ? (
                                    <>
                                        <Image
                                            src={update.profile_image}
                                            css={css`
                                                height: 200px;
                                                width: 200px;
                                            `}
                                            roundedCircle
                                        />
                                        <Typography
                                            component="span"
                                            style={{
                                                color: 'green',
                                                cursor: 'pointer',
                                                marginTop: '10px',
                                                textDecoration: 'underline',
                                            }}
                                            onClick={() => {
                                                const input = document.createElement('input');
                                                input.type = 'file';
                                                input.accept = 'image/*';
                                                input.onchange = (e) => {
                                                    const file = e.target.files[0];
                                                    if (file) {
                                                        handleFileAdd(file, file.type.split('/')[1]);
                                                    }
                                                };
                                                input.click();
                                            }}
                                        >
                                            Edit
                                        </Typography>
                                    </>
                                ) : (
                                    <FileDropZone
                                        customLabel={'Upload Driver Photo'}
                                        customStyle={{
                                            height: '100px',
                                            width: '100%',
                                            maxWidth: '400px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'white',
                                            borderRadius: '5px',
                                            outline: '1px solid #c4c4c4',
                                            fontFamily: 'Montserrat',
                                            cursor: 'pointer',
                                        }}
                                        handleFileAdd={handleFileAdd}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </ModalContent>
                    <StickyModalActions>
                        <SecondaryButton
                            onClick={() => {
                                setTeammateToEdit(null);
                            }}
                        >
                            Back
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={() => {
                                updateTeammate({
                                    variables: {
                                        teammate_id: teammateToEdit.teammate_id,
                                        update: update,
                                    },
                                    onCompleted: () => {
                                        setUpdate({});
                                        setTeammateToEdit(null);
                                    },
                                });
                            }}
                        >
                            Save
                        </PrimaryButton>
                    </StickyModalActions>
                </ResponsiveSidebarDialog>
            </ThemeProvider>
        </Card>
    );
}
