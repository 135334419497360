import { SERVICE_LEVEL_FIELDS } from '@/graphql/fragments/service_levels';
import { gql } from '@apollo/client';

export const UPSERT_SERVICE_LEVEL = gql`
    ${SERVICE_LEVEL_FIELDS}
    mutation UpsertServiceLevel($service_level: service_levels_insert_input!) {
        insert_service_levels_one(
            object: $service_level
            on_conflict: {
                constraint: service_levels_pkey
                update_columns: [service_level, location_type, instructions, tags]
            }
        ) {
            ...ServiceLevelFields
        }
    }
`;

export const DELETE_SERVICE_LEVEL = gql`
    mutation DeleteServiceLevel($id: uuid!) {
        delete_service_levels_by_pk(service_level_id: $id) {
            service_level_id
        }
    }
`;
