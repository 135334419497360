import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { UPDATE_PROFILE_IMAGE, UPDATE_SHIPPING_PARTNER } from './mutations';
import * as Sentry from '@sentry/react';
import { css } from '@emotion/react';
import { Card } from 'react-bootstrap';
import { LocationPermissionsTitle, LocationPermissionsTitleSpan, LocationPermissionsText, CardSubtext } from './blocks';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import { useClientUser } from '@/hooks';
import { capitalize } from 'lodash';
import { onwardNavy } from '@/styles/colors';
import { PrimaryButton, WarningButton } from '@/styles/blocks';

const ProfileImageUpload = ({
    client,
    partnership,
    partnershipProfileImageVariables,
    setPartnershipProfileImageVariables,
}) => {
    const [uploading, setUploading] = useState(false);
    const [previewOpened, setPreviewOpened] = useState(false);
    const [color, setColor] = useState(client?.profile_img_color || null);
    let profileImageRef = useRef(null);
    let colorPickerRef = useRef(null);
    const { accountType } = useClientUser();

    useEffect(() => {
        if (client?.profile_img_color || partnership?.profile_img_color) {
            setColor(client?.profile_img_color || partnership?.profile_img_color);
        }
    }, [client, partnership]);

    const shownPartnershipLogoInfo = useMemo(() => {
        if (partnershipProfileImageVariables) {
            return partnershipProfileImageVariables;
        } else
            return {
                profile_img: partnership?.profile_img,
                profile_img_color: partnership?.profile_img_color,
            };
    }, [partnershipProfileImageVariables, partnership]);

    const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMAGE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const setBackgroundColor = (color) => {
        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables((prev) => ({
                ...prev,
                profile_img_color: color,
            }));
        } else {
            setColor(color);
        }
    };

    const uploadFile = async (e) => {
        setUploading(true);

        const file = e.target.files[0];
        const fileType = file.type.split('/')[1];

        let fileObject = await URL.createObjectURL(file);
        let fbFile = await uploadPhotoAsync(fileObject, accountType, fileType);

        const variables = {
            profile_img: fbFile,
            profile_img_color: null,
        };

        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables(variables);
        } else {
            await updateProfileImage({
                variables: {
                    client_id: client.client_id,
                    _set: variables,
                },
            });
        }

        e.target.value = '';
        setUploading(false);
        setPreviewOpened(false);
        setColor(null);
    };

    const deleteImage = async (e) => {
        setPreviewOpened(false);

        const variables = {
            profile_img: null,
            profile_img_color: null,
        };

        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables(variables);
        } else {
            await updateProfileImage({
                variables: {
                    client_id: client.client_id,
                    _set: variables,
                },
            });
        }
    };

    const saveBackgroundColor = async (e) => {
        const variables = {
            profile_img_color: shownPartnershipLogoInfo.profile_img_color || color,
        };

        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables((prev) => ({
                ...prev,
                ...variables,
            }));
        } else {
            await updateProfileImage({
                variables: {
                    client_id: client.client_id,
                    _set: variables,
                },
            });
        }
    };

    return (
        <>
            <Card
                css={css`
                    margin-top: 30px;
                `}
            >
                <Card.Body
                    css={css`
                        padding: 28px 26px 28px 26px;
                    `}
                >
                    <LocationPermissionsTitle>
                        <LocationPermissionsTitleSpan>Company Logo</LocationPermissionsTitleSpan>
                    </LocationPermissionsTitle>
                    <Grid container direction="column">
                        <Grid
                            item
                            css={css`
                                margin-bottom: 15px;
                            `}
                        >
                            <LocationPermissionsText>
                                Upload {partnership?.partnership_id ? '' : capitalize(accountType)} Logo
                            </LocationPermissionsText>
                            <CardSubtext>
                                This image will display sitewide on customer-facing pages, such as the tracking link
                                they are sent to track their delivery progress. The image will be resized to a height of
                                50 pixels.
                            </CardSubtext>
                        </Grid>

                        <Grid item container direction="row" justifyContent="flex-start">
                            <Grid item>
                                <PrimaryButton onClick={() => profileImageRef.current.click()} disabled={uploading}>
                                    Upload
                                </PrimaryButton>
                                <input
                                    type="file"
                                    accept=".png,.jpg,.jpeg"
                                    ref={profileImageRef}
                                    name="profileImage"
                                    onChange={uploadFile}
                                    css={css`
                                        display: none;
                                    `}
                                />
                            </Grid>

                            {(client?.profile_img || shownPartnershipLogoInfo?.profile_img) && (
                                <Grid
                                    item
                                    css={css`
                                        margin-left: 1rem;
                                    `}
                                >
                                    <WarningButton onClick={deleteImage}>Remove Image</WarningButton>
                                </Grid>
                            )}
                        </Grid>

                        {(client?.profile_img || shownPartnershipLogoInfo?.profile_img) && (
                            <>
                                <LocationPermissionsText
                                    css={css`
                                        margin-top: 30px;
                                    `}
                                >
                                    Select Banner Background Color
                                </LocationPermissionsText>

                                <Grid item container direction="row" alignItems="center">
                                    <Grid
                                        item
                                        css={css`
                                            display: flex;
                                        `}
                                    >
                                        <input
                                            type="color"
                                            value={color || '#0c0b1d'}
                                            ref={colorPickerRef}
                                            css={css`
                                                height: 52px;
                                                width: 52px;
                                            `}
                                            onChange={(e) => setBackgroundColor(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        css={css`
                                            margin-left: 25px;
                                        `}
                                    >
                                        <PrimaryButton onClick={saveBackgroundColor}>Save</PrimaryButton>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {(client?.profile_img || shownPartnershipLogoInfo?.profile_img) && (
                            <>
                                <LocationPermissionsText
                                    css={css`
                                        margin-top: 30px;
                                    `}
                                >
                                    Banner Preview
                                </LocationPermissionsText>
                                <Grid>
                                    <div
                                        css={css`
                                            display: flex;
                                            justify-content: center;
                                            background-color: ${shownPartnershipLogoInfo?.profile_img_color ||
                                            color ||
                                            onwardNavy};
                                        `}
                                    >
                                        <img
                                            css={css`
                                                height: 50px;
                                                object-fit: cover;
                                            `}
                                            src={client?.profile_img || shownPartnershipLogoInfo?.profile_img}
                                        />
                                    </div>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Card.Body>
            </Card>
        </>
    );
};

export default ProfileImageUpload;
