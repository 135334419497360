import { gql } from '@apollo/client';
import { CSV_MAPPINGS_FIELDS } from '@/graphql/queries/csv_mappings';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';

export const UPSERT_CSV_MAPPING = gql`
    ${CSV_MAPPINGS_FIELDS}

    mutation UpsertCsvMapping($mapping: csv_mappings_insert_input!) {
        insert_csv_mappings(
            objects: [$mapping]
            on_conflict: { constraint: csv_mappings_pkey, update_columns: [mapping, name] }
        ) {
            returning {
                ...CsvMappingsFields
            }
        }
    }
`;

export const DELETE_CSV_MAPPING = gql`
    mutation DeleteCsvMapping($mapping_id: uuid!) {
        removed: delete_csv_mappings(where: { mapping_id: { _eq: $mapping_id } }) {
            affected_rows
            returning {
                mapping_id
            }
        }
    }
`;

export const UPSERT_ORDERS = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}

    mutation UpsertOrders($orders: [orders_insert_input!]!, $items: [items_insert_input!]!, $removals: [uuid!]!) {
        insert_orders(
            objects: $orders
            on_conflict: {
                constraint: orders_pkey
                update_columns: [
                    admin_carrier_rate_override
                    admin_shipper_rate_override
                    airline
                    assembly_required
                    broker_billed_rate
                    broker_paid_rate
                    carrier_notes
                    carrier_rate
                    carrier_id
                    completion_source
                    completion_time
                    del_elev
                    del_stairs
                    del_window_end
                    del_window_start
                    delivery_date
                    delivery_stair_quantity
                    distance
                    dropoff_address
                    dropoff_address_geocode_failed
                    dropoff_address_is_custom
                    dropoff_city
                    dropoff_comments
                    dropoff_email
                    dropoff_full_address
                    dropoff_lat
                    dropoff_lng
                    dropoff_location
                    dropoff_location_info
                    dropoff_location_type
                    dropoff_name
                    dropoff_phone
                    dropoff_state
                    dropoff_street
                    dropoff_unit
                    dropoff_zip
                    duration_seconds
                    equipment_overrides
                    estimated_delivery_range_start
                    estimated_delivery_range_end
                    first_available_date
                    haulaway_items
                    job_type
                    known_availability
                    manufacturer
                    miles
                    no_contact
                    oms
                    onward_notes
                    order_revenue
                    order_revenue_override
                    order_status
                    order_tags
                    order_type
                    pallet_debris_removal_notes
                    pallet_flatbed_truck
                    pallet_forklift_at_dropoff
                    pallet_forklift_at_pickup
                    pallet_unloading_required
                    pallet_warehouse_close_time
                    palletize_returns
                    palletized_dimensions
                    pickup_address
                    pickup_address_geocode_failed
                    pickup_address_is_custom
                    pickup_city
                    pickup_comments
                    pickup_date
                    pickup_email
                    pickup_full_address
                    pickup_lat
                    pickup_lng
                    pickup_location
                    pickup_location_info
                    pickup_location_type
                    pickup_name
                    pickup_phone
                    pickup_stair_quantity
                    pickup_state
                    pickup_street_address
                    pickup_unit
                    pickup_window_end
                    pickup_window_start
                    pickup_zip
                    po_duplicate_override
                    po_number
                    preferred_delivery_date
                    price_breakdown
                    recommended_equipment
                    reference_id
                    secondary_pickup_contact_email
                    secondary_pickup_contact_name
                    secondary_pickup_contact_phone
                    selected_type
                    service_level
                    service_level_instructions
                    shipper_id
                    shipper_notes
                    shipper_rate
                    show_revenue_on
                    source_form
                    alternative_delivery_dates
                    warehouse_control_entity
                ]
            }
        ) {
            returning {
                ...OrderFields
            }
        }
        insert_items(
            objects: $items
            on_conflict: {
                constraint: items_pkey
                update_columns: [
                    assembly_type
                    cubes_per_unit
                    description
                    height
                    is_return
                    item_piece_count
                    item_type
                    item_type_details
                    length
                    pallet_id
                    quantity
                    sku
                    total_cubes
                    total_weight
                    weight
                    width
                ]
            }
        ) {
            returning {
                ...ItemFields
            }
        }
        delete_items(where: { item_id: { _in: $removals } }) {
            affected_rows
            returning {
                item_id
            }
        }
    }
`;

export const UPSERT_ORDER_DYNAMIC = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}

    mutation UpsertOrdersDynamic(
        $orders: [orders_insert_input!]!
        $items: [items_insert_input!]!
        $removals: [uuid!]!
        $order_update_cols: [orders_update_column!]! = []
        $item_update_cols: [items_update_column!]! = []
    ) {
        insert_orders(objects: $orders, on_conflict: { constraint: orders_pkey, update_columns: $order_update_cols }) {
            returning {
                ...OrderFields
            }
        }
        insert_items(objects: $items, on_conflict: { constraint: items_pkey, update_columns: $item_update_cols }) {
            returning {
                ...ItemFields
            }
        }
        delete_items(where: { item_id: { _in: $removals } }) {
            affected_rows
            returning {
                item_id
            }
        }
    }
`;

export const DELETE_ORDER = gql`
    ${ORDER_FIELDS}
    mutation DeleteOrder($order_id: uuid!) {
        removed: update_orders(
            where: { order_id: { _eq: $order_id } }
            _set: { job_id: null, order_status: "deleted" }
        ) {
            returning {
                ...OrderFields
            }
        }
    }
`;

export const CONFIRM_ORDERS = gql`
    mutation ConfirmOrders($order_ids: [uuid!] = []) {
        confirmed_pending: update_orders(
            where: {
                _and: [
                    { order_id: { _in: $order_ids } }
                    { order_status: { _eq: "pending_confirmation" } }
                    { carrier_id: { _is_null: true } }
                ]
            }
            _set: { order_status: "pending" }
        ) {
            affected_rows
        }

        confirmed_claimed: update_orders(
            where: {
                _and: [
                    { order_id: { _in: $order_ids } }
                    { order_status: { _eq: "pending_confirmation" } }
                    { carrier_id: { _is_null: false } }
                ]
            }
            _set: { order_status: "claimed" }
        ) {
            affected_rows
        }
    }
`;

export const OVERRIDE_DUPLICATES = gql`
    mutation OverrideDuplicates($order_ids: [uuid!] = []) {
        updated: update_orders(
            where: { _and: [{ order_id: { _in: $order_ids } }, { order_status: { _eq: "duplicate" } }] }
            _set: { order_status: "pending_confirmation", po_duplicate_override: true }
        ) {
            affected_rows

            returning {
                order_id
                order_number
                order_status
                po_duplicate_override
            }
        }
    }
`;

export const CLEAR_DUPLICATES = gql`
    mutation ClearDuplicates($order_ids: [uuid!] = []) {
        removed: update_orders(
            where: { _and: [{ order_id: { _in: $order_ids } }, { order_status: { _eq: "duplicate" } }] }
            _set: { job_id: null }
        ) {
            affected_rows

            returning {
                order_id
                order_number
                order_status
                po_duplicate_override
            }
        }
    }
`;

export const UPDATE_ITEM_PIECE_COUNTS = gql`
    mutation UpdateItemPieceCounts(
        $item_updates: [items_updates!]! = {}
        $order_id_string: String
        $client_id: uuid!
        $order_id: uuid!
    ) {
        update_items_many(updates: $item_updates) {
            returning {
                item_id
                item_piece_count
            }
        }
        update_orders_by_pk(pk_columns: { order_id: $order_id }, _set: { piece_count_verified: true }) {
            order_id
            piece_count_verified
        }
        update_notifications(
            where: {
                notification_data: { _cast: { String: { _ilike: $order_id_string } } }
                notification_status: { _eq: "UNRESOLVED" }
                notification_type: { _eq: "CUSTOMER_SHIPMENT_FORM_PIECE_COUNT_VERIFICATION" }
                user_id: { _eq: $client_id }
            }
            _set: { notification_status: "RESOLVED" }
        ) {
            returning {
                user_id
                notification_type
                notification_status
                notification_id
                notification_data
            }
        }
    }
`;
