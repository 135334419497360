import { useClientUser } from '@/hooks';
import React, { useMemo, useState } from 'react';
import { TabCard } from '../blocks';
import { css } from '@emotion/react';
import { Autocomplete } from '@material-ui/lab';
import { OnwardChip } from '@/styles/blocks';
import { Grid, TextField } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { UPDATE_CLIENT } from './graphql';

const CustomTags = () => {
    const { order_tags, user_id } = useClientUser();

    const [updateClient, { loading }] = useMutation(UPDATE_CLIENT);

    const tags = useMemo(() => {
        return Object.entries(order_tags || {})
            .filter(([_, enabled]) => !!enabled)
            .map(([key]) => key);
    }, [order_tags]);

    const onSave = (patch) => {
        updateClient({
            variables: {
                client_id: user_id,
                update: patch,
            },
        });
    };

    return (
        <TabCard
            css={css`
                padding: 2rem;
            `}
        >
            <Grid
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                `}
            >
                <Grid
                    item
                    css={css`
                        width: 600px;
                    `}
                >
                    <Autocomplete
                        disabled={loading}
                        multiple
                        value={tags}
                        options={[]}
                        freeSolo
                        onChange={(e, value = []) => {
                            const newTags = Object.fromEntries(value.map((tag) => [tag, true]));
                            onSave({ order_tags: newTags });
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((tag, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return <OnwardChip key={key} label={tag} {...tagProps} />;
                            })
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Order Tags"
                                onKeyDown={(e) => {
                                    if (['Backspace'].includes(e.key)) {
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </TabCard>
    );
};

export default CustomTags;
