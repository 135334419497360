import React, { useState, useMemo, useCallback } from 'react';
import { css } from '@emotion/react';
import {
    Grid,
    TextField,
    FormControlLabel,
    TextareaAutosize,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { enrich } from '@/components/ShipmentForm/utilities/processOrders';
import { validatePhoneNumber } from '@/utilities/validatePhoneNumber';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { DropoffType } from '@/components/ShipmentForm/modals/InputFields';
import { colors } from '@/styles';
import {
    local,
    FlexRow,
    FlexColumn,
    DefaultResponsiveTextField,
    Header1,
    SubHeader1,
    BodyDescription1,
} from '../blocks';
import { SUPPORT_PHONE_NUMBER } from '../constants';

import Footer from './Footer';
import ProgressBar from './ProgressBar';

const REQUIRED_FIELDS = [
    'dropoff_phone',
    'dropoff_email',
    'dropoff_name',
    'dropoff_address',
    'dropoff_full_address',
    'dropoff_street',
    'dropoff_city',
    'dropoff_state',
    'dropoff_zip',
    'dropoff_location',
    'dropoff_location_type',
];

export function ServiceLevelSelection({ section, setSection, isEmpty = false, isDirty = false, order }) {
    const [open, setOpen] = useState(false);

    return (
        <FlexRow
            css={css`
                margin-bottom: 20px;
            `}
        >
            <FlexColumn>
                <DropoffType
                    value={section.dropoff_location_type}
                    locationType={section.dropoff_location}
                    onChange={(e) => setSection(e.target.value)}
                    error={!!isEmpty && isEmpty('dropoff_location_type') && isDirty.dropoff_location_type}
                    hiddenSelections={section?.hiddenSelections}
                    order={order}
                />
            </FlexColumn>
            <FlexColumn>
                <Button variant="text" color="primary" onClick={() => setOpen(true)}>
                    <u>Need help? Click for descriptions.</u>
                </Button>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <u>{'Service Types'}</u>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <strong>White Glove:</strong> Unboxing, assembly, installation (does not include water line,
                            gas line, or complex appliance installation), and debris removal.
                            <br />
                            <strong>Room of Choice:</strong>Placing the item in the customer's room of choice. Does not
                            include unpacking, debris removal, or assembly.
                            <br />
                            <strong>Threshold:</strong> Placing the item inside the front door.
                            <br />
                            <strong>Curbside/FDS:</strong> Placing the item on the front walk or inside the garage.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </FlexColumn>
        </FlexRow>
    );
}

function Dropoff({ isDesktop, callbacks, state }) {
    const [section, setSection] = useState(
        state.sections[state.progression.current] || { dropoff_location: 'Residence' }
    );
    const [isDirty, setDirty] = useState({});

    // may want to require this in the future
    // if (state.progression.type === 'BUSINESS') {
    //     REQUIRED_FIELDS.push('po_number');
    // }

    const isEmpty = useCallback(
        (field) => {
            const invalid = REQUIRED_FIELDS.includes(field) && (!section[field] || section[field].length === 0);
            switch (field) {
                case 'dropoff_phone':
                    return invalid || !validatePhoneNumber(section[field]);
                default:
                    return invalid;
            }
        },
        [section]
    );

    const hasError = useMemo(() => {
        return REQUIRED_FIELDS.some((field) => isEmpty(field));
    }, [isEmpty]);

    return (
        <Grid
            container
            direction="column"
            css={css`
                color: ${local.greys[3]};
            `}
        >
            <ProgressBar step={state.progression.current + 1} maxSteps={7} />
            <Grid
                container
                css={css`
                    margin-bottom: 28px;
                    color: ${local.greys[4]};
                `}
            >
                <Header1>Delivery Information</Header1>
            </Grid>

            <FlexRow>
                {[
                    { display: 'Contact Name', value: 'dropoff_name' },
                    { display: 'Email', value: 'dropoff_email' },
                ].map(({ display, value }) => (
                    <DefaultResponsiveTextField
                        key={value}
                        display={display}
                        value={value}
                        state={section}
                        isDirty={isDirty}
                        callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                    />
                ))}
                <FlexColumn
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        label="Phone"
                        InputProps={{
                            inputComponent: PhoneNumberInput,
                        }}
                        InputLabelProps={{ shrink: !!section.dropoff_phone }}
                        value={section.dropoff_phone ? toNational(section.dropoff_phone) : ''}
                        onBlur={(e) => {
                            setDirty((prev) => ({ ...prev, dropoff_phone: true }));
                        }}
                        onChange={(e) => {
                            setSection((prev) => ({ ...prev, dropoff_phone: toE164(e.target.value) }));
                        }}
                        error={isEmpty('dropoff_phone') && isDirty.dropoff_phone}
                    />
                </FlexColumn>
                <FlexColumn
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    {['dropoff_address', 'dropoff_street', 'dropoff_city', 'dropoff_state', 'dropoff_zip'].some(
                        (attr) => isEmpty(attr) && isDirty[attr]
                    ) ? (
                        <Grid
                            item
                            css={css`
                                margin-bottom: 20px;
                            `}
                        >
                            <SubHeader1
                                css={css`
                                    color: #d23e3e;
                                `}
                            >
                                {`Address not found. For help please contact us at ${toNational(
                                    SUPPORT_PHONE_NUMBER
                                )}.`}
                            </SubHeader1>
                        </Grid>
                    ) : null}
                    <Grid item>
                        <AddressAutocomplete
                            state={{
                                street: section.dropoff_street,
                                city: section.dropoff_city,
                                state: section.dropoff_state,
                                zip: section.dropoff_zip,
                            }}
                            onBlur={(e) => {
                                setDirty((prev) => ({
                                    ...prev,
                                    ...Object.fromEntries(
                                        [
                                            'dropoff_address',
                                            'dropoff_street',
                                            'dropoff_city',
                                            'dropoff_state',
                                            'dropoff_zip',
                                        ].map((field) => [field, true])
                                    ),
                                }));
                            }}
                            handleAddressUpdate={async (value) => {
                                const [{ geocodePartialMatch, geocodeFailed, ...enriched }] = await enrich(
                                    { dropoff_address: value },
                                    {}
                                );
                                if (geocodePartialMatch || geocodeFailed) {
                                    setSection((prev) => ({
                                        ...prev,
                                        dropoff_address: value,
                                        dropoff_street: null,
                                        dropoff_city: null,
                                        dropoff_zip: null,
                                        dropoff_state: null,
                                    }));
                                } else {
                                    setSection((prev) => ({ ...prev, ...enriched }));
                                }
                            }}
                            error={[
                                'dropoff_address',
                                'dropoff_street',
                                'dropoff_city',
                                'dropoff_state',
                                'dropoff_zip',
                            ].some((attr) => isEmpty(attr) && isDirty[attr])}
                        />
                    </Grid>
                </FlexColumn>
                {[
                    { display: 'Unit/Suite/Lot', value: 'dropoff_unit' },
                    { display: 'City', value: 'dropoff_city', disabled: true },
                    { display: 'State', value: 'dropoff_state', disabled: true },
                    { display: 'Zip', value: 'dropoff_zip', disabled: true },
                ].map(({ display, value, disabled }) => (
                    <DefaultResponsiveTextField
                        disabled={!!disabled}
                        key={value}
                        display={display}
                        value={value}
                        state={section}
                        isDirty={isDirty}
                        callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                    />
                ))}
            </FlexRow>
            <FlexRow
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <FormControlLabel
                    control={
                        <OnwardCheckbox
                            color="primary"
                            checked={section.del_stairs || section.del_elev}
                            onChange={() =>
                                setSection((prev) => ({
                                    ...prev,
                                    del_stairs: !prev.del_stairs,
                                    del_elev: !prev.del_elev,
                                }))
                            }
                        />
                    }
                    label="Delivery is above first level and requires an elevator or stairs to reach."
                    css={css`
                        margin-left: 0;
                    `}
                />
            </FlexRow>
            <ServiceLevelSelection
                section={section}
                setSection={(newVal) => {
                    setSection((prev) => ({ ...prev, dropoff_location_type: newVal }));
                }}
                isEmpty={isEmpty}
                isDirty={isDirty}
                order={state?.order}
            />
            <FlexRow>
                <DefaultResponsiveTextField
                    key={'po_number'}
                    display={'PO Number'}
                    value={'po_number'}
                    state={section}
                    isDirty={isDirty}
                    callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                />
                <BodyDescription1
                    css={css`
                        margin-top: -10px;
                        margin-bottom: 20px;
                        color: ${local.greys[1]};
                    `}
                >
                    PO number is your purchase or sales number from the store. This will be used by the carrier to
                    identify your order on pick up. Required if you chose 'Store-to-Door' delivery.
                </BodyDescription1>
            </FlexRow>

            <Grid
                item
                css={css`
                    margin-bottom: 15px;
                `}
            >
                <TextareaAutosize
                    color="primary"
                    css={css`
                        width: 100%;
                        padding: 14px;
                        resize: none;
                        border-radius: 5px;
                        background: #f0f0f0;
                    `}
                    minRows={4}
                    placeholder="Notes"
                    value={section.dropoff_comments}
                    onChange={(e) => {
                        setSection((prev) => ({
                            ...prev,
                            dropoff_comments: e.target.value,
                        }));
                    }}
                />
            </Grid>

            <Grid item>
                <span
                    css={css`
                        color: ${colors.greys.primary};
                        font-size: 14px;
                        font-weight: 500;
                    `}
                >
                    * By providing a telephone number and submitting the form you are consenting to be contacted by SMS
                    text message. Message & data rates may apply. Reply STOP to opt out of further messaging.
                </span>
            </Grid>

            <Footer
                state={state}
                isFirst={state.isFirstStage}
                isLast={state.isLastStage}
                hasErrors={hasError}
                callbacks={{ advance: () => callbacks.advance(section), revert: callbacks.revert }}
            />
        </Grid>
    );
}

export default Dropoff;
