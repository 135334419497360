import React, { useMemo, useState, useRef } from 'react';
import {
    Grid,
    Button,
    Tooltip,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Typography,
    InputAdornment,
    TextField as MuiTextField,
    Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from '@emotion/styled';
import { Add, Remove, Info } from '@material-ui/icons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { css } from '@emotion/react';
import { colors } from '@/styles/';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import dateFns from '@/utilities/dateFns';
import DIMENSIONS from '@/constants/bedItemDims';
import APPLIANCE_DIMENSIONS from '@/constants/applianceDims';
import FURNITURE_DIMENSIONS from '@/constants/furnitureDims';
import STATES from '@/constants/states';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { IconButton } from '@material-ui/core';
import { TextField, OnwardCheckbox } from '../blocks';
import PhoneNumberInput from '../../PhoneNumberInput';
import format from 'date-fns-tz/format';

import {
    LOCATION_TYPES,
    DROPOFF_TYPES,
    RESIDENCE_TYPES,
    PICKUP_TYPES,
} from '@/components/ShipmentForm/constants/dropoffOptions';

import { ITEM_TYPES, BED_SIZES, ASSEMBLY_TYPES } from '@/components/ShipmentForm/constants/freightTypes';
import { startCase } from 'lodash';
import { ORDER_STATUS_OPTIONS, ROUTED_ORDER_STATUS_OPTIONS } from '../constants/statusOptions';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { asDateInTZ, convertToISO } from '@/utilities/convertToISO';
import { getTimezoneOffset } from 'date-fns-tz';
import { localFormatter, routeTimeZoneFormatter } from '../utilities/formats';
import { DatePicker, LocalizationProvider, PickersDay, pickersDayClasses, StaticDatePicker } from '@mui/x-date-pickers';
import { isSameDay, parseISO } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const PICKUP_INFO_FIELDS = {
    NAME: 'pickupname',
    SECONDARY_NAME: 'secondaryPickupContactName',
    PHONE: 'pickupphone',
    SECONDARY_PHONE: 'secondaryPickupContactPhone',
    EMAIL: 'pickupemail',
    SECONDARY_EMAIL: 'secondaryPickupContactEmail',
    STORE: 'store',
    FIRST_AVAILABLE: 'firstAvailableDate',
    COMMENTS: 'pickupcomments',
    NEW_ADDRESS: 'newAddress',
    FULL_ADDRESS: 'pickupaddress',
    STREET: 'pickupstreetaddress',
    CITY: 'pickupcity',
    STATE: 'pickupstate',
    ZIP: 'pickupzip',
    ADDRESS_LINE_2: 'pickupunit',
    LOCATION_TYPE: 'pickuplocation',
    RESIDENCE_TYPE: 'residenceType',
    PICKUP_TYPE: 'pickuplocationtype',
    LAT: 'pulat',
    LONG: 'pulng',
    IS_CUSTOM: 'isCustom',
};

export const Name = ({ value, onChange, onBlur, error, name = PICKUP_INFO_FIELDS.NAME, isSecondary = false }) => {
    if (isSecondary) {
        name = PICKUP_INFO_FIELDS.SECONDARY_NAME;
    }

    return (
        <TextField
            label="Name *"
            variant="outlined"
            name={name}
            fullWidth
            value={value}
            onChange={onChange}
            obBlur={onBlur}
            error={error}
            helperText={null}
            disabled={null}
        />
    );
};

export const Phone = ({ value, onChange, onBlur, error, name = PICKUP_INFO_FIELDS.PHONE, isSecondary = false }) => {
    if (isSecondary) {
        name = PICKUP_INFO_FIELDS.SECONDARY_PHONE;
    }

    return (
        <TextField
            label="Phone *"
            variant="outlined"
            name={name}
            InputLabelProps={{ shrink: !!value }}
            InputProps={{
                inputComponent: PhoneNumberInput,
            }}
            fullWidth
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            helperText={null}
            disabled={null}
        />
    );
};

export const Email = ({ value, onChange, onBlur, error, name = PICKUP_INFO_FIELDS.EMAIL, isSecondary = false }) => {
    if (isSecondary) {
        name = PICKUP_INFO_FIELDS.SECONDARY_EMAIL;
    }

    return (
        <TextField
            label="E-mail (Optional)"
            variant="outlined"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={{ width: '100%' }}
            helperText={null}
            error={error}
            disabled={null}
        />
    );
};

export const SecondaryContactBtn = ({ hasSecondaryContact = false, onClick }) => (
    <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        style={
            hasSecondaryContact
                ? {
                      backgroundColor: '#d23e3e',
                      '&:hover': {
                          backgroundColor: '#a32f2f',
                      },
                      color: '#fff',
                  }
                : { color: '#fff' }
        }
    >
        {hasSecondaryContact ? 'Remove' : 'Add'} Secondary Contact
    </Button>
);

export const StoreSelect = ({ value, onChange, error, storeList = [] }) => (
    <TextField
        select
        disabled={storeList?.length === 0}
        variant="outlined"
        label="Store"
        name={PICKUP_INFO_FIELDS.STORE}
        value={value === null ? '' : value}
        onChange={onChange}
        error={error}
        InputLabelProps={{
            shrink: value !== null,
        }}
        fullWidth
    >
        {Object.keys(storeList).map((storeKey) => (
            <MenuItem key={storeKey} value={storeKey}>
                {storeList[storeKey].location_name}
            </MenuItem>
        ))}
    </TextField>
);

export const SingleDatePicker = ({ value, onChange, error, tooltipText, label, disableDate }) => {
    return (
        <Grid
            container
            direction="row"
            wrap="nowrap"
            css={css`
                align-items: center;
            `}
        >
            <Grid
                container
                item
                css={css`
                    margin-right: 8px;
                    flex: 1;
                `}
            >
                <TextField
                    disabled={disableDate}
                    fullWidth
                    type="date"
                    variant="outlined"
                    label={label}
                    className="mr-3"
                    InputLabelProps={{ shrink: true }}
                    name={PICKUP_INFO_FIELDS.FIRST_AVAILABLE}
                    value={value}
                    onChange={onChange}
                    error={error}
                    helperText={null}
                />
            </Grid>

            {tooltipText ? (
                <Grid
                    container
                    item
                    css={css`
                        flex: 0;
                    `}
                >
                    <Tooltip style={{ color: '#59B863' }} title={tooltipText} placement="top">
                        <Info />
                    </Tooltip>
                </Grid>
            ) : null}
        </Grid>
    );
};

export const PreferredDeliveryDatePicker = ({ value, altDates, onChange, tooltipText }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen((prev) => !prev);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const calendarButton = (
        <IconButton
            onClick={() => handleOpen()}
            css={css`
                padding: 0px !important;
            `}
        >
            <CalendarTodayOutlinedIcon
                css={css`
                    color: ${colors.greens.primary};
                `}
            />
        </IconButton>
    );

    const isAltDate = (day) => {
        return altDates.includes(day.toISOString());
    };

    return (
        <Grid
            container
            direction="row"
            wrap="nowrap"
            css={css`
                align-items: center;
            `}
        >
            <Grid
                container
                item
                css={css`
                    margin-right: 8px;
                    flex: 1;
                `}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        open={open}
                        onClose={() => handleClose()}
                        value={value ? new Date(value) : null}
                        disableHighlightToday={true}
                        fullWidth
                        disablePast={true}
                        label="Preferred Delivery Date *"
                        placeholder="MM/DD/YYYY"
                        shouldDisableDate={isAltDate}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                InputLabelProps: { shrink: true },
                                InputProps: {
                                    endAdornment: calendarButton,
                                },
                            },
                        }}
                        onChange={onChange}
                    />
                </LocalizationProvider>
            </Grid>

            {tooltipText ? (
                <Grid
                    container
                    item
                    css={css`
                        flex: 0;
                    `}
                >
                    <Tooltip style={{ color: '#59B863' }} title={tooltipText} placement="top">
                        <Info />
                    </Tooltip>
                </Grid>
            ) : null}
        </Grid>
    );
};

export const AlternativeDeliveryDatePicker = ({ values, prefDate, tooltipText, timeZone, onSelect }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen((prev) => !prev);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const calendarButton = (
        <IconButton
            onClick={() => handleOpen()}
            css={css`
                padding: 0px !important;
            `}
        >
            <CalendarTodayOutlinedIcon
                css={css`
                    color: ${colors.greens.primary};
                `}
            />
        </IconButton>
    );

    const isPrefDate = (day) => {
        return prefDate === day.toISOString();
    };

    const valueDislay = useMemo(() => {
        return values
            .map((date) => {
                return format(new Date(date), 'MM/dd/yyyy');
            })
            .join(', ');
    }, [values]);

    return (
        <Grid
            container
            direction="row"
            wrap="nowrap"
            css={css`
                align-items: center;
            `}
        >
            <Grid
                container
                item
                css={css`
                    margin-right: 8px;
                    flex: 1;
                `}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        open={open}
                        onClose={() => handleClose()}
                        fullWidth
                        disableHighlightToday={true}
                        label="Alternative Delivery Dates *"
                        placeholder="MM/DD/YYYY"
                        disablePast={true}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                InputLabelProps: { shrink: true },
                                InputProps: {
                                    endAdornment: calendarButton,
                                    value: !!valueDislay ? valueDislay : '',
                                },
                            },
                        }}
                        closeOnSelect={false}
                        shouldDisableDate={isPrefDate}
                        slots={{
                            day: ({ onDaySelect, ...props }) => {
                                const isHighlighted = values.includes(props.day.toISOString());
                                const matchedStyles = values.reduce((a, v) => {
                                    const date = new Date(props.day);
                                    return isSameDay(date, parseISO(v))
                                        ? { backgroundColor: colors.greens.primary }
                                        : a;
                                }, {});

                                return (
                                    <PickersDay
                                        onDaySelect={() => {}}
                                        onClick={() => {
                                            let _timestamp = props['data-timestamp'];
                                            onSelect(_timestamp);
                                        }}
                                        style={{
                                            color: isHighlighted && 'white',
                                            backgroundColor: isHighlighted && colors.greens.primary,
                                            borderRadius: '50%',
                                        }}
                                        sx={{
                                            ...matchedStyles,
                                            [`&&.${pickersDayClasses.selected}`]: {
                                                backgroundColor: colors.greens.primary,
                                            },
                                        }}
                                        {...props}
                                    />
                                );
                            },
                        }}
                    />
                </LocalizationProvider>
            </Grid>

            {tooltipText ? (
                <Grid
                    container
                    item
                    css={css`
                        flex: 0;
                    `}
                >
                    <Tooltip style={{ color: '#59B863' }} title={tooltipText} placement="top">
                        <Info />
                    </Tooltip>
                </Grid>
            ) : null}
        </Grid>
    );
};

export const Comments = ({ value, onChange }) => (
    <TextField
        type="text"
        variant="outlined"
        style={{ width: '100%' }}
        label="Pickup Comments"
        name={PICKUP_INFO_FIELDS.COMMENTS}
        multiline={true}
        value={value}
        onChange={onChange}
        helperText={null}
        css={css`
            & .MuiOutlinedInput-input {
                height: 45px;
            }
        `}
    />
);

export const NewAddressCheckbox = ({ hasNewAddress, onChange, ...rest }) => (
    <FormControlLabel
        control={
            <OnwardCheckbox
                color="primary"
                checked={hasNewAddress || false}
                onChange={onChange}
                name={PICKUP_INFO_FIELDS.NEW_ADDRESS}
                {...rest}
            />
        }
        label="Enter new address"
        css={css`
            margin-left: -8px;
        `}
    />
);

export const Address = ({
    onBlur,
    handleAddressUpdate,
    error,
    state = {
        pickupstreetaddress: '',
        pickupcity: '',
        pickupstate: '',
        pickupzip: '',
    },
}) => (
    <AddressAutocomplete
        disabled={false}
        state={{
            street: state.pickupstreetaddress,
            city: state.pickupcity,
            state: state.pickupstate,
            zip: state.pickupzip,
        }}
        onBlur={onBlur}
        handleAddressUpdate={handleAddressUpdate}
        error={error}
        helperText={null}
    />
);

export const AddressLine2 = ({ value, error, onChange, onBlur }) => (
    <TextField
        variant="outlined"
        color="primary"
        label="Unit/Suite #"
        name={PICKUP_INFO_FIELDS.ADDRESS_LINE_2}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        InputLabelProps={{
            shrink: !!value,
        }}
        fullWidth
    />
);

export const City = ({ error, disabled, value, onChange, onBlur }) => (
    <TextField
        label="City"
        variant="outlined"
        color="primary"
        name={PICKUP_INFO_FIELDS.CITY}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        error={error}
        disabled={disabled}
        InputLabelProps={{
            shrink: !!value,
        }}
        helperText={null && 'Select a valid address'}
        fullWidth
    />
);

export const State = ({ error, disabled, value, onChange, onBlur }) => (
    <TextField
        variant="outlined"
        color="primary"
        label="State"
        name={PICKUP_INFO_FIELDS.STATE}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
        helperText={null && 'Select a valid address'}
        InputLabelProps={{
            shrink: !!value,
        }}
        fullWidth
    />
);

export const Zip = ({ error, disabled, value, onChange, onBlur }) => (
    <TextField
        variant="outlined"
        color="primary"
        label="Zip"
        name={PICKUP_INFO_FIELDS.ZIP}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        fullWidth
        InputLabelProps={{
            shrink: !!value,
        }}
        helperText={null && 'Select a valid address'}
    />
);

export const LocationType = ({ value, onChange, onBlur, error }) => (
    <TextField
        select
        variant="outlined"
        label="Location Type"
        name={PICKUP_INFO_FIELDS.LOCATION_TYPE}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        fullWidth
    >
        {LOCATION_TYPES.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))}
    </TextField>
);

export const ResidenceType = ({ value, onChange, onBlur, error }) => (
    <TextField
        select
        variant="outlined"
        label="Residence Type"
        name={PICKUP_INFO_FIELDS.RESIDENCE_TYPE}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        fullWidth
    >
        {RESIDENCE_TYPES.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))}
    </TextField>
);

export const PickupType = ({ value, onChange, onBlur, locationType, error, ...rest }) => (
    <TextField
        select
        variant="outlined"
        label="Pickup Type"
        name={PICKUP_INFO_FIELDS.PICKUP_TYPE}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        {...rest}
        fullWidth
    >
        {locationType &&
            (PICKUP_TYPES[locationType] || [])
                .filter((option) => (option.condition ? option.condition({}) : true))
                .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {typeof option.label === 'function' ? option.label('pickUp') : option.label}
                    </MenuItem>
                ))}
    </TextField>
);

export const DropoffType = ({
    value,
    order = {},
    onChange,
    onBlur,
    locationType = 'Residence',
    error,
    hiddenSelections = [],
    ...rest
}) => {
    return (
        <TextField
            select
            variant="outlined"
            label="Dropoff Type"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            {...rest}
            fullWidth
        >
            {locationType &&
                (DROPOFF_TYPES[locationType] || [])
                    .filter((option) => (option.condition ? option.condition(order) : true))
                    .filter((option) => !hiddenSelections.some((i) => i.value === option.value))
                    .map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {typeof option.label === 'function' ? option.label('dropOff') : option.label}
                        </MenuItem>
                    ))}
        </TextField>
    );
};

export const ServiceType = ({
    value,
    locationType,
    dropoffType,
    order = {},
    serviceLevels = [],
    onChange,
    onBlur,
    error,
    ...rest
}) => {
    const serviceTypesByKey = useMemo(() => {
        const dropoffTypes = (DROPOFF_TYPES[locationType || 'Residence'] || []).filter((option) =>
            option.condition ? option.condition(order) : true
        );

        return Object.fromEntries([
            ...serviceLevels.map((option) => [option.service_level, { ...option, label: option.service_level }]),
            ...dropoffTypes.map((option) => [
                `ONWARD_DEFAULT_OPTION_${option.value}`,
                {
                    location_type: option.value,
                    service_level: null,
                    instructions: null,
                    tags: {},
                    label: typeof option.label === 'function' ? option.label('dropOff') : option.label,
                },
            ]),
        ]);
    }, [serviceLevels, order, locationType]);

    // console.log(serviceTypesByKey);
    // console.log(value || `ONWARD_DEFAULT_OPTION_${dropoffType}`);

    return (
        <TextField
            select
            variant="outlined"
            label="Service Type"
            value={value || `ONWARD_DEFAULT_OPTION_${dropoffType}`}
            onChange={(e) => onChange(serviceTypesByKey[e.target.value])}
            onBlur={onBlur}
            error={error}
            fullWidth
            {...rest}
        >
            {Object.entries(serviceTypesByKey).map(([key, option]) => (
                <MenuItem key={key} value={key}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const StoreDetails = ({ storeName, fullAddress, locationType, pickupType }) => {
    const storeDetails = `border-left: 3px solid ${colors.greens.primary}; padding-left: 24px;`;
    return (
        <>
            <Typography
                css={css`
                    ${storeDetails}
                `}
            >
                {storeName}
            </Typography>
            <Typography
                css={css`
                    ${storeDetails}
                `}
            >
                {fullAddress}
            </Typography>
            {locationType && (
                <Typography
                    css={css`
                        ${storeDetails}
                    `}
                >
                    {locationType}, {pickupType}
                </Typography>
            )}
        </>
    );
};

export const ItemType = ({ isWillCall, value, onChange, error, ...rest }) => (
    <TextField select variant="outlined" value={value} onChange={onChange} error={error} fullWidth {...rest}>
        {[
            { label: isWillCall ? 'Will Call' : 'Delivery', value: 'delivery' },
            { label: 'Return', value: 'return' },
        ].map((type) => (
            <MenuItem key={type} value={type.value}>
                {type.label}
            </MenuItem>
        ))}
    </TextField>
);

export const ItemTypeDetails = ({ value, onChange, freightType, error, ...rest }) => {
    const options = ITEM_TYPES[freightType || 'household'] || [];
    const isCustom = !!value && value.length > 0 && !options.find(({ value: type }) => type === value);
    return (
        <TextField
            select
            variant="outlined"
            label="Item Type"
            value={isCustom ? 'custom' : value}
            onChange={onChange}
            error={error}
            fullWidth
            renderValue={(val) => {
                return val;
            }}
            {...rest}
            css={css`
                & .MuiFormHelperText-root {
                    margin: 4px 4px;
                }
            `}
        >
            {options.map((itemType) => (
                <MenuItem key={itemType.label} value={itemType.value}>
                    {itemType.label}
                </MenuItem>
            ))}
            {isCustom ? (
                <MenuItem key="Custom" value="custom">
                    Custom
                </MenuItem>
            ) : null}
        </TextField>
    );
};

export const FurnitureType = ({ value, onChange, error, ...rest }) => {
    return (
        <TextField
            select
            variant="outlined"
            label="Furniture Type"
            value={value}
            InputLabelProps={{ shrink: !!value }}
            onChange={(e) => {
                onChange(e.target.value);
            }}
            fullWidth
            error={error}
            {...rest}
        >
            {Object.keys(FURNITURE_DIMENSIONS).map((display) => (
                <MenuItem key={display} value={display}>
                    {display}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const Furniture = ({ value = {}, furnitureType, onChange, error, ...rest }) => {
    const { length, width, height } = value;
    const comp = ([key, item]) => item.length === length && item.width === width && item.height === height;
    const key = Object.entries(FURNITURE_DIMENSIONS[furnitureType]).find(comp)?.[0] || '';

    return (
        <TextField
            select
            variant="outlined"
            label="Furniture"
            value={key}
            InputLabelProps={{ shrink: !!value }}
            onChange={(e) => {
                onChange(FURNITURE_DIMENSIONS[furnitureType][e.target.value]);
            }}
            fullWidth
            error={error}
            {...rest}
        >
            {Object.entries(FURNITURE_DIMENSIONS[furnitureType] || {}).map(([key, furniture]) => (
                <MenuItem key={key} value={key}>
                    {furniture.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const Appliance = ({ value = {}, itemType, onChange, error, ...rest }) => {
    const { length, width, height, description = '' } = value;
    const applianceName = description.split(' - ')?.[1];
    let key = Object.entries(APPLIANCE_DIMENSIONS).find(([key, item]) => item.name === applianceName)?.[0] || '';

    if (!key) {
        const comp = ([key, item]) => item.length === length && item.width === width && item.height === height;
        key = Object.entries(APPLIANCE_DIMENSIONS).find(comp)?.[0] || '';
    }

    return (
        <TextField
            select
            variant="outlined"
            label="Appliance"
            value={key}
            InputLabelProps={{ shrink: !!value }}
            onChange={(e) => {
                onChange(APPLIANCE_DIMENSIONS[e.target.value]);
            }}
            fullWidth
            error={error}
            {...rest}
        >
            {Object.entries(APPLIANCE_DIMENSIONS).map(([key, appliance]) => (
                <MenuItem key={key} value={key}>
                    {appliance.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const BedSize = ({ value = {}, itemType, onChange, error, ...rest }) => {
    const { length, width, height } = value;
    let itemDimensions;
    let key;
    const comp = ([key, item]) => {
        return item.length === length && item.width === width && item.height === height;
    };

    switch (itemType) {
        case 'mattress':
            itemDimensions = DIMENSIONS.Mattress;
            key = Object.entries(DIMENSIONS.Mattress).find(comp)?.[0] || '';
            break;
        case 'boxSpring':
            itemDimensions = DIMENSIONS.Boxspring;
            key = Object.entries(DIMENSIONS.Boxspring).find(comp)?.[0] || '';
            break;
        case 'bedFrame':
            itemDimensions = DIMENSIONS.Bedframe.assembled;
            key = Object.entries(DIMENSIONS.Bedframe.assembled).find(comp)?.[0] || '';
            break;
    }
    const displayValue = useMemo(() => {
        return !!length || !!width || !!height
            ? BED_SIZES.find((size) => size.value === key)?.display || 'Custom'
            : null;
    }, [length, width, height, value]);

    const keyValue = useMemo(() => {
        return !!length || !!width || !!height ? key || 'Custom' : null;
    }, [length, width, height, value]);

    return (
        <TextField
            select
            variant="outlined"
            label="Size"
            value={keyValue}
            onChange={(e) => {
                onChange(itemDimensions[e.target.value]);
            }}
            SelectProps={{
                renderValue: (option) => {
                    return displayValue;
                },
            }}
            fullWidth
            error={error}
            {...rest}
        >
            {BED_SIZES.map((size) => (
                <MenuItem key={size.display} value={size.value}>
                    {size.display}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const AssemblyType = ({ value, onChange, error, ...rest }) => {
    return (
        <TextField
            select
            variant="outlined"
            label="Assembly Type"
            value={value}
            InputLabelProps={{ shrink: !!value }}
            onChange={onChange}
            fullWidth
            error={error}
            {...rest}
        >
            {ASSEMBLY_TYPES.map((type) => (
                <MenuItem key={type.display} value={type.value}>
                    <span
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;
                        `}
                    >
                        <span>{type.display}</span>
                        {type.description && (
                            <Tooltip
                                title={
                                    <span
                                        css={css`
                                            font-size: 14px;
                                        `}
                                    >
                                        {type.description}
                                    </span>
                                }
                            >
                                <InfoOutlinedIcon fontSize="small" color="success" />
                            </Tooltip>
                        )}
                    </span>
                </MenuItem>
            ))}
        </TextField>
    );
};

export const ItemIsPackagedSelect = ({ value, onChange, error, ...rest }) => {
    return (
        <TextField
            select
            variant="outlined"
            label="Item is packaged"
            value={value}
            InputLabelProps={{ shrink: true }}
            onChange={onChange}
            fullWidth
            error={error}
            {...rest}
        >
            <MenuItem key={'yes'} value={true}>
                <span>Yes</span>
            </MenuItem>
            <MenuItem key={'no'} value={false}>
                <span>No</span>
            </MenuItem>
        </TextField>
    );
};

export const ItemPieceCountSelect = ({ value, onChange, error, ...rest }) => {
    return (
        <TextField
            select
            variant="outlined"
            label="Box / Piece Count"
            value={value}
            InputLabelProps={{ shrink: !!value }}
            onChange={onChange}
            fullWidth
            error={error}
            {...rest}
        >
            {new Array(10).fill(0).map((arrayItem, idx) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                    <span>{idx + 1}</span>
                </MenuItem>
            ))}
        </TextField>
    );
};

export const StateSelect = ({ value, onChange, label = 'State', error, ...rest }) => {
    return (
        <TextField
            select
            variant="outlined"
            label={label}
            value={value}
            onChange={(e) => {
                onChange([e.target.value, STATES[e.target.value]]);
            }}
            error={error}
            fullWidth
            {...rest}
        >
            {Object.entries(STATES).map(([abb, full]) => (
                <MenuItem key={abb} value={abb}>
                    {full}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const ClientSelect = ({
    value,
    options,
    onChange,
    allowEmpty = false,
    type = 'shipper',
    customMenuRender,
    ...rest
}) => {
    return (
        <TextField
            select
            variant="outlined"
            label={startCase(type)}
            value={value || ''}
            onChange={(e) => {
                const val = e.target.value;
                if (!val && allowEmpty) {
                    onChange(null);
                } else if (val) {
                    onChange(val);
                }
            }}
            fullWidth
            {...rest}
        >
            {[...(allowEmpty ? [{ client_id: null, business_name: 'NONE' }] : []), ...options].map((option) => (
                <MenuItem key={option.client_id} value={option.client_id}>
                    {customMenuRender ? customMenuRender(option) : option.business_name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const StatusSelect = ({ value, onChange, routed = false, ...rest }) => {
    return (
        <TextField
            select
            variant="outlined"
            label="Order Status"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            fullWidth
            {...rest}
        >
            {Object.values(routed ? ROUTED_ORDER_STATUS_OPTIONS : ORDER_STATUS_OPTIONS).map((status) => (
                <MenuItem key={status.value} value={status.value}>
                    {status.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const PriceInput = ({ value, onChange, label = 'Rate', error, ...rest }) => {
    return (
        <CurrencyTextField
            label={label}
            variant="outlined"
            value={value}
            currencySymbole="$"
            outputFormat="number"
            onChange={(e, val) => onChange(val)}
            fullWidth
            error={error}
            {...rest}
        />
    );
};

export const DateInput = ({
    value,
    onChange,
    label,
    tz = 'America/NewYork',
    firstAvailable,
    disabled,
    error,
    ...rest
}) => {
    return (
        <TextField
            fullWidth
            type="date"
            variant="outlined"
            label={label}
            InputLabelProps={{ shrink: true }}
            value={dateFns.formatDate(asDateInTZ(value, tz), 'yyyy-MM-dd')}
            onChange={(e) => {
                let dateStr = null;
                if (e.target.value) {
                    dateStr = asDateInTZ(e.target.value, tz).toISOString();
                }
                onChange(dateStr);
            }}
            min={firstAvailable ? dateFns.formatDate(asDateInTZ(firstAvailable, tz), 'yyyy-MM-dd') : undefined}
            disabled={disabled}
            error={error}
            {...rest}
        />
    );
};

export const TimeSelect = ({
    interval = 30,
    startHour = 0,
    endHour = 23,
    value,
    date,
    onChange,
    tz = 'America/New_York',
    label = 'Select Time',
    error,
    disabled,
    ...rest
}) => {
    const menuItems = useMemo(() => {
        if (!date) return [];

        const utcDate = new Date(convertToISO(date));
        const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const browserLocalDate = new Date(utcDate.getTime() - getTimezoneOffset(localTZ, utcDate));

        const intervalList = [];
        for (let i = 0; i < 60; i += interval) {
            intervalList.push(i);
        }

        const items = [];
        for (let hours = startHour; hours <= endHour; hours++) {
            intervalList.forEach((minutes) => {
                browserLocalDate.setHours(hours);
                browserLocalDate.setMinutes(minutes);
                browserLocalDate.setSeconds(0);
                browserLocalDate.setMilliseconds(0);

                if (tz && localTZ !== tz) {
                    const customerLocalDate = new Date(
                        browserLocalDate.getTime() +
                            getTimezoneOffset(localTZ, utcDate) -
                            getTimezoneOffset(tz, utcDate)
                    );

                    items.push({
                        value: customerLocalDate.toISOString(),
                        label: `${localFormatter.format(customerLocalDate)} (${routeTimeZoneFormatter(tz).format(
                            customerLocalDate
                        )})`,
                    });
                } else {
                    items.push({
                        value: browserLocalDate.toISOString(),
                        label: `${localFormatter.format(browserLocalDate)}`,
                    });
                }
            });
        }

        return items;
    }, [tz, date]);

    const menuItemsWithValue = useMemo(() => {
        if (value && !menuItems.find((item) => item.value === value)) {
            const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const valueDate = new Date(value);

            return [
                ...menuItems,
                {
                    value: valueDate.toISOString(),
                    label:
                        tz && tz !== localTZ
                            ? `${localFormatter.format(valueDate)} (${routeTimeZoneFormatter(tz).format(valueDate)})`
                            : `${localFormatter.format(valueDate)}`,
                },
            ].sort((a, b) => new Date(a.value) - new Date(b.value));
        }

        return menuItems;
    }, [menuItems, value]);

    return (
        <TextField
            select
            variant="outlined"
            label={label}
            value={value ? new Date(value).toISOString() : ''}
            onChange={(e) => onChange(e.target.value || null)}
            fullWidth
            disabled={disabled}
            error={error}
            {...rest}
        >
            <MenuItem key={`${label}-null`} value={''}>
                NONE
            </MenuItem>
            {(menuItemsWithValue || []).map((menuItem) => (
                <MenuItem key={`${label}-${menuItem.value}`} value={menuItem.value}>
                    {menuItem.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const WarehouseEntities = ({ value, onChange, error, ...rest }) => (
    <TextField
        select
        variant="outlined"
        value={value || ''}
        onChange={onChange}
        error={error}
        InputLabelProps={{ shrink: !!value }}
        fullWidth
        {...rest}
    >
        {[
            { label: 'Carrier', value: 'CARRIER' },
            { label: 'Shipper', value: 'SHIPPER' },
        ].map((type) => (
            <MenuItem key={type.value} value={type.value}>
                {type.label}
            </MenuItem>
        ))}
    </TextField>
);

export const CustomMuiDatePicker = ({
    selectedDate,
    daysToHighlight,
    onChange,
    disabled,
    label,
    timeZone = 'America/New_York',
    minDate,
}) => {
    const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzDiff = getTimezoneOffset(timeZone) - getTimezoneOffset(localTZ);

    const convertedSelectedDate = useMemo(() => {
        if (!selectedDate) return null;
        return asDateInTZ(selectedDate, timeZone);
    }, [selectedDate]);

    const convertedDaysToHighlight = useMemo(() => {
        if (!daysToHighlight) return [];
        return daysToHighlight.map((date) => asDateInTZ(date, timeZone).toISOString());
    }, [daysToHighlight]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                sx={{ width: '100%' }}
                orientation="portrait"
                disablePast={true}
                label={label}
                minDate={minDate}
                disabled={disabled}
                value={convertedSelectedDate}
                onChange={(date) => {
                    let convertedToOrderTZ = new Date(date.getTime() - tzDiff).toISOString();
                    onChange(convertedToOrderTZ);
                }}
                closeOnSelect={true}
                disableHighlightToday={true}
                slotProps={{
                    desktopPaper: {
                        sx: {
                            '& .MuiPickersDay-root': { '&.Mui-selected': { backgroundColor: 'red' } },
                            '& .MuiDateCalendar-root': { backgroundColor: '#F0F0F0' },
                            '& .MuiDialogActions-root': { backgroundColor: '#F0F0F0' },
                        },
                    },
                }}
                slots={{
                    day: (props) => {
                        const isHighlighted = convertedDaysToHighlight.includes(props.day.toISOString());
                        const matchedStyles = convertedDaysToHighlight.reduce((a, v) => {
                            const date = new Date(props.day);
                            return isSameDay(date, parseISO(v)) ? { backgroundColor: '#71B77A' } : a;
                        }, {});

                        return (
                            <PickersDay
                                style={{
                                    color: isHighlighted && 'white',
                                    borderRadius: '50%',
                                }}
                                sx={{
                                    ...matchedStyles,
                                    [`&&.${pickersDayClasses.selected}`]: {
                                        backgroundColor: 'green',
                                    },
                                }}
                                {...props}
                            />
                        );
                    },
                }}
            />
        </LocalizationProvider>
    );
};

export const InfoInput = ({ value, label, ...rest }) => {
    return <TextField variant="outlined" value={value} label={label} fullWidth disabled={true} {...rest} />;
};

export const ClientMultiselect = ({ clients, value, onChange, lockedClients = [] }) => {
    return (
        <Autocomplete
            multiple
            fullWidth
            limitTags={2}
            id="multiple-limit-tags"
            options={clients}
            getOptionLabel={(option) => option.business_name}
            getOptionDisabled={(option) => lockedClients.some((i) => i.client_id === option.client_id)}
            value={[...lockedClients, ...value]}
            onChange={(e, newValue) => {
                onChange([
                    ...newValue.filter((option) => !lockedClients.some((i) => i.client_id === option.client_id)),
                ]);
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option.business_name}
                        {...getTagProps({ index })}
                        disabled={lockedClients.some((i) => i.client_id === option.client_id)}
                    />
                ))
            }
            renderInput={(params) => {
                return (
                    <MuiTextField
                        {...params}
                        variant="outlined"
                        label="Notify Carriers about Order"
                        placeholder="Type to search for a Carrier..."
                        fullWidth
                    />
                );
            }}
        />
    );
};
