import React, { useMemo, useState, useCallback } from 'react';
import { Grid, TextField as MuiTextField } from '@material-ui/core';
import { css } from '@emotion/react';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { enrich } from '@/components/ShipmentForm/utilities/processOrders';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { validatePhoneNumber } from '@/utilities/validatePhoneNumber';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { colors } from '@/styles';
import { TextField, Header1, local } from '../blocks';
import Footer from './Footer';

const REQUIRED_FIELDS = [
    'business_name',
    'business_ein',
    'username',
    'business_phone',
    'business_address',
    'business_city',
    'business_state',
    'business_zip',
    'billing_address',
    'billing_city',
    'billing_state',
    'billing_zip',
];

function CarrierContact({ state, callbacks }) {
    const [sectionBase, setSection] = useState(state.sections[state.progression.current] || {});
    const [isDirty, setDirty] = useState({});
    const [reuseWarehouseAddress, setReuseWarehouseAddress] = useState(false);

    const section = useMemo(() => {
        const billing = Object.fromEntries(
            Object.entries({
                billing_address: 'business_address',
                billing_address_unit: 'business_address_unit',
                billing_state: 'business_state',
                billing_city: 'business_city',
                billing_zip: 'business_zip',
            }).map(([key, value]) => {
                return [key, sectionBase[value]];
            })
        );

        return {
            ...sectionBase,
            ...(reuseWarehouseAddress
                ? {
                      ...billing,
                  }
                : {}),
        };
    }, [reuseWarehouseAddress, sectionBase]);

    const isEmpty = useCallback(
        (field) => {
            const invalid = REQUIRED_FIELDS.includes(field) && (!section[field] || section[field].length === 0);
            switch (field) {
                case 'business_phone':
                    return invalid || !validatePhoneNumber(section[field]);
                case 'personal_phone':
                    return invalid || (section[field] && !validatePhoneNumber(section[field]));
                default:
                    return invalid;
            }
        },
        [section]
    );

    const hasError = useMemo(() => {
        return REQUIRED_FIELDS.some((field) => isEmpty(field));
    }, [isEmpty]);

    const genAddressForm = ({ address, unit, state, city, zip, isDisabled = false }) => {
        return (
            <>
                <Grid
                    container
                    css={css`
                        margin-bottom: 16px;
                    `}
                >
                    <AddressAutocomplete
                        disabled={isDisabled}
                        state={{
                            street: section[address],
                            city: section[city],
                            state: section[state],
                            zip: section[zip],
                        }}
                        onBlur={(e) => {
                            setDirty((prev) => ({
                                ...prev,
                                ...Object.fromEntries([address, state, city, zip].map((field) => [field, true])),
                            }));
                        }}
                        handleAddressUpdate={async (value) => {
                            const [{ geocodePartialMatch, geocodeFailed, ...result }] = await enrich(
                                { pickup_address: value },
                                {},
                                { pickup: true }
                            );
                            if (geocodePartialMatch || geocodeFailed) {
                                setSection((prev) => ({
                                    ...prev,
                                    [address]: value,
                                    [city]: null,
                                    [state]: null,
                                    [zip]: null,
                                }));
                            } else {
                                setSection((prev) => ({
                                    ...prev,
                                    [address]: result.pickup_street_address,
                                    [city]: result.pickup_city,
                                    [state]: result.pickup_state,
                                    [zip]: result.pickup_zip,
                                }));
                            }
                        }}
                        error={[address, state, city, zip].some((attr) => isEmpty(attr) && isDirty[attr])}
                    />
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-bottom: 16px;
                    `}
                >
                    <TextField
                        disabled={isDisabled}
                        display="Address line 2"
                        value={unit}
                        state={section}
                        isDirty={isDirty}
                        callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                    />
                </Grid>
                <Grid
                    container
                    css={css`
                        margin-bottom: 16px;
                    `}
                >
                    {[
                        { display: 'City', value: city },
                        { display: 'State', value: state },
                        { display: 'Zip', value: zip },
                    ].map(({ display, value }, idx, arr) => (
                        <Grid
                            key={value}
                            container
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                margin-right: ${idx === arr.length - 1 ? '0' : '12px'};
                            `}
                        >
                            <TextField
                                disabled={true}
                                display={display}
                                value={value}
                                state={section}
                                isDirty={isDirty}
                                callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    };

    return (
        <>
            <Grid
                container
                css={css`
                    margin-bottom: 16px;
                    color: ${local.black};
                `}
            >
                <Header1>Carrier Info</Header1>
            </Grid>
            {[
                { display: 'Carrier Legal Name', value: 'business_name' },
                { display: 'EIN', value: 'business_ein' },
            ].map(({ display, value }, idx, arr) => (
                <Grid
                    key={value}
                    container
                    css={css`
                        margin-bottom: ${idx === arr.length - 1 ? '32px' : '16px'};
                    `}
                >
                    <TextField
                        display={display}
                        value={value}
                        state={section}
                        isDirty={isDirty}
                        callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                    />
                </Grid>
            ))}

            <Grid
                container
                css={css`
                    margin-bottom: 16px;
                    color: ${local.black};
                `}
            >
                <Header1>Primary Contact</Header1>
            </Grid>
            {[
                { display: 'Full Name', value: 'username' },
                { display: 'Job Title (optional)', value: 'job_title' },
                { display: 'Personal Email (optional)', value: 'personal_email' },
                { display: 'Personal Phone (optional)', value: 'personal_phone' },
                { display: 'Business Phone', value: 'business_phone' },
            ].map(({ display, value }, idx, arr) => {
                return !['business_phone', 'personal_phone'].includes(value) ? (
                    <Grid
                        key={value}
                        container
                        css={css`
                            margin-bottom: ${idx === arr.length - 1 ? '32px' : '16px'};
                        `}
                    >
                        <TextField
                            display={display}
                            value={value}
                            state={section}
                            isDirty={isDirty}
                            callbacks={{ setPayload: setSection, setDirty, isEmpty }}
                        />
                    </Grid>
                ) : (
                    <Grid
                        key={value}
                        container
                        css={css`
                            margin-bottom: ${idx === arr.length - 1 ? '32px' : '16px'};
                        `}
                    >
                        <MuiTextField
                            fullWidth
                            variant="outlined"
                            label={display}
                            InputProps={{
                                inputComponent: PhoneNumberInput,
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={toNational(section[value])}
                            onBlur={(e) => {
                                setDirty((prev) => ({ ...prev, [value]: true }));
                            }}
                            onChange={(e) => {
                                setSection((prev) => ({ ...prev, [value]: toE164(e.target.value, null) }));
                            }}
                            error={isEmpty(value) && isDirty[value]}
                        />
                    </Grid>
                );
            })}

            <Grid
                container
                css={css`
                    margin-bottom: 16px;
                    color: ${local.black};
                `}
            >
                <Header1>Warehouse Address</Header1>
            </Grid>
            {genAddressForm({
                address: 'business_address',
                unit: 'business_address_unit',
                state: 'business_state',
                city: 'business_city',
                zip: 'business_zip',
            })}

            <Grid
                container
                css={css`
                    margin-bottom: 8px;
                    color: ${local.black};
                `}
            >
                <Header1>Billing Address</Header1>
            </Grid>
            <Grid
                container
                css={css`
                    margin-bottom: 16px;
                `}
            >
                <OnwardCheckbox
                    label="Billing address same as warehouse address"
                    checked={reuseWarehouseAddress}
                    onChange={(e) => {
                        setReuseWarehouseAddress((prev) => !prev);
                    }}
                />
            </Grid>
            {genAddressForm({
                isDisabled: reuseWarehouseAddress,
                address: 'billing_address',
                unit: 'billing_address_unit',
                state: 'billing_state',
                city: 'billing_city',
                zip: 'billing_zip',
            })}

            <Footer
                state={state}
                isFirst={state.progression.current === 0}
                isLast={state.progression.current === state.progression.stages.length - 1}
                hasErrors={hasError}
                callbacks={{
                    advance: () => callbacks.advance(section),
                    revert: callbacks.revert,
                }}
            />
            <Grid
                item
                css={css`
                    margin-top: 16px;
                `}
            >
                <span
                    css={css`
                        color: ${colors.greys.primary};
                        font-size: 14px;
                        font-weight: 500;
                    `}
                >
                    * By providing a telephone number and submitting the form you are consenting to be contacted by SMS
                    text message. Message & data rates may apply. Reply STOP to opt out of further messaging.
                </span>
            </Grid>
        </>
    );
}

export default CarrierContact;
