import React, { useEffect, useCallback, useState, useMemo, useContext } from 'react';
import { isBefore, isAfter, addDays, isEqual } from 'date-fns';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { ADVANCED_SCHEDULING } from '@/constants/featureFlags';
import { genResources, genRecommendations } from '@/components/Resources/utils';
import { STAGES, TYPES } from './constants';

const TODAY = new Date();

export const usePredeliveryState = ({ req }) => {
    const [rawStage, setStage] = useState(null);
    const [stairsOverride, setStairs] = useState(null);
    const [specialInstructionsOverride, setSpecialInstructions] = useState(null);
    const [gateCodeOverride, setGateCode] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [availabilityDays, setAvailabilityDays] = useState([]);
    const [confirmed, setConfirm] = useState(false);
    const [survey, setSurvey] = useState({
        truck_fit: true,
    });

    const order = useMemo(() => {
        if (req?.order) {
            return req.order;
        }
        return {};
    }, [req]);

    const stairs = useMemo(() => {
        return stairsOverride || order?.delivery_stair_quantity || 0;
    }, [order, stairsOverride]);

    const specialInstructions = useMemo(() => {
        return specialInstructionsOverride || order?.customer_instructions || '';
    }, [order, specialInstructionsOverride]);

    const gateCode = useMemo(() => {
        return gateCodeOverride || order?.gate_code || '';
    }, [order, gateCodeOverride]);

    const { zip } = useSwappedAttributes(order);

    const tz = useMemo(() => {
        return zipcode_to_timezone.lookup(zip);
    }, [zip]);

    const interval = useMemo(() => {
        const adjustTZ = (ts) => {
            const utc = new Date(asUTCDate(ts).setUTCHours(0, 0, 0, 0)).toISOString();
            return asDateInTZ(utc, tz).toISOString();
        };

        const rv = [];
        const todayAdjusted = new Date(adjustTZ(TODAY.toISOString()));
        const start = addDays(todayAdjusted, 3);
        const end = addDays(start, 30);

        let curr = start;
        while (isEqual(curr, end) || isBefore(curr, end)) {
            const next = addDays(curr, 1);
            const dow = curr.getUTCDay();
            rv.push({
                thisYear: todayAdjusted.getFullYear() === curr.getFullYear(),
                isCurrentMonth: todayAdjusted.getMonth() === curr.getMonth(),
                thisMonth: todayAdjusted.getMonth() === curr.getMonth(),
                isToday: todayAdjusted.getDate() === curr.getDate(),
                date: curr.getDate(),
                start: curr.toISOString(),
                end: next.toISOString(),
                dow,
            });

            curr = next;
        }

        return rv;
    }, [tz]);

    const surveyType = useMemo(() => {
        if (order.oms) {
            if (
                order?.order_carrier?.user?.circles[ADVANCED_SCHEDULING] ||
                order?.order_shipper?.user?.circles[ADVANCED_SCHEDULING]
            ) {
                return TYPES.SCHEDULE;
            }

            return TYPES.AVAILABILITY;
        }

        if (order?.order_carrier?.user?.circles[ADVANCED_SCHEDULING]) {
            return TYPES.SCHEDULE;
        }

        return TYPES.AVAILABILITY;
    }, [order]);

    const stage = useMemo(() => {
        if (rawStage) {
            return rawStage;
        }

        if (order?.dropoff_address_confirmed) {
            if (order.delivery_date) {
                return STAGES.SCHEDULE_SUCCESS;
            }

            if (order.alternative_delivery_dates?.length > 0) {
                return STAGES.AVAILABILITY_SUCCESS;
            }

            return STAGES.CONTACT;
        }

        return null;
    }, [order, rawStage, surveyType]);

    const recommendations = useMemo(() => {
        if (surveyType !== TYPES.SCHEDULE) {
            return [];
        }

        const { default: defaultResources, override: overrideResources } = genResources({
            client: order.order_carrier || order.order_shipper || {},
            order,
        });
        const resources = overrideResources.length > 0 ? overrideResources : defaultResources;

        return genRecommendations({ resources, range: interval });
    }, [surveyType, order, interval]);

    const dayIsDisabled = (day) => {
        let startDate = ['first_available_date', 'estimated_delivery_range_start'].reduce((acc, attr) => {
            if (order?.[attr]) {
                const attrDate = new Date(order[attr]);
                if (attrDate > acc) return attrDate;
            }
            return acc;
        }, new Date());
        const receiveEvent = order?.wh_events?.find(
            (event) => event.action === 'START:RECEIVING' && event?.est_received_date
        );
        if (receiveEvent?.est_received_date) {
            const warehouseCutoff = addDays(new Date(receiveEvent.est_received_date), 1);
            if (warehouseCutoff > startDate) startDate = warehouseCutoff;
        }

        return isBefore(day, startDate) || isAfter(day, addDays(startDate, 21));
    };

    const dowClosed = useMemo(() => {
        let open;
        if (order.oms) {
            open = (order.order_shipper?.default_store_hours || []).map((d) => d.day_num);
        } else {
            open = (order.order_carrier?.default_store_hours || []).map((d) => d.day_num);
        }

        const closed = Array(7)
            .fill()
            .map((_, idx) => idx)
            .filter((x) => !open.includes(x));

        return open.length === 0 ? [0] : closed;
    }, [order]);

    const toDropoffTz = useCallback(
        (d) => {
            const clone = new Date(d);
            clone.setUTCHours(0, 0, 0, 0);
            const fixed = asDateInTZ(clone.toISOString(), tz);
            return fixed.toISOString();
        },
        [tz]
    );

    const contactPhone = useMemo(() => {
        if (!order) return;
        if (order?.order_carrier) {
            return order?.order_carrier?.user?.circles?.['hide-tracking-link-phone']
                ? ''
                : order?.order_carrier?.support_phone || order?.order_carrier?.business_phone;
        } else {
            return order?.order_shipper?.user?.circles?.['hide-tracking-link-phone']
                ? ''
                : order?.order_shipper?.support_phone || order?.order_shipper?.business_phone;
        }
    }, [order]);

    return [
        {
            order,
            stairs,
            specialInstructions,
            gateCode,
            surveyType,
            recommendations,
            survey,
            stage,
            confirmed,
            deliveryDate,
            contactPhone,
            availabilityDays,
            disabledDays: [dayIsDisabled, { daysOfWeek: dowClosed }],
        },
        {
            setStage,
            setStairs,
            setSpecialInstructions,
            setGateCode,
            setDeliveryDate,
            setAvailabilityDays,
            toDropoffTz,
            setConfirm,
            setSurvey,
            reset: () => {
                setStairs(0);
                setSpecialInstructions('');
                setGateCode('');
                setDeliveryDate(null);
                setAvailabilityDays(null);
                setConfirm(false);
            },
        },
    ];
};
