import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { addDays } from 'date-fns';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { validate, useWarehouseAggregate } from '@onward-delivery/core';
import styled from '@emotion/styled';
import {
    ThemeProvider,
    createTheme,
    Grid,
    Box,
    Typography,
    Button,
    MenuItem,
    Menu,
    CircularProgress,
    Snackbar,
    IconButton,
} from '@material-ui/core';
import { PrimaryButton } from '@/styles/blocks';
import { ArrowDropDown } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { createOrderJob } from '@/components/ShipmentForm/queries/createOrderJob';
import { Lightbox } from 'react-modal-image';
import { UserContext } from '../App';
import * as Sentry from '@sentry/react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { UPSERT_ORDERS, UPSERT_ORDER_DYNAMIC } from '@/components/ShipmentForm/graphql/mutations';
import { QUERY_BY_LEADER } from '@/graphql/queries/teammates';
import orderStatusStrings from '@/constants/orderStatusStrings';
import EditOrderModal from '@/components/ShipmentForm/modals/EditOrderModal';
import MarketplaceModal from '@/components/ShipmentForm/modals/MarketplaceModal';
import CreditCardModal from '@/components/ShipmentForm/modals/CreditCardModal';
import ExceptionResolutionModal from '@/components/admin/AdminExceptions/modals/ExceptionResolutionModal';
import { RESOLVE_EXCEPTION } from '@/components/admin/AdminExceptions/graphql';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { ITEM_READONLY_FIELDS, ORDER_READONLY_FIELDS } from '@/constants/readonlyFields';
import { EditOutlined as EditOutlinedIcon } from '@material-ui/icons';
import { EDIT_ORDER_TABS } from '@/components/ShipmentForm/constants';
import _, { rest, omit } from 'lodash';
import OrderSummaryCard from './OrderSummaryCard';
import ExceptionCard from './ExceptionCard';
import RoutedOrderCard from './RoutedOrderCard';
import PickUpCard from './PickUpCard';
import DropOffCard from './DropOffCard';
import OrderProgress from './OrderProgress';
import { UPSERT_EVENT, INSERT_EXCEPTION, INSERT_EXCEPTION_EVENT, UPDATE_EXCEPTION } from './graphql/mutations';
import BrokerCard from './BrokerCard';
import { MODALS } from './constants';
import { OnwardTabContainer, OnwardTab } from '../Tabs';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '../Breadcrumbs';
import StoreIcon from '@material-ui/icons/Store';

import { useExceptionCallbacks } from './hooks';
import OrderItems from './OrderItems/OrderItems';
import OrderImages from './OrderImages';
import CarrierReviewModal from '../CarrierReviewModal';
import CarrierEditBudget from './CarrierEditBudget';
import ExceptionModal from './modals/Exceptions';
import AssignDriverModal from './modals/AssignDriverModal';
import ConfirmationModal from './modals/ConfirmationModal';
import ChecklistModal from './modals/ChecklistModal';
import CrossdockPickupConfigModal from './modals/CrossdockPickupConfigModal';
import CrossdockConfigModal from './modals/CrossdockConfigModal';
import CommunicationLog from '../CommunicationLog';
import { OnwardCard } from './Card.jsx';
import { useClientUser } from '../../hooks';
import WarehousingCard from './WarehousingCard';
import Notes from './Notes';
import ListingCarrierInfo from './ListingCarrierInfo';
import CrossdockingCard from './Crossdocking';
import CarrierInfoCard from './CarrierInfoCard';
import EmptyCard from './EmptyCard';

import { ORDER_BY_ID } from './queries';
import { UPDATE_ORDER_STATUS } from './mutations';
import { useQuery, useMutation, useApolloClient, useLazyQuery } from '@apollo/client';
import OnwardPricePopper from '../OnwardPricingDisplay/OnwardPricePopper';
import AssignedToRouteErrorModal from './modals/AssignedToRouteErrorModal';
import BrokerDisclaimerModal from './modals/BrokerDisclaimerModal';
import { addFormattedDateFields } from '@/utilities/addFormattedDateFields';
import BackToUnassignedModal from './modals/BackToUnassignedModal';
import { post } from '@/utilities/onwardClient';
import { CANCEL_LISTING, FETCH_ROCKET_STATUS_UPDATES, MANUAL_ROCKET_PUSH } from '@/constants/apiRoutes';
import { QUERY_BY_ORDER } from '@/graphql/queries/order_wh_events';
import AuctionModal from '../ShipmentForm/modals/ListingModal';
import useAction from '@/utilities/useQuery';
import { createListings } from '../ShipmentForm/queries/createListings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CreateIcon from '@mui/icons-material/Create';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelIcon from '@mui/icons-material/Cancel';
import { Store } from '@material-ui/icons';
import { inviteTempDriver } from './queries/inviteTempDriver';
import PalletDimensionsModal from './modals/PalletDimensionsModal';
import { toNational } from '@/utilities/formatPhoneNumber';
import CustomerAddressConfirmationCard from './CustomerAddressConfirmationCard';
import { CROSSDOCK_CONFIRM_TIMEFRAME_TEXT } from '@/constants/apiRoutes';
import { captureException } from '@sentry/react';
import { ROCKET_MM_INTEGRATION } from '@/constants/featureFlags';
import MiddleMileCard from './MiddleMileCard';
import ReceiveModal from './modals/ReceiveModal';
import ScheduleModal from './modals/ScheduleModal';
import TimeframeModal from './modals/TimeframeModal';
import AssignModal from './modals/AssignModal';
import IntegrationPartnerCard from './IntegrationPartnerCard';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocumentBOL from './DocumentBOL';
import UpdateItemPieceCountModal from './modals/UpdateItemPieceCountModal';
import WillCallCard from './WillCallCard';
import WHCrossdockCard from './WHCrossdockCard';
import { PARTNERSHIP_BY_CLIENTS } from '@/graphql/queries/partnerships';

const useStyles = makeStyles({
    root: {
        minWidth: '15px',
    },
});

const SecondaryNavContainer = styled((props) => <Grid {...props} />)`
    padding: 0 40px;
    background: #fff;
    justify-content: space-between;
`;

const renderNotesCardIfExists = (notesTitle, notesBody) =>
    notesBody ? (
        <Grid container className="mt-3">
            <OnwardCard>
                <Typography variant="h6" color="secondary">
                    {notesTitle}
                </Typography>
                <Typography className="my-1" variant="body1">
                    {notesBody}
                </Typography>
            </OnwardCard>
        </Grid>
    ) : null;

function OrderDetailsPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { cache } = useApolloClient();
    const { sidebarCollapsed, superUser } = useContext(UserContext);

    const {
        user_id,
        payment_type,
        stripe_payment_method,
        userType,
        accountType,
        teammateRoles,
        circles,
        locations,
        isOnwardAdmin,
    } = useClientUser();
    const source = location?.state?.source || '';
    const routeName = location?.state?.routeAlias || location?.state?.routeNumber || '';

    const { order_id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const modalParam = searchParams.get('modal');
    const [tabIndex, setTabIndex] = useState(0);
    const [viewImg, setViewImg] = useState('');
    const [carrierReviewOpen, setCarrierReviewOpen] = useState(false);
    const [carrierReview, setCarrierReview] = useState(null);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [crossdockPickupParams, setCrossdockPickupParams] = useState(null);
    const [crossdockConfigParams, setCrossdockConfigParams] = useState(null);
    const [receiveParams, setReceiveParams] = useState(null);
    const [scheduleParams, setScheduleParams] = useState(null);
    const [timeframeParams, setTimeframeParams] = useState(null);
    const [assignParams, setAssignParams] = useState(null);
    const [palletDimensionsParams, setPalletDimensionsParams] = useState(null);
    const [exceptionParams, setExceptionParams] = useState(null);
    const [resolveExceptionParams, setResolveExceptionParams] = useState({});
    const [confirmation, setConfirmation] = useState({});
    const [checklist, setChecklist] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [assignedToRouteErrorModal, setAssignedToRouteErrorModal] = useState(false);
    const [notification, setNotification] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [backToUnassignedModalOpen, setBackToUnassignedModalOpen] = useState(false);
    const [openListingModal, setOpenListingModal] = useState(false);
    const [pendingMarketPlace, setPendingMarketplace] = useState({
        flow: [],
        step: 0,
        order: null,
    });
    const [brokerEditingOrder, setBrokerEditingOrder] = useState(null);
    const [editingItemPieceCount, setEditingItemPieceCount] = useState(modalParam === 'piece_count_verification');
    const [logo, setLogo] = useState(null);
    const [logoBackground, setLogoBackground] = useState(null);

    const {
        loading: orderLoading,
        error: orderError,
        data: orderData,
        refetch: refetchOrder,
    } = useQuery(ORDER_BY_ID, {
        variables: {
            order_id: order_id,
        },
        onCompleted: (data) => {
            const _order = data?.orders?.[0];
            if (!_order) return;

            if (_order?.ratings?.length) {
                const relevantRating = _order.ratings
                    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    ?.find((r) => r.reviewer_id === user_id);
                setCarrierReview(relevantRating);
            }
        },
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const order = useMemo(() => {
        return orderData?.orders?.[0] || {};
    }, [orderData]);

    const route = useMemo(() => {
        return orderData?.orders?.[0]?.route || {};
    }, [orderData]);

    const isFtlCrossdocked = useMemo(() => {
        return order.wh_events?.some((event) => event.action.includes('ADD_CD'));
    }, [order]);

    const [upsertOrder, { loading: upsertLoading }] = useMutation(UPSERT_ORDERS, {
        update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
        },
        onError: (e) => {
            console.error(e);
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to save order. Please try again.' });
        },
    });

    const [upsertOrderDynamic, { loading: upsertDynamicLoading }] = useMutation(UPSERT_ORDER_DYNAMIC, {
        update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
        },
        onError: (e) => {
            console.error(e);
            Sentry.captureException(e);
            setNotification({ severity: 'error', message: 'Failed to save order. Please try again.' });
        },
    });

    const [openEditOrderModal, setOpenEditOrderModal] = useState(false);

    const [handleSendBackToUnassigned] = useAction(async (body) => post(CANCEL_LISTING, body), {
        onComplete: ({ data }) => {
            if (data?.success) {
                setNotification({
                    severity: 'success',
                    message: 'Order sent back to unassigned.',
                });
            } else {
                setNotification({
                    severity: 'error',
                    message: data?.error || 'Error cancelling order.',
                });
            }
            setBackToUnassignedModalOpen(false);
        },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error cancelling order. Please try again.',
            });
            setBackToUnassignedModalOpen(false);
        },
    });

    const [fetchRocketStatusUpdates, { loading: rocketUpdatesLoading }] = useAction(
        async (body) => post(FETCH_ROCKET_STATUS_UPDATES, body),
        {
            onComplete: (response) => {
                if (response.status !== 200) {
                    setNotification({
                        severity: 'error',
                        message: 'Error fetching status updates from Rocket.',
                    });
                } else {
                    setNotification({
                        severity: 'success',
                        message: response?.data?.message,
                    });
                }
            },
            onError: (err) => {
                console.error(err);
                Sentry.captureException(err);
                setNotification({
                    severity: 'error',
                    message: 'Error fetching status updates from Rocket.',
                });
            },
        }
    );

    const [manualRocketPush, { loading: manualRocketPushLoading }] = useAction(
        async (body) => post(MANUAL_ROCKET_PUSH, body),
        {
            onComplete: (response) => {
                if (response.status !== 200) {
                    setNotification({
                        severity: 'error',
                        message: 'Error pushing order integration to Rocket.',
                    });
                } else {
                    setNotification({
                        severity: 'success',
                        message: 'Successfully pushed order to Rocket.',
                    });
                }
            },
            onError: (err) => {
                console.error(err);
                Sentry.captureException(err);
                setNotification({
                    severity: 'error',
                    message: 'Error pushing order integration to Rocket.',
                });
            },
        }
    );

    const handleManualRocketPush = () => {
        if (!order?.order_carrier || !order?.has_middle_mile_origin) {
            setNotification({
                severity: 'error',
                message: 'Missing middle mile fields required to send Rocket integration data.',
            });
            return;
        }
        manualRocketPush({
            order: order,
            type: 'ROCKET',
            shipper: order?.order_shipper,
        });
    };

    const [submitOrders, { loading: submitInflight }] = useAction(createOrderJob, {
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    const [resolve, { loading: resolveInflight }] = useMutation(RESOLVE_EXCEPTION, {
        update(cache, { data: { order, exception, items_removed } }) {
            cache.updateQuery(
                {
                    query: ORDER_BY_ID,
                    variables: {
                        order_id: order_id,
                    },
                },
                (data) => {
                    if (!order) {
                        return data;
                    }
                    const [updated] = order.returning;
                    const clone = {
                        ...data.orders[0],
                        ...updated,
                    };

                    return {
                        ...data,
                        orders: [clone],
                    };
                }
            );
        },
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    const [insertException, { loading: insertExceptionLoading }] = useMutation(INSERT_EXCEPTION, {
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to add exception. Please try again' });
        },
    });
    const [updateException, { loading: updateExceptionLoading }] = useMutation(UPDATE_EXCEPTION, {
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to update exception. Please try again' });
        },
    });

    const [insertExceptionEvent, { loading: insertExceptionEventLoading }] = useMutation(INSERT_EXCEPTION_EVENT, {
        update: (cache, { data: { mappings, order } }) => {
            cache.updateQuery(
                {
                    query: ORDER_BY_ID,
                    variables: {
                        order_id: order_id,
                    },
                },
                (data) => {
                    if (!order) {
                        return data;
                    }

                    const clone = {
                        ...data.orders[0],
                        ...order,
                    };

                    return {
                        ...data,
                        orders: [clone],
                    };
                }
            );

            cache.updateQuery(
                {
                    query: QUERY_BY_ORDER,
                    variables: { order_id },
                },
                (data) => {
                    if (!order) {
                        return data;
                    }
                    const clone = [...order.wh_events];

                    return {
                        ...data,
                        results: clone,
                    };
                }
            );
        },
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to insert event. Please try again' });
        },
    });

    const [upsertEvent, { loading: eventUpsertLoading }] = useMutation(UPSERT_EVENT, {
        update: (cache, { data: { event, order } }) => {
            cache.updateQuery(
                {
                    query: ORDER_BY_ID,
                    variables: {
                        order_id: order_id,
                    },
                },
                (data) => {
                    if (!event) {
                        return data;
                    }
                    const clone = {
                        ...data.orders[0],
                        ...order,
                    };

                    return {
                        ...data,
                        orders: [clone],
                    };
                }
            );

            cache.updateQuery(
                {
                    query: QUERY_BY_ORDER,
                    variables: { order_id },
                },
                (data) => {
                    if (!event) {
                        return data;
                    }
                    const idx = data.results.findIndex((e) => e.event_id === event.event_id);
                    const clone = [...data.results];
                    if (idx > -1) {
                        clone.splice(idx, 1, event);

                        return {
                            ...data,
                            results: clone,
                        };
                    }

                    return {
                        ...data,
                        results: [...data.results, event],
                    };
                }
            );
        },
        onError: (error) => {
            Sentry.captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Failed to insert event. Please try again' });
        },
    });

    const [findPartnership] = useLazyQuery(PARTNERSHIP_BY_CLIENTS, {
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
    });

    const [sendWeblink, { loading: isLoadingWeblink }] = useAction(inviteTempDriver, {
        onComplete: () => {
            setNotification({
                severity: 'success',
                message: `Order ${order.order_number} weblink sent to driver`,
            });
        },
        onError: (error) => {
            console.error(error);
            setNotification({
                severity: 'error',
                message: error?.response?.data?.error || 'Error sending weblink',
            });
        },
    });

    const { data: warehouseEvents } = useQuery(QUERY_BY_ORDER, {
        variables: { order_id },
    });

    const warehouse = useWarehouseAggregate(warehouseEvents?.results);

    const { data: teammates } = useQuery(QUERY_BY_LEADER, {
        variables: { leader_id: user_id },
    });

    const drivers = useMemo(() => {
        return (teammates?.results || []).filter((teammate) => teammate?.roles?.driver);
    }, [teammates]);

    const [renewListing] = useAction(createListings, {
        onComplete: ({ data }) => {
            if (data?.success?.length) {
                setNotification({
                    severity: 'success',
                    message: 'Listing Renewed.',
                });
            } else {
                setNotification({
                    severity: 'error',
                    message: data?.error || 'Error renewing listing.',
                });
            }
            resetSendToMarketplace();
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'listings' });
            setOpenListingModal(false);
        },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error renewing listing. Please try again.',
            });
            setOpenListingModal(false);
        },
    });

    const { createException } = useExceptionCallbacks(
        { order, exceptionParams },
        { insertExceptionEvent, insertException, updateException }
    );

    const onSaveOrder = ({ errors, order: modified, items: itemsModified, itemsRemoved }) => {
        if (errors?.hasMissingField?.length) {
            setNotification({
                severity: 'error',
                message: `Order has missing fields: ${errors.hasMissingField.join(', ')}.`,
            });
        } else if (errors?.phoneInvalid) {
            setNotification({ severity: 'error', message: 'Order has an invalid phone number.' });
        } else {
            if (errors?.failedPricing?.length) {
                let message = `Failed to calculate pricing for ${modified.order_number}.`;
                if (errors.failedPricing.includes('miles')) {
                    message = `${message} Cannot find route between pickup and dropoff locations.`;
                }
                if (errors.failedPricing.includes('delivery_type')) {
                    message = `${message} ${
                        modified.order_type === 'return' ? 'Return Pickup' : 'Delivery'
                    } type not set.`;
                }
                setNotification({ severity: 'warning', message });
            }

            const {
                warehouse_delivery_notes,
                warehouse_estimated_delivery_date,
                warehouse_estimated_ship_date,
                ...rest
            } = modified;

            upsertOrder({
                variables: {
                    orders: [
                        {
                            ...rest,
                            ...(warehouse_delivery_notes ||
                            warehouse_estimated_delivery_date ||
                            warehouse_estimated_ship_date
                                ? {
                                      wh_events: {
                                          data: [
                                              {
                                                  action: 'START:RECEIVING',
                                                  notes: warehouse_delivery_notes,
                                                  est_received_date: warehouse_estimated_delivery_date,
                                                  est_ship_date: warehouse_estimated_ship_date,
                                              },
                                          ],
                                      },
                                  }
                                : {}),
                        },
                    ],
                    items: itemsModified || [],
                    removals: itemsRemoved || [],
                },
            });
            setOpenEditOrderModal(false);
        }
    };

    const onBrokerSaveOrder = ({ errors, order, items: modified, itemsRemoved }) => {
        upsertOrder({ variables: { orders: [{ ...order }], items: modified || [], removals: itemsRemoved || [] } });
        setBrokerEditingOrder(null);
    };

    const handleSendingToMarketplace = ({ shipper_id, listings }) => {
        // If a broker decides to send an order to the Onward Marketplace, then we need to reset fields that the broker previously had control over that normal shippers do not.
        const { itemsByOrderId, ...modified } = omit(order, ORDER_READONLY_FIELDS);

        upsertOrder({
            variables: {
                orders: [
                    {
                        ...modified,
                        order_status: 'open',
                        carrier_id: null,
                        pickup_date: null,
                        delivery_date: null,
                        pickup_window_start: null,
                        pickup_window_end: null,
                        del_window_start: null,
                        del_window_end: null,
                        pod: null,
                        proof_of_pickup: null,
                        delivery_photo: null,
                        customer_signature: null,
                    },
                ],
                items: [],
                removals: [],
            },
            onCompleted: (data) => {
                renewListing({
                    shipper_id,
                    listings,
                });
            },
            onError: (error) => {
                console.error(error);
                Sentry.captureException(error);
                setNotification({
                    severity: 'error',
                    message: `We're sorry, something went wrong.`,
                });
            },
        });
    };

    const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS, {
        onError: (error) => {
            Sentry.captureException(error);
        },
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const resetSendToMarketplace = () => {
        setPendingMarketplace({
            flow: [],
            step: 0,
            order: null,
        });
    };

    const progressSendingToMarketplace = ({ order: optionalOrderModifier, listing: optionalListing }) => {
        if (pendingMarketPlace.step === pendingMarketPlace.flow.length - 1) {
            handleSendingToMarketplace({
                shipper_id: user_id,
                listings: [pendingMarketPlace.order.listing],
            });
        } else {
            setPendingMarketplace((prev) => {
                let next = {
                    ...prev,
                    step: prev.step + 1,
                };
                if (optionalListing) {
                    next.order = {
                        ...prev.order,
                        listing: {
                            ...optionalListing,
                        },
                    };
                } else if (optionalOrderModifier) {
                    next.order = {
                        ...optionalOrderModifier,
                    };
                }
                if (next.flow[next.step] === MODALS.EDIT_ORDER) {
                    setNotification({
                        severity: 'warning',
                        message: `Cannot submit to Onward, order is missing certain fields required for Onward Marketplace orders.`,
                    });
                }
                return next;
            });
        }
    };

    useEffect(() => {
        if (order?.order_shipper?.profile_img) {
            setLogo(order?.order_shipper?.profile_img);
            if (order?.order_shipper?.profile_img_color) {
                setLogoBackground(order?.order_shipper?.profile_img_color);
            }
        }

        if (order?.oms && order?.carrier_id) {
            findPartnership({
                variables: {
                    shipperId: order.shipper_id,
                    carrierId: order.carrier_id,
                },
                onCompleted: ({ partnerships }) => {
                    if (partnerships[0]?.profile_img) {
                        setLogo(partnerships[0]?.profile_img);
                        if (partnerships[0]?.profile_img_color) {
                            setLogoBackground(partnerships[0]?.profile_img_color);
                        }
                    }
                },
            });
        }
    }, [order]);

    const [finalMileDriver, crossDockDriver] = useMemo(() => {
        if (order?.routes?.length > 0) {
            return [
                order.routes.find((mapping) => ['FULL', 'DROPOFF'].includes(mapping.type))?.route?.teammateByDriverId,
                order.routes.find((mapping) => mapping.type === 'PICKUP')?.route?.teammateByDriverId,
            ];
        } else {
            return [
                warehouse?.driver ||
                    (warehouse?.driver_name && warehouse?.driver_phone
                        ? { username: warehouse.driver_name, phone: warehouse.driver_phone }
                        : null),
                warehouse?.wh_driver ||
                    (warehouse?.wh_driver_name && warehouse?.wh_driver_phone
                        ? { username: warehouse.wh_driver_name, phone: warehouse.wh_driver_phone }
                        : null),
            ];
        }
    }, [order, warehouse]);

    const editable = useMemo(() => {
        return (
            ((order.oms && order.shipper_id === user_id) || order.carrier_id === user_id) &&
            (userType !== 'teammate' || teammateRoles?.admin) &&
            !['cancelled', 'open'].includes(order?.order_status)
        );
    }, [order, user_id, userType, teammateRoles]);

    const warehouseEditable = useMemo(() => {
        const containsEvent = warehouseEvents?.results.find((e) => e.action === 'START:RECEIVING');
        const omsShipper = editable && accountType !== 'carrier';
        const mktplaceShipper = order.warehouse_control_entity === 'SHIPPER' && order.shipper_id === user_id;

        return containsEvent && (omsShipper || mktplaceShipper);
    }, [warehouseEvents, editable, accountType, order]);

    const handleSubmitStatus = async (status) => {
        const completion_time = status === 'complete' ? new Date().toISOString() : null;
        const isAdmin = !!superUser?.user_id;
        try {
            await updateOrderStatus({
                variables: {
                    order_id: order.order_id,
                    order_status: status,
                    completion_time: completion_time,
                    source: isAdmin ? 'WEB_ONWARD_ADMIN' : user_id === order.shipper_id ? 'WEB_SHIPPER' : 'WEB_CARRIER',
                },
            });
            handleClose();
        } catch (e) {
            Sentry.captureException(e);
            console.log(e);
        }
    };

    const initiateSendToMarketplace = (brokerFlag) => {
        const next = { ...order, oms: false };
        const [, hasMissingField] = validate(next);
        if (next?.routes?.length > 0) {
            setAssignedToRouteErrorModal(true);
            return;
        }

        let flow = [
            ...(brokerFlag ? [MODALS.BROKER_DISCLAIMER] : []),
            ...(hasMissingField && hasMissingField.length > 0 ? [MODALS.EDIT_ORDER] : []),
            MODALS.AUCTION_DETAILS,
            MODALS.MARKETPLACE_TOC,
            ...(payment_type !== 'Invoice' && !stripe_payment_method ? [MODALS.CREDIT_CARD] : []),
        ];

        setPendingMarketplace((prev) => ({
            ...prev,
            flow,
            order: next,
        }));
        return;
    };

    const listing = useMemo(() => {
        if (pendingMarketPlace.order) {
            let orderTZ =
                zipcode_to_timezone.lookup(order?.dropoff_zip) || Intl.DateTimeFormat().resolvedOptions().timeZone;
            const utc = addDays(
                new Date(asUTCDate(new Date().toISOString()).setUTCHours(0, 0, 0, 0)),
                21
            ).toISOString();

            return {
                [pendingMarketPlace.order.order_id]: {
                    order_id: pendingMarketPlace.order.order_id,
                    listing_type: circles?.['bidding-disabled'] ? 'claim_now' : 'all',
                    shipper_rate: pendingMarketPlace.order.shipper_rate,
                    expiration_date: asDateInTZ(utc, orderTZ).toISOString(),
                },
            };
        }

        return {};
    }, [pendingMarketPlace]);

    let orderStatus = useMemo(() => {
        if (!Object.keys(order).length) return '';
        if (
            order.order_status === 'complete' &&
            (order.order_exception || order?.itemsByOrderId?.some((i) => i.item_exception))
        ) {
            return 'Complete with Exceptions';
        }
        return orderStatusStrings[order.order_status];
    }, [order]);
    const hasExceptions = useMemo(() => {
        return order.itemsByOrderId?.some(
            (item) => item.exceptions?.filter((mapping) => mapping.exception.status !== 'RESOLVED').length > 0
        );
    }, [order]);

    const OrderStatusStyles = {
        open: {
            color: '#fc8f00',
        },
        inProgress: {
            color: '#008BD9',
        },
        complete: {
            color: '#59b863',
        },
        pending: {
            color: '#fc8f00',
        },
        claimed: {
            color: '#fc8f00',
        },
        routedActive: {
            color: '#fc8f00',
        },
        routed: {
            color: '#fc8f00',
        },
        routedInProgress: {
            color: '#fc8f00',
        },
        routedComplete: {
            color: '#59b863',
        },
        cancelled: {
            color: '#D23E3E',
        },
        rescheduled: {
            color: colors.oranges.primary,
        },
        'Complete with Exceptions': {
            color: colors.oranges.primary,
        },
    };

    const cardFrag = css`
        height: 100%;
        flex-grow: 1;
        display: flex;
    `;

    const theme = createTheme({
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h1: {
                fontWeight: 800,
                fontSize: '28px !important',
            },
            h3: {
                fontSize: 25,
                fontWeight: '800',
            },
            h4: {
                color: '#4c4c4c',
                fontWeight: '900',
                fontSize: 15,
            },
            h6: {
                color: '#8d8d8d',
                fontWeight: '800',
                fontSize: 18,
            },
            body1: {
                fontWeight: '650',
                fontSize: 16,
            },
            body2: {
                fontWeight: '500',
                fontSize: 16,
            },
            subtitle1: {
                color: '#8d8d8d',
                fontWeight: '500',
                fontSize: '10',
            },
            subtitle2: {
                color: `${
                    OrderStatusStyles[orderStatus] || OrderStatusStyles[order.order_status]
                        ? OrderStatusStyles[orderStatus]?.color || OrderStatusStyles[order.order_status]?.color
                        : 'black'
                }`,
                fontWeight: '700',
                fontSize: '10',
                border: '3px solid',
                borderRadius: '5px',
                paddingLeft: '4px',
                paddingRight: '4px',
                height: '36px',
                display: 'flex',
                alignItems: 'center',
            },
            button: {
                fontWeight: '550',
                textTransform: 'capitalize',
            },
        },
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
    });

    const handleTabChange = (event, newValue) => setTabIndex(newValue);
    const classes = useStyles();

    const backButtonBar = useMemo(() => {
        switch (source) {
            case 'order':
                return (
                    <React.Fragment>
                        <OnwardBreadcrumbInactive onClick={() => navigate(-1)}>Active</OnwardBreadcrumbInactive>
                        <OnwardBreadcrumbSpacer />
                        <OnwardBreadcrumbActive variant="body1" color="primary" className="ml-2">
                            Order {order.order_number}
                        </OnwardBreadcrumbActive>
                    </React.Fragment>
                );
            case 'route':
                return (
                    <React.Fragment>
                        <OnwardBreadcrumbInactive onClick={() => navigate(-2)}>Active</OnwardBreadcrumbInactive>
                        <OnwardBreadcrumbSpacer />
                        <OnwardBreadcrumbInactive onClick={() => navigate(-1)}>
                            Route {routeName}
                        </OnwardBreadcrumbInactive>
                        <OnwardBreadcrumbSpacer />
                        <OnwardBreadcrumbActive>Order {order.order_number}</OnwardBreadcrumbActive>
                    </React.Fragment>
                );
            case 'financials':
                return (
                    <React.Fragment>
                        <OnwardBreadcrumbInactive onClick={() => navigate(-1)}>Financials</OnwardBreadcrumbInactive>
                        <OnwardBreadcrumbSpacer />
                        <OnwardBreadcrumbActive variant="body1" color="primary" className="ml-2">
                            Order {order.order_number}
                        </OnwardBreadcrumbActive>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <OnwardBreadcrumbInactive onClick={() => navigate(-1)}>My Orders</OnwardBreadcrumbInactive>
                        <OnwardBreadcrumbSpacer />
                        <OnwardBreadcrumbActive>Order {order.order_number}</OnwardBreadcrumbActive>
                    </React.Fragment>
                );
        }
    }, [source]);

    const isOwnRoute = route.oms && (!route.carrier_id || route.carrier_id === user_id);
    const isBroker = circles?.['broker'] && order?.shipper_id === user_id;
    const brokerOwnsOrder =
        isBroker && !['open', 'pending', 'claimed', 'expired'].includes(order?.listing?.listing_status);
    const isOrderHandler = order?.carrier_id ? order?.carrier_id === user_id : order?.shipper_id === user_id;
    const nonLtlChecklistOrder = order.oms || accountType === 'shipper' || order.routes?.length > 0;

    if (orderLoading) {
        return (
            <ThemeProvider theme={theme}>
                <Box className={`${sidebarCollapsed && 'collapse-margin'} sidenav-margin-responsive p-0 vh-100`}>
                    <Grid
                        container
                        xs={12}
                        className="ms-5 me-5 bg-white"
                        justifyContent="center"
                        alignContent="center"
                        css={css`
                            height: 100vh;
                            width: 100vw;
                        `}
                    >
                        <CircularProgress size="200px" color="primary" />
                    </Grid>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <>
            {/* edit order modal here instead of with other modals so themeprovider doesn't mess up styling */}
            {((!!pendingMarketPlace.order && pendingMarketPlace.flow[pendingMarketPlace.step] === MODALS.EDIT_ORDER) ||
                openEditOrderModal) && (
                <EditOrderModal
                    order={openEditOrderModal ? order : pendingMarketPlace.order}
                    open={
                        (!!pendingMarketPlace.order &&
                            pendingMarketPlace.flow[pendingMarketPlace.step] === MODALS.EDIT_ORDER) ||
                        openEditOrderModal
                    }
                    opt={{
                        readOnly: openEditOrderModal
                            ? !!order.carrier_invoice_id
                            : !!pendingMarketPlace.order.carrier_invoice_id,
                        enableSingleOrderManifest: true,
                    }}
                    callbacks={
                        openEditOrderModal
                            ? {
                                  onSave: onSaveOrder,
                                  onClose: () => {
                                      setOpenEditOrderModal(false);
                                  },
                                  onError: (error) => {
                                      setNotification({
                                          severity: 'error',
                                          message: error?.message || error || `We're sorry, something went wrong.`,
                                      });
                                  },
                              }
                            : {
                                  onSave: ({ order, items: modified, itemsRemoved }) => {
                                      upsertOrder({
                                          variables: {
                                              orders: [
                                                  {
                                                      ...order,
                                                      oms: true,
                                                  },
                                              ],
                                              items: modified || [],
                                              removals: itemsRemoved || [],
                                          },
                                      }).then(
                                          () => {
                                              progressSendingToMarketplace({ order });
                                          },
                                          () => {
                                              setNotification({
                                                  severity: 'error',
                                                  message: 'Failed to modify order',
                                              });
                                              resetSendToMarketplace();
                                          }
                                      );
                                  },
                                  onClose: resetSendToMarketplace,
                                  onError: (error) => {
                                      Sentry.captureException(error);
                                      console.error(error);
                                      setNotification({
                                          severity: 'error',
                                          message: error?.message || error || `We're sorry, something went wrong.`,
                                      });
                                  },
                              }
                    }
                />
            )}

            {/* Broker accounts editing orders have different functionality than normal accounts */}
            {!!brokerEditingOrder && (
                <EditOrderModal
                    order={brokerEditingOrder}
                    open={!!brokerEditingOrder}
                    initTab={EDIT_ORDER_TABS.BROKER}
                    opt={{
                        enableBrokerTab: true,
                        readOnly: !brokerEditingOrder.oms && !!brokerEditingOrder.carrier_invoice_id,
                    }}
                    callbacks={{
                        onSave: onBrokerSaveOrder,
                        onClose: () => {
                            setBrokerEditingOrder(null);
                        },
                        onError: (error) => {
                            Sentry.captureException(error);
                            console.error(error);
                            setNotification({
                                severity: 'error',
                                message: 'Failed to save order. Please try again or contact support.',
                            });
                        },
                    }}
                />
            )}
            <ThemeProvider theme={theme}>
                <Box className={`${sidebarCollapsed && 'collapse-margin'} sidenav-margin-responsive p-0 vh-100`}>
                    <Grid container>
                        <SecondaryNavContainer container>
                            <Grid item sm={3} className="d-flex align-items-center">
                                {backButtonBar}
                            </Grid>

                            <Grid item sm={6}>
                                <Box display="flex" justifyContent="center" width="100%">
                                    <OnwardTabContainer
                                        value={tabIndex}
                                        onChange={handleTabChange}
                                        textColor="primary"
                                        indicatorColor="primary"
                                        variant={'scrollable'}
                                        scrollButtons={'auto'}
                                    >
                                        <OnwardTab
                                            label={
                                                <Grid direction="row" container>
                                                    {order.order_exception || hasExceptions ? (
                                                        <Grid
                                                            direction="column"
                                                            container
                                                            css={css`
                                                                flex-basis: 0;
                                                            `}
                                                        >
                                                            <WarningIcon
                                                                css={css`
                                                                    font-size: 20px;
                                                                    justify-content: center;

                                                                    margin-right: 8px;
                                                                `}
                                                            />
                                                        </Grid>
                                                    ) : null}
                                                    <Grid
                                                        direction="column"
                                                        container
                                                        css={css`
                                                            flex-basis: 0;
                                                        `}
                                                    >
                                                        Details
                                                    </Grid>
                                                </Grid>
                                            }
                                            value={0}
                                            classes={{
                                                root: classes.root,
                                            }}
                                            style={{ color: hasExceptions || order.order_exception ? 'orange' : null }}
                                        />
                                        <OnwardTab
                                            label={
                                                <Grid direction="row" container>
                                                    {hasExceptions ? (
                                                        <Grid
                                                            direction="column"
                                                            container
                                                            css={css`
                                                                flex-basis: 0;
                                                            `}
                                                        >
                                                            <WarningIcon
                                                                css={css`
                                                                    font-size: 20px;
                                                                    justify-content: center;

                                                                    margin-right: 8px;
                                                                `}
                                                            />
                                                        </Grid>
                                                    ) : null}
                                                    <Grid
                                                        direction="column"
                                                        container
                                                        css={css`
                                                            flex-basis: 0;
                                                        `}
                                                    >
                                                        Items
                                                    </Grid>
                                                </Grid>
                                            }
                                            value={1}
                                            style={{ color: hasExceptions ? 'orange' : null }}
                                            classes={{
                                                root: classes.root,
                                            }}
                                        />
                                        <OnwardTab
                                            label="POD/Images"
                                            value={2}
                                            classes={{
                                                root: classes.root,
                                            }}
                                        />
                                        <OnwardTab
                                            label="Communications Log"
                                            value={3}
                                            classes={{
                                                root: classes.root,
                                            }}
                                        />
                                        <OnwardTab
                                            label="Notes"
                                            value={4}
                                            classes={{
                                                root: classes.root,
                                            }}
                                        />
                                    </OnwardTabContainer>
                                </Box>
                            </Grid>
                            <Grid item sm={3} className="d-flex align-items-center justify-content-end">
                                {editable && !!order && (
                                    <>
                                        <PDFDownloadLink
                                            document={
                                                <DocumentBOL
                                                    order={order}
                                                    logoInfo={{ logo: logo, logoBackground: logoBackground }}
                                                />
                                            }
                                            fileName={`OnwardBOL_${order.order_number}.pdf`}
                                        >
                                            {({ loading, error }) => (
                                                <IconButton disabled={loading || error}>
                                                    <FileDownloadIcon style={{ color: '#59b863' }} />
                                                </IconButton>
                                            )}
                                        </PDFDownloadLink>

                                        {!isBroker && (
                                            <IconButton onClick={() => setAssignModalOpen(true)}>
                                                <AssignmentIndIcon style={{ color: '#59b863' }} />
                                            </IconButton>
                                        )}
                                    </>
                                )}
                                {editable && !isBroker && (order.oms || order.shipper_id === user_id) && (
                                    <IconButton onClick={() => setOpenEditOrderModal(true)}>
                                        <CreateIcon style={{ color: '#59b863' }} />
                                    </IconButton>
                                )}
                                {order.shipper_id === user_id &&
                                    (order.order_status === 'open' || order.order_status === 'pending') &&
                                    !order.oms &&
                                    order.routes?.length === 0 &&
                                    !order.is_middle_mile && (
                                        <IconButton onClick={() => setBackToUnassignedModalOpen(true)}>
                                            <CancelIcon style={{ color: '#59b863' }} />
                                        </IconButton>
                                    )}
                            </Grid>
                            <AssignDriverModal
                                setOpenModal={setAssignModalOpen}
                                isOpen={assignModalOpen}
                                onClose={() => setAssignModalOpen(false)}
                                drivers={drivers}
                                order={order}
                            />
                            <BackToUnassignedModal
                                isOpen={backToUnassignedModalOpen}
                                onClose={() => setBackToUnassignedModalOpen(false)}
                                handleSubmit={() =>
                                    handleSendBackToUnassigned({ listing_id: order?.listing.listing_id })
                                }
                            />
                            <BrokerDisclaimerModal
                                handleClose={resetSendToMarketplace}
                                onSuccess={() => {
                                    progressSendingToMarketplace({});
                                }}
                                open={pendingMarketPlace.flow[pendingMarketPlace.step] === MODALS.BROKER_DISCLAIMER}
                            />
                            <CreditCardModal
                                handleClose={resetSendToMarketplace}
                                onSuccess={() => {
                                    progressSendingToMarketplace({});
                                }}
                                open={pendingMarketPlace.flow[pendingMarketPlace.step] === MODALS.CREDIT_CARD}
                            />
                            <MarketplaceModal
                                open={pendingMarketPlace.flow[pendingMarketPlace.step] === MODALS.MARKETPLACE_TOC}
                                onClose={resetSendToMarketplace}
                                onSubmit={() => {
                                    progressSendingToMarketplace({});
                                }}
                                orders={
                                    pendingMarketPlace.order
                                        ? { [pendingMarketPlace.order.order_id]: pendingMarketPlace.order }
                                        : {}
                                }
                                listings={
                                    pendingMarketPlace?.order?.listing
                                        ? {
                                              [pendingMarketPlace.order.order_id]: pendingMarketPlace.order.listing,
                                          }
                                        : listing
                                }
                            />
                            <AuctionModal
                                open={
                                    openListingModal ||
                                    (!!pendingMarketPlace.order &&
                                        pendingMarketPlace.flow[pendingMarketPlace.step] === MODALS.AUCTION_DETAILS)
                                }
                                order={pendingMarketPlace.order || order}
                                listing={listing?.[order.order_id] || {}}
                                callbacks={{
                                    onClose: () => {
                                        setOpenListingModal(false);
                                        resetSendToMarketplace();
                                    },
                                    onSave: (val) => {
                                        if (pendingMarketPlace.order) {
                                            progressSendingToMarketplace({ listing: val.listing });
                                        } else {
                                            handleSendingToMarketplace({
                                                shipper_id: user_id,
                                                listings: [val.listing],
                                            });
                                        }
                                    },
                                }}
                            />
                            <ReceiveModal
                                order={order}
                                {...(receiveParams || {})}
                                callbacks={{
                                    onClose: () => {
                                        setReceiveParams(null);
                                    },
                                    onSubmit: ({ event }) => {
                                        const { action, order_id, ...rest } = event;
                                        upsertEvent({
                                            variables: {
                                                event,
                                                order: { order_id },
                                                update_cols: Object.keys(rest),
                                                order_update_cols: [],
                                            },
                                        }).then(() => {
                                            setReceiveParams(null);
                                        });
                                    },
                                }}
                            />
                            <ScheduleModal
                                order={order}
                                {...(scheduleParams || {})}
                                callbacks={{
                                    onClose: () => {
                                        setScheduleParams(null);
                                    },
                                    onSubmit: ({ event, order: update }) => {
                                        upsertEvent({
                                            variables: {
                                                event: { ...event, order_id: order.order_id },
                                                order: { ...update, order_id: order.order_id },
                                                update_cols: Object.keys(event),
                                                order_update_cols: Object.keys(update),
                                            },
                                        }).then(() => {
                                            setScheduleParams(null);
                                        });
                                    },
                                }}
                            />
                            <TimeframeModal
                                order={order}
                                {...(timeframeParams || {})}
                                callbacks={{
                                    onClose: () => {
                                        setTimeframeParams(null);
                                    },
                                    onSubmit: ({ event, order: update, sendText }) => {
                                        upsertEvent({
                                            variables: {
                                                event: { ...event, order_id: order.order_id },
                                                order: { ...update, order_id: order.order_id },
                                                update_cols: Object.keys(event),
                                                order_update_cols: Object.keys(update),
                                            },
                                        }).then((result) => {
                                            if (sendText && result?.data?.order) {
                                                post(CROSSDOCK_CONFIRM_TIMEFRAME_TEXT, {
                                                    orderId: order.order_id,
                                                })
                                                    .then(() => {
                                                        setTimeframeParams(null);
                                                        setNotification({
                                                            severity: 'success',
                                                            message: 'Customer texted, awaiting confirmation',
                                                        });
                                                    })
                                                    .catch((err) => {
                                                        captureException(err);
                                                        setNotification({
                                                            severity: 'error',
                                                            message:
                                                                'Failed to send customer timeframe confirmation text',
                                                        });
                                                    });
                                            } else {
                                                setTimeframeParams(null);
                                            }
                                        });
                                    },
                                }}
                            />
                            <AssignModal
                                order={order}
                                {...(assignParams || {})}
                                drivers={drivers}
                                callbacks={{
                                    onClose: () => {
                                        setAssignParams(null);
                                    },
                                    onSubmit: ({ event, order: update }) => {
                                        upsertEvent({
                                            variables: {
                                                event: { ...event, order_id: order.order_id },
                                                order: { ...update, order_id: order.order_id },
                                                update_cols: Object.keys(event),
                                                order_update_cols: Object.keys(update),
                                            },
                                        }).then(() => {
                                            setAssignParams(null);
                                        });
                                    },
                                }}
                            />
                            <CrossdockPickupConfigModal
                                order={order}
                                drivers={drivers}
                                {...(crossdockPickupParams || {})}
                                callbacks={{
                                    onClose: () => {
                                        setCrossdockPickupParams(null);
                                    },
                                    onSubmit: ({ event, order: update, sendConfirm }) => {
                                        const { action, order_id, ...rest } = event;

                                        upsertEvent({
                                            variables: {
                                                event,
                                                order: { ...update, order_id: order.order_id },
                                                update_cols: Object.keys(rest),
                                                order_update_cols: Object.keys(update),
                                            },
                                        }).then(({ data }) => {
                                            if (data?.event && sendConfirm) {
                                                post(CROSSDOCK_CONFIRM_TIMEFRAME_TEXT, {
                                                    orderId: order.order_id,
                                                }).catch((err) => {
                                                    captureException(err);
                                                    setNotification({
                                                        severity: 'error',
                                                        message: 'Failed to send confirmation text.',
                                                    });
                                                });
                                            }
                                        });
                                        setCrossdockPickupParams(null);
                                    },
                                }}
                            />
                            <CrossdockConfigModal
                                order={order}
                                drivers={drivers}
                                warehouses={locations}
                                {...(crossdockConfigParams || {})}
                                callbacks={{
                                    onClose: () => {
                                        setCrossdockConfigParams(null);
                                    },
                                    onSubmit: ({ event, order: update, sendConfirm }) => {
                                        const { action, order_id, ...rest } = event;

                                        upsertEvent({
                                            variables: {
                                                event,
                                                order: { ...update, order_id: order.order_id },
                                                update_cols: Object.keys(rest),
                                                order_update_cols: Object.keys(update),
                                            },
                                        }).then(({ data }) => {
                                            if (data?.event && sendConfirm) {
                                                post(CROSSDOCK_CONFIRM_TIMEFRAME_TEXT, {
                                                    orderId: order.order_id,
                                                }).catch((err) => {
                                                    captureException(err);
                                                    setNotification({
                                                        severity: 'error',
                                                        message: 'Failed to send confirmation text.',
                                                    });
                                                });
                                            }
                                        });
                                        setCrossdockConfigParams(null);
                                    },
                                }}
                            />
                            <PalletDimensionsModal
                                order={order}
                                open={palletDimensionsParams}
                                callbacks={{
                                    onClose: () => {
                                        setPalletDimensionsParams(null);
                                    },

                                    onSubmit: ({ order: update }) => {
                                        upsertOrderDynamic({
                                            variables: {
                                                orders: [
                                                    {
                                                        order_id: order.order_id,
                                                        ...update,
                                                    },
                                                ],
                                                order_update_cols: Object.keys(update),
                                                items: [],
                                                removals: [],
                                                item_update_cols: [],
                                            },
                                        }).then(() => {
                                            setPalletDimensionsParams(null);
                                        });
                                    },
                                }}
                            />
                            <ConfirmationModal
                                open={confirmation.content}
                                content={confirmation.content}
                                callbacks={{
                                    onClose: () => {
                                        setConfirmation({});
                                    },
                                    onConfirm: () => confirmation.callback().then(() => setConfirmation({})),
                                }}
                            />
                            <ChecklistModal
                                open={checklist.content}
                                order={order}
                                {...(checklist || {})}
                                items={order.itemsByOrderId}
                                callbacks={{
                                    onClose: () => {
                                        setChecklist({});
                                    },
                                    onConfirm: (params) => checklist.callback(params).then(() => setChecklist({})),
                                }}
                            />
                            <UpdateItemPieceCountModal
                                items={order?.itemsByOrderId || []}
                                open={!!editingItemPieceCount}
                                onClose={() => setEditingItemPieceCount(false)}
                                order={order}
                            />
                            <ExceptionResolutionModal
                                {...resolveExceptionParams}
                                loading={resolveInflight || submitInflight}
                                callbacks={{
                                    onClose: () => {
                                        setResolveExceptionParams({});
                                    },
                                    onResolve: ({ toSubmit, items = [], order = {}, exception = {}, event = {} }) => {
                                        const toRemove = items.map((item) => item.item_id);
                                        const { order_id, ...orderUpdates } = order;
                                        const { event_id, ...eventUpdates } = event;
                                        const { exception_id, ...exceptionUpdates } = exception;

                                        return Promise.all([
                                            resolve({
                                                variables: {
                                                    orders: order_id ? [order_id] : [],
                                                    events: event_id ? [event_id] : [],
                                                    exceptions: exception_id ? [exception_id] : [],
                                                    items: toRemove,
                                                    order_update: orderUpdates,
                                                    event_update: eventUpdates,
                                                    exception_update: exceptionUpdates,
                                                    include_route_update: false,
                                                },
                                            }),
                                            ...(toSubmit
                                                ? [
                                                      submitOrders({
                                                          client_id: toSubmit.shipper_id,
                                                          orders: [toSubmit],
                                                          filename: undefined,
                                                          type: 'MANUAL',
                                                      }),
                                                  ]
                                                : []),
                                        ]).then(([, resp]) => {
                                            if (resp?.data?.jobs?.length > 0) {
                                                navigate(`/job/${resp.data.jobs[0]}`);
                                            } else {
                                                setResolveExceptionParams({});
                                            }
                                        });
                                    },
                                }}
                            />
                        </SecondaryNavContainer>

                        {tabIndex === 0 && (
                            // Order Details
                            <Grid container className="h-100">
                                {order?.source_form === 'CUSTOMER' &&
                                    (order.shipper_id === user_id || isOnwardAdmin) &&
                                    !['complete', 'cancelled'].includes(order.order_status) && (
                                        <>
                                            <Grid item sm={1}></Grid>
                                            <Grid container item sm={10} className="mt-4">
                                                <Grid item>
                                                    <PrimaryButton onClick={() => setEditingItemPieceCount(true)}>
                                                        {order?.piece_count_verified
                                                            ? 'Update Piece Count'
                                                            : 'Verify Piece Count'}
                                                    </PrimaryButton>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={1}></Grid>
                                        </>
                                    )}
                                {circles?.[ROCKET_MM_INTEGRATION] && (
                                    <>
                                        <Grid item sm={1}></Grid>
                                        <Grid container item sm={10} className="mt-4">
                                            <Grid item>
                                                <PrimaryButton
                                                    disabled={rocketUpdatesLoading}
                                                    variant="contained"
                                                    onClick={(e) =>
                                                        fetchRocketStatusUpdates({
                                                            order_id: order?.order_id,
                                                            loadsId: order?.middle_mile_integration_meta?.loadsId,
                                                            shipper_id: order?.shipper_id,
                                                        })
                                                    }
                                                >
                                                    Fetch Rocket Status Updates
                                                </PrimaryButton>
                                            </Grid>
                                            {!!superUser?.user_id && (
                                                <Grid
                                                    item
                                                    css={css`
                                                        margin-left: 2rem;
                                                    `}
                                                >
                                                    <PrimaryButton
                                                        disabled={manualRocketPushLoading}
                                                        variant="contained"
                                                        onClick={(e) => handleManualRocketPush()}
                                                    >
                                                        Manually Push Order to Rocket
                                                    </PrimaryButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid item sm={1}></Grid>
                                    </>
                                )}
                                <Grid item sm={1}></Grid>
                                {/* <Grid container sm={10}> */}
                                <Grid container item sm={10} className="mb-5">
                                    <Grid
                                        item
                                        sm={12}
                                        className="d-flex justify-content-between align-items-center mt-4"
                                    >
                                        <div className="d-flex">
                                            <Typography style={{ marginRight: 20 }} variant="h1">
                                                {order.order_number}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {orderStatusStrings[order.order_status]}
                                                {editable && !isBroker && (
                                                    <ArrowDropDown
                                                        id="menuArrow"
                                                        onClick={handleClick}
                                                        aria-controls={openMenu ? 'statusMenu' : undefined}
                                                        aria-expanded={openMenu ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                    />
                                                )}
                                            </Typography>
                                            <Menu
                                                open={openMenu}
                                                id={'statusMenu'}
                                                onClose={handleClose}
                                                anchorEl={anchorEl}
                                                getContentAnchorEl={null}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                MenuListProps={{
                                                    'aria-labelledby': 'menuArrow',
                                                }}
                                            >
                                                <MenuItem
                                                    disabled={!editable}
                                                    onClick={() =>
                                                        handleSubmitStatus(order.oms ? 'pending' : 'claimed')
                                                    }
                                                >
                                                    {order.oms ? 'Pending' : 'Claimed'}
                                                </MenuItem>
                                                <MenuItem
                                                    disabled={
                                                        !editable ||
                                                        (order.wh_events || []).some((e) => e.action.includes('ADD_CD'))
                                                    }
                                                    onClick={() => handleSubmitStatus('inProgress')}
                                                >
                                                    In Progress
                                                </MenuItem>
                                                <MenuItem
                                                    disabled={!editable}
                                                    onClick={() => handleSubmitStatus('complete')}
                                                >
                                                    Complete
                                                </MenuItem>
                                            </Menu>
                                        </div>

                                        {(order.order_status === 'complete' ||
                                            order.order_status === 'routedComplete') &&
                                            accountType === 'shipper' &&
                                            !isOwnRoute &&
                                            (carrierReview ? (
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor: '#59B863',
                                                        color: 'white',
                                                        marginLeft: 'auto',
                                                        marginRight: '22px',
                                                    }}
                                                    onClick={() => setCarrierReviewOpen(true)}
                                                >
                                                    Edit Carrier Review
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor: '#59B863',
                                                        color: 'white',
                                                        marginLeft: 'auto',
                                                        marginRight: '22px',
                                                    }}
                                                    onClick={() => setCarrierReviewOpen(true)}
                                                >
                                                    Leave Carrier Review
                                                </Button>
                                            ))}
                                        {!brokerOwnsOrder && (
                                            <OnwardPricePopper
                                                order={order}
                                                callbacks={{
                                                    onSend: () => {
                                                        initiateSendToMarketplace(isBroker);
                                                    },
                                                }}
                                            />
                                        )}
                                    </Grid>

                                    {circles?.['broker'] && (
                                        <Grid
                                            item
                                            sm={12}
                                            className="d-flex justify-content-between align-items-center mt-3 mb-3"
                                        >
                                            <PrimaryButton
                                                disabled={upsertLoading || !brokerOwnsOrder}
                                                variant="contained"
                                                style={{ flexDirection: 'row' }}
                                                onClick={(e) => setBrokerEditingOrder(order)}
                                            >
                                                <div>
                                                    <EditOutlinedIcon />
                                                    <> Edit Order Details</>
                                                </div>
                                            </PrimaryButton>

                                            {brokerOwnsOrder && (
                                                <PrimaryButton
                                                    disabled={upsertLoading}
                                                    variant="contained"
                                                    onClick={() => {
                                                        initiateSendToMarketplace(isBroker);
                                                    }}
                                                >
                                                    <div>
                                                        <Store />
                                                        Send to Onward Marketplace
                                                    </div>
                                                </PrimaryButton>
                                            )}
                                        </Grid>
                                    )}

                                    <CarrierReviewModal
                                        isOpen={carrierReviewOpen}
                                        reviewObject={carrierReview}
                                        orderId={order.order_id}
                                        revieweeId={order.carrier_id ? order.carrier_id : order.shipper_id}
                                        revieweeType={order.carrier_id ? 'CARRIER' : 'SHIPPER'}
                                        driverId={route?.driverId}
                                        callbacks={{
                                            onClose: () => setCarrierReviewOpen(false),
                                        }}
                                    />

                                    {/* Only allow user to edit the budget if user is a carrier */}
                                    {accountType === 'carrier' && order.customer_budget ? (
                                        <Grid container className="mb-4 mt-4">
                                            <CarrierEditBudget order={order} />
                                        </Grid>
                                    ) : null}

                                    {(order?.exceptions || [])
                                        .filter(
                                            (mapping) =>
                                                mapping.exception_type === 'ORDER' &&
                                                mapping.exception.status !== 'RESOLVED'
                                        )
                                        .map((mapping) => {
                                            return (
                                                <Grid container className="mt-3">
                                                    <ExceptionCard
                                                        exception={mapping.exception}
                                                        order={order}
                                                        callbacks={{
                                                            setExceptionParams,
                                                            setResolveExceptionParams,
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        })}

                                    {order?.itemsByOrderId?.map((item, i) => {
                                        (item?.exceptions || [])
                                            .filter((mapping) => mapping.exception.status !== 'RESOLVED')
                                            .map((mapping) => {
                                                return (
                                                    <Grid key={i} container className="mt-4">
                                                        <ExceptionCard
                                                            exception={mapping.exception}
                                                            order={order}
                                                            exceptionItem={item}
                                                            callbacks={{
                                                                setExceptionParams,
                                                                setResolveExceptionParams,
                                                            }}
                                                        />
                                                    </Grid>
                                                );
                                            });
                                    })}

                                    <Grid container className="mt-3">
                                        <>
                                            {finalMileDriver ? (
                                                <Grid item container justifyContent={'flex-start'}>
                                                    <Typography
                                                        className="my-2"
                                                        css={css`
                                                            color: ${colors.greys.primary};
                                                            font-weight: 600;
                                                            font-size: 16px;
                                                        `}
                                                    >
                                                        Driver: {finalMileDriver?.username}{' '}
                                                        {toNational(finalMileDriver?.phone)}
                                                    </Typography>
                                                </Grid>
                                            ) : null}
                                            {crossDockDriver ? (
                                                <Grid item container justifyContent={'flex-start'}>
                                                    <Typography
                                                        className="my-2"
                                                        css={css`
                                                            color: ${colors.greys.primary};
                                                            font-weight: 600;
                                                            font-size: 16px;
                                                        `}
                                                    >
                                                        Cross-dock Driver: {crossDockDriver?.username}{' '}
                                                        {toNational(crossDockDriver?.phone)}
                                                    </Typography>
                                                </Grid>
                                            ) : null}
                                        </>
                                        {order.listing &&
                                            order?.listing.listing_status === 'claimed' &&
                                            order.order_carrier && (
                                                <OnwardCard
                                                    style={{
                                                        marginTop: '40px',
                                                        marginBottom: '40px',
                                                        backgroundColor: '#59B863',
                                                    }}
                                                >
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={1}>
                                                            <StoreIcon fontSize="large" style={{ color: 'white' }} />
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                                className="my-2"
                                                                style={{ color: 'white' }}
                                                                variant="body1"
                                                            >
                                                                Sent to Onward Marketplace
                                                            </Typography>
                                                            <Typography
                                                                className="my-2"
                                                                style={{ color: 'white' }}
                                                                variant="body2"
                                                            >
                                                                Order claimed.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </OnwardCard>
                                            )}

                                        {order?.messages?.some((m) => m.link_type === 'customer_predelivery') &&
                                            isOrderHandler && (
                                                <CustomerAddressConfirmationCard
                                                    order={order}
                                                    goToCommsTab={() => {
                                                        setTabIndex(3);
                                                    }}
                                                    setNotification={setNotification}
                                                />
                                            )}

                                        {(accountType === 'carrier' || isOnwardAdmin) &&
                                            order?.order_carrier?.dispatch_integration_type && (
                                                <Grid
                                                    container
                                                    className="d-flex justify-content-between align-items-start"
                                                    spacing={4}
                                                    style={{ marginBottom: 0, marginTop: 0 }}
                                                >
                                                    <Grid item xs={12} style={{ height: '100%' }}>
                                                        <IntegrationPartnerCard
                                                            order={order}
                                                            integrationName={
                                                                order.order_carrier.dispatch_integration_type
                                                            }
                                                            setNotification={setNotification}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}

                                        {!brokerOwnsOrder ? (
                                            nonLtlChecklistOrder ? (
                                                <OrderProgress order={order} />
                                            ) : (
                                                <CrossdockingCard
                                                    order={order}
                                                    isLoading={eventUpsertLoading}
                                                    warehouse={warehouse}
                                                    events={warehouseEvents?.results || []}
                                                    callbacks={{
                                                        editCrossdockPickup: (params) => {
                                                            setCrossdockPickupParams(params);
                                                        },
                                                        editCrossdock: (params) => {
                                                            setCrossdockConfigParams(params);
                                                        },
                                                        editPalletDimensions: () => {
                                                            setPalletDimensionsParams(true);
                                                        },
                                                        upsertEvent: ({ event, order: update = {} }) => {
                                                            const { action, order_id, ...rest } = event;

                                                            return upsertEvent({
                                                                variables: {
                                                                    event,
                                                                    order: { ...update, order_id: order.order_id },
                                                                    update_cols: Object.keys(rest),
                                                                    order_update_cols: Object.keys(update),
                                                                },
                                                            });
                                                        },
                                                        editReceiveEvent: (params) => setReceiveParams(params),
                                                        editScheduled: (params) => setScheduleParams(params),
                                                        editTimeframe: (params) => setTimeframeParams(params),
                                                        editAssigned: (params) => setAssignParams(params),
                                                        confirmOrder: () => {
                                                            const update = { delivery_time_confirmed: 'confirmed' };
                                                            upsertOrderDynamic({
                                                                variables: {
                                                                    orders: [
                                                                        {
                                                                            order_id: order.order_id,
                                                                            ...update,
                                                                        },
                                                                    ],
                                                                    order_update_cols: Object.keys(update),
                                                                    items: [],
                                                                    removals: [],
                                                                    item_update_cols: [],
                                                                },
                                                            });
                                                        },
                                                        sendConfirmationText: () => {
                                                            post(CROSSDOCK_CONFIRM_TIMEFRAME_TEXT, {
                                                                orderId: order.order_id,
                                                                forceRetry: true,
                                                            })
                                                                .then(() => {
                                                                    setNotification({
                                                                        severity: 'success',
                                                                        message:
                                                                            'Customer texted, awaiting confirmation',
                                                                    });
                                                                })
                                                                .catch((error) => {
                                                                    captureException(error);
                                                                    setNotification({
                                                                        severity: 'error',
                                                                        message:
                                                                            'Failed to send customer timeframe confirmation text',
                                                                    });
                                                                });
                                                        },
                                                        createException: (params) => {
                                                            setExceptionParams(params);
                                                        },
                                                        confirmAction: (content, callback) => {
                                                            setConfirmation({ content, callback });
                                                        },
                                                        openChecklist: (params, callback) => {
                                                            setChecklist({
                                                                ...params,
                                                                callback,
                                                            });
                                                        },
                                                        sendWeblink: (event) => {
                                                            sendWeblink(order.order_id, event.event_id);
                                                        },
                                                        gotoFileTab: () => {
                                                            setTabIndex(2);
                                                        },
                                                    }}
                                                />
                                            )
                                        ) : null}
                                    </Grid>

                                    {brokerOwnsOrder && (
                                        <Grid
                                            container
                                            className="d-flex justify-content-between align-items-start"
                                            spacing={4}
                                            style={{ marginBottom: 0, marginTop: 0 }}
                                        >
                                            <Grid item xs={6} style={{ height: '100%' }}>
                                                <BrokerCard order={order} />
                                            </Grid>
                                        </Grid>
                                    )}

                                    {(accountType === 'carrier' || order.oms) && (
                                        <>{renderNotesCardIfExists('Carrier Notes', order.carrier_notes)}</>
                                    )}

                                    {order.has_middle_mile_origin ? (
                                        <Grid
                                            container
                                            className="d-flex justify-content-between align-items-start"
                                            spacing={4}
                                            style={{ marginBottom: 0, marginTop: 0 }}
                                        >
                                            <Grid item xs={12} style={{ height: '100%' }}>
                                                <MiddleMileCard order={order} />
                                            </Grid>
                                        </Grid>
                                    ) : null}

                                    <Grid
                                        container
                                        spacing={4}
                                        css={css`
                                            margin-top: 0;
                                            margin-bottom: 0;
                                            flex-wrap: nowrap;
                                            justify-content: space-between;
                                        `}
                                    >
                                        <Grid item css={cardFrag}>
                                            <PickUpCard order={order} editable={editable} user_id={user_id} />
                                        </Grid>
                                        {isFtlCrossdocked ? (
                                            <Grid item css={cardFrag}>
                                                <WHCrossdockCard order={order} editable={editable} user_id={user_id} />
                                            </Grid>
                                        ) : null}
                                        {['SERVICE', 'SHIPMENT', 'PICKUP_AND_WILL_CALL'].includes(order.job_type) ? (
                                            <Grid item css={cardFrag}>
                                                <DropOffCard order={order} editable={editable} user_id={user_id} />
                                            </Grid>
                                        ) : null}
                                        {['WILL_CALL', 'PICKUP_AND_WILL_CALL'].includes(order.job_type) ? (
                                            <Grid item css={cardFrag}>
                                                <WillCallCard order={order} />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                    <Grid
                                        container
                                        className="d-flex justify-content-between align-items-start"
                                        spacing={4}
                                        style={{ marginBottom: 0, marginTop: 0 }}
                                    >
                                        <Grid item xs={6}>
                                            <OrderSummaryCard
                                                order={order}
                                                editable={editable}
                                                userType={accountType}
                                                editPalletDimensions={() => {
                                                    setPalletDimensionsParams(true);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item container xs={6} direction="column">
                                            <Grid item style={{ marginBottom: '16px' }}>
                                                <Grid container>
                                                    <WarehousingCard
                                                        warehouse={warehouse}
                                                        order={order}
                                                        warehouseEditable={warehouseEditable}
                                                        editable={editable}
                                                        refetchOrder={refetchOrder}
                                                        setNotification={setNotification}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ marginTop: '16px' }}>
                                                {order?.carrier_id ? (
                                                    <Grid container>
                                                        <CarrierInfoCard carrier={order?.order_carrier} />
                                                    </Grid>
                                                ) : (
                                                    <EmptyCard
                                                        title={'Carrier Info'}
                                                        text={
                                                            'Carrier info displays when orders are claimed from the Onward Marketplace. You may send orders to the marketplace from the Unassigned page, or on order creation. If you do not have access to this feature, reach out to Onward admins.'
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {order?.routes?.length > 0 && editable && (
                                        <Grid container className="mt-2">
                                            <RoutedOrderCard order={order} editable={editable} />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item sm={1}></Grid>
                            </Grid>
                        )}

                        {tabIndex === 1 && (
                            <OrderItems
                                order={order}
                                setExceptionParams={setExceptionParams}
                                setResolveExceptionParams={setResolveExceptionParams}
                            />
                        )}

                        {tabIndex === 2 && (
                            <OrderImages
                                userType={accountType}
                                editable={editable || isBroker}
                                onImgView={setViewImg}
                                order={order}
                            />
                        )}

                        {tabIndex === 3 && (
                            <Grid container className="h-100">
                                <Grid item xs={1} />
                                <Grid item xs={10}>
                                    <Grid
                                        container
                                        className="d-flex justify-content-between align-items-center mt-3 mb-5"
                                    >
                                        <Typography variant="h1">{order.order_number}</Typography>
                                        <Typography variant="subtitle2">{orderStatus}</Typography>
                                    </Grid>
                                    <Grid container>
                                        <CommunicationLog
                                            orderNumber={order.order_number}
                                            customerName={order.dropoff_name}
                                            customerPhone={order.dropoff_phone}
                                            customerZip={order.dropoff_zip}
                                            order={order}
                                            order_id={order.order_id}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} />
                            </Grid>
                        )}

                        {tabIndex === 4 && <Notes order={order} editable={editable} />}
                    </Grid>
                </Box>
                {viewImg && <Lightbox large={viewImg} hideZoom={true} onClose={() => setViewImg('')} />}
            </ThemeProvider>

            {/* Modal */}
            <AssignedToRouteErrorModal
                open={assignedToRouteErrorModal}
                onClose={(e) => setAssignedToRouteErrorModal(false)}
            />
            <ExceptionModal
                {...exceptionParams}
                loading={insertExceptionEventLoading || insertExceptionLoading || updateExceptionLoading}
                order={order}
                callbacks={{
                    onError: (e) => {
                        setNotification({
                            severity: 'error',
                            message: 'Failed to upload image. Please try again',
                        });
                    },
                    onSubmitCreateAnother: ({ exception }) => {
                        return createException(exception);
                    },
                    onSubmit: ({ exception }) => {
                        return createException(exception).then(() => {
                            setExceptionParams(null);
                        });
                    },
                    onClose: () => setExceptionParams(null),
                }}
            />

            {/* Notification */}
            <Snackbar
                open={!!notification?.message}
                onClose={() => setNotification(null)}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                autoHideDuration={10000}
            >
                <Alert
                    onClose={() => setNotification(null)}
                    severity={notification?.severity}
                    elevation={6}
                    variant="filled"
                >
                    {notification?.message}
                    {notification?.clickable && notification.clickable}
                </Alert>
            </Snackbar>
        </>
    );
}

export default OrderDetailsPage;
