export const MODALS = {
    MULTI_PICKUP: 'MULTI_PICKUP',
    CSV_UPLOAD: 'CSV_UPLOAD',
    PICKUP_INFO: 'PICKUP_INFO',
    CSV_HEADERS: 'CSV_HEADERS',
    INITIAL_DELIVERY: 'INITIAL_DELIVERY',
    SAVE_TEMPLATE: 'SAVE_TEMPLATE',
    ITEMS_VIEW: 'ITEMS_VIEW',
    ITEMS_EDIT: 'ITEMS_EDIT',
    CONTACT: 'CONTACT',
    HAULAWAY: 'HAULAWAY',
    DELIVERY_TYPE_EDIT: 'DELIVERY_TYPE_EDIT',
    AVAILABILITY: 'AVAILABILITY',
    MARKETPLACE: 'MARKETPLACE',
    CREDIT_CARD: 'CREDIT_CARD',
    SUBMIT_VALIDATION_MODAL: 'SUBMIT_VALIDATION_MODAL',
    EDIT_PICKUP_INFO_MODAL: 'EDIT_PICKUP_INFO_MODAL',
    PO_DUPLICATE_CONFIRMATION: 'PO_DUPLICATE_CONFIRMATION',
    PDF_UPLOAD: 'PDF_UPLOAD',
};

export const SHIPMENT_FORM_STATE = {
    CREATE: 'CREATE',
    REVIEW: 'REVIEW',
};

export const SHIPMENT_FORM_TYPES = {
    MANUAL: 'MANUAL',
    CSV: 'CSV',
    PDF: 'PDF',
};

export const PROGRESSION_TYPES = {
    EDIT_PICKUP: 'EDIT_PICKUP',
    PO_CONFIRM: 'PO_CONFIRM',
    PO_CONFIRM_FINALIZE_MARKETPLACE_JOB_PAYMENT: 'PO_CONFIRM_FINALIZE_MARKETPLACE_JOB_PAYMENT',
    PO_CONFIRM_FINALIZE_MARKETPLACE_JOB: 'PO_CONFIRM_FINALIZE_MARKETPLACE_JOB',
    FINALIZE_MARKETPLACE_JOB_PAYMENT: 'FINALIZE_MARKETPLACE_JOB_PAYMENT',
    FINALIZE_MARKETPLACE_JOB: 'FINALIZE_MARKETPLACE_JOB',
    PREPARE_MANUAL: 'PREPARE_MANUAL',
    PREPARE_CSV: 'PREPARE_CSV',
    PREPARE_CSV_WITH_SAVE: 'PREPARE_CSV_WITH_SAVE',
    PREPARE_PDF: 'PREPARE_PDF',
};

export const SHIPMENT_FORM_MODAL_PROGRESSION = {
    [PROGRESSION_TYPES.EDIT_ORDER_PICKUP]: [MODALS.PICKUP_INFO],
    [PROGRESSION_TYPES.PO_CONFIRM]: [MODALS.PO_DUPLICATE_CONFIRMATION],
    [PROGRESSION_TYPES.PO_CONFIRM_FINALIZE_MARKETPLACE_JOB_PAYMENT]: [
        MODALS.PO_DUPLICATE_CONFIRMATION,
        MODALS.MARKETPLACE,
        MODALS.CREDIT_CARD,
    ],
    [PROGRESSION_TYPES.PO_CONFIRM_FINALIZE_MARKETPLACE_JOB]: [MODALS.PO_DUPLICATE_CONFIRMATION, MODALS.MARKETPLACE],
    [PROGRESSION_TYPES.FINALIZE_MARKETPLACE_JOB_PAYMENT]: [MODALS.MARKETPLACE, MODALS.CREDIT_CARD],
    [PROGRESSION_TYPES.FINALIZE_MARKETPLACE_JOB]: [MODALS.MARKETPLACE],
    [PROGRESSION_TYPES.PREPARE_MANUAL]: [MODALS.PICKUP_INFO],
    [PROGRESSION_TYPES.PREPARE_CSV]: [MODALS.MULTI_PICKUP, MODALS.CSV_UPLOAD, MODALS.PICKUP_INFO, MODALS.CSV_HEADERS],
    [PROGRESSION_TYPES.PREPARE_PDF]: [MODALS.PDF_UPLOAD, MODALS.PICKUP_INFO],
    [PROGRESSION_TYPES.PREPARE_CSV_WITH_SAVE]: [
        MODALS.MULTI_PICKUP,
        MODALS.CSV_UPLOAD,
        MODALS.PICKUP_INFO,
        MODALS.CSV_HEADERS,
        MODALS.SAVE_TEMPLATE,
    ],
};

export const ITEM_REQUIRED_FIELDS = ['height', 'width', 'length', 'cubes_per_unit', 'weight'];

export const EDIT_ORDER_TABS = {
    PICKUP: 'PICKUP',
    CUSTOMER: 'CUSTOMER',
    ORDER_DETAILS: 'ORDER_DETAILS',
    ITEMS: 'ITEMS',
    HAUL_AWAY: 'HAUL_AWAY',
    ADMIN: 'ADMIN',
    WAREHOUSE: 'WAREHOUSE',
    NOTES: 'NOTES',
    BROKER: 'BROKER',
};

export const EDIT_ORDER_TABS_DISPLAY = {
    [EDIT_ORDER_TABS.PICKUP]: 'Pickup',
    [EDIT_ORDER_TABS.CUSTOMER]: 'Customer',
    [EDIT_ORDER_TABS.ORDER_DETAILS]: 'Order Details',
    [EDIT_ORDER_TABS.ITEMS]: 'Items',
    [EDIT_ORDER_TABS.HAUL_AWAY]: 'Haulaway',
    [EDIT_ORDER_TABS.ADMIN]: 'Admin',
    [EDIT_ORDER_TABS.NOTES]: 'Notes',
    [EDIT_ORDER_TABS.WAREHOUSE]: 'Warehousing',
    [EDIT_ORDER_TABS.BROKER]: 'Broker',
};

export const DEFAULT_ITEM = {
    quantity: 1,
    is_return: false,
    is_packaged: true,
};

export const DEFAULT_HAUL_AWAY = {
    quantity: 1,
    is_return: false,
    is_packaged: true,
    return_or_dispose: 'dispose',
};

export const AIRLINE_OPTIONS = ['Accelerated', 'American Airlines', 'Delta', 'FedEx', 'Forward Air', 'UPS'];
