import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import _ from 'lodash';

function render(status) {
    if (status === Status.FAILURE) {
        return (
            <div
                style={{
                    height: '350px',
                    borderRadius: '5px',
                    backgroundColor: 'rgb(50,50,50)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ErrorOutline />
                <Typography>There was an error loading the map</Typography>
            </div>
        );
    }

    // else status === Status.LOADING
    return <Skeleton variant="rect" height="350px" />;
}

function _GoogleMap({ forwardRef, ...props }) {
    const { className, onClick, style, children, onBoundsChange, ...options } = props;

    const ref = useRef(null);
    const map = useRef(null);
    const [prev, setPrev] = useState(null);

    useDeepCompareEffect(() => {
        if (ref.current && !map?.current && options) {
            const gmap = new window.google.maps.Map(ref.current, options);
            map.current = gmap;

            if (forwardRef) {
                forwardRef.current = gmap;
            }
        }
    }, [ref, map, options]);

    useEffect(() => {
        if (!options.bounds || !map?.current || options.bounds.length === 0) {
            return;
        }

        const mapBounds = new window.google.maps.LatLngBounds();
        options.bounds.forEach((coords) => {
            mapBounds.extend(coords);
        });

        if (!prev || !mapBounds.getCenter().equals(prev.getCenter())) {
            setPrev(mapBounds);
            map.current.fitBounds(mapBounds);
        }
    }, [options.bounds]);

    useEffect(() => {
        let listener;
        if (map?.current && onBoundsChange) {
            listener = window.google.maps.event.addListener(map.current, 'bounds_changed', () => {
                const bounds = map.current.getBounds();

                onBoundsChange(bounds);
            });
        }

        return () => {
            window.google.maps.event.removeListener(listener);
        };
    }, [map, onBoundsChange]);

    // Effect to clear and re-add listeners (if a listener passed as prop is updated)
    useEffect(() => {
        if (map?.current) {
            // Clear listeners
            ['click'].forEach((eventName) => window.google.maps.event.clearListeners(map.current, eventName));
            // Re-add
            if (onClick) {
                map.current.addListener('click', onClick);
            }
        }
    }, [map, onClick]);

    useEffect(() => {
        const { center, zoom } = options;
        if (map?.current && (center || zoom)) {
            if (center === undefined) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };

                            map.current.setOptions({ ...options, center: pos, zoom });
                        },
                        () => {
                            // geo location declined, default to zoomed out Denver
                            map.current.setOptions({ ...options, center: { lat: 39.74, lng: -104.99 }, zoom: 5 });
                        }
                    );
                } else {
                    map.current.setOptions({ ...options, center: { lat: 0, lng: 0 } });
                }
            } else {
                map.current.setOptions(options);
            }
        }
    }, [map.current, options.center, options.zoom]);

    return (
        <>
            <div className={`map-container ${className}`} ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    if (child.props.children && child.type === Symbol.for('react.fragment')) {
                        return (
                            <React.Fragment>
                                {Array.isArray(child.props.children)
                                    ? child.props.children
                                          .filter((_child) => _child !== null)
                                          .map((_child) => {
                                              return React.cloneElement(_child, {
                                                  map: map.current,
                                              });
                                          })
                                    : React.cloneElement(child.props.children, {
                                          map: map.current,
                                      })}
                            </React.Fragment>
                        );
                    }
                    return React.cloneElement(child, { map: map.current });
                }
            })}
        </>
    );
}

export { default as Marker } from './Marker';
export { default as DirectionsRenderer } from './DirectionsRenderer';
export { default as InfoWindow } from './InfoWindow';

function GoogleMap({ forwarded, defaultMap, ...rest }) {
    return (
        <Wrapper render={render}>
            <_GoogleMap
                {...rest}
                draggableCursor="pointer"
                gestureHandling="greedy"
                {...(defaultMap ? {} : { mapId: 'd3247097a38c69d0' })}
                zoomControlOptions={{
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                }}
                streetViewControlOptions={{
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                }}
                forwardRef={forwarded}
            />
        </Wrapper>
    );
}

export default React.forwardRef((props, ref) => {
    return <GoogleMap {...props} forwarded={ref} />;
});
