import useShippersAndCarriers from '@/hooks/useShippersAndCarriers';
import { OnwardSwitch, PrimaryButton, Row, SecondaryButton, SectionSubtitle } from '@/styles/blocks';
import { Body1, Body2, TextField } from '../../blocks';
import { css } from '@emotion/react';
import { Grid, Tooltip } from '@material-ui/core';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { ModalContext } from '.';
import { COMPLETE_STATUSES } from '../../constants/statusOptions';
import {
    ClientSelect,
    DateInput,
    InfoInput,
    PriceInput,
    StatusSelect,
    TimeSelect,
    ClientMultiselect,
} from '../InputFields';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { useSwappedAttributes } from '../../hooks';
import { Alert } from '@material-ui/lab';
import { post } from '@/utilities/onwardClient';
import { CUSTOMER_REQUEST_PAYMENT, NOTIFY_CARRIERS_OF_LTL_ORDER } from '@/constants/apiRoutes';
import { withStyles } from '@material-ui/core/styles';
import useTrackedComms from '@/hooks/useTrackedComms';
import Snackbar from '@/components/Snackbar';
import { SOURCE_MAPPINGS } from '../../constants/admin';

const CustomTooltip = withStyles(() => ({
    tooltip: {
        fontSize: 14,
    },
}))(Tooltip);

const numberValOrDefault = (numberVal, defaultVal) => {
    if (numberVal || numberVal === 0) {
        return numberVal;
    }
    return defaultVal;
};

const black = css`
    color: black;
`;

const AdminTab = ({ hasError, opt }) => {
    const { enableAdminTestAccounts } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;
    const [selectedCarriersForNotifying, setSelectedCarriersForNotifying] = useState([]);
    const [lockedClients, setLockedClients] = useState([]);
    const [saving, setSaving] = useState(false);
    const [notifyCarriersMessage, setNotifyCarriersMessage] = useState('');
    const [customerLoading, setCustomerLoading] = useState(false);

    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const { zip } = useSwappedAttributes(order);

    const [shippers, carriers] = useShippersAndCarriers(enableAdminTestAccounts);

    const [shipRate, carrRate, margin] = useMemo(() => {
        const shipRate = numberValOrDefault(order.admin_shipper_rate_override, order.shipper_rate);
        const carrRate = numberValOrDefault(order.admin_carrier_rate_override, order.carrier_rate);

        if (shipRate && carrRate) {
            const diff = shipRate - carrRate;
            return [shipRate, carrRate, `$${diff.toFixed(2)} (${((diff / shipRate) * 100).toFixed(2)}%)`];
        }

        return [shipRate, carrRate, 'N/A'];
    }, [order]);

    const orderTZ = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : 'America/New_York';
    }, [order, zip]);

    const handleNotifyCarriers = async () => {
        setSaving(true);
        setNotifyCarriersMessage('');
        try {
            const response = await post(NOTIFY_CARRIERS_OF_LTL_ORDER, {
                carriers: selectedCarriersForNotifying.map((carrier) => ({
                    business_name: carrier.business_name,
                    email: carrier.email,
                    contract_rates: !!carrier?.user?.circles?.['hide-market-rates'],
                    client_id: carrier.client_id,
                })),
                order,
            });

            setLockedClients([...lockedClients, ...(response?.data?.successes || [])]);

            if (response?.data?.failures.length) {
                setNotifyCarriersMessage(
                    `Partial success: There was an error attempting to notify the following carriers: ${response?.data?.failures
                        .map((carrier) => carrier.business_name)
                        .join(', ')}`
                );
            } else {
                setNotifyCarriersMessage('All carriers were successfully notified.');
            }
        } catch (e) {
            setNotifyCarriersMessage('There was an error attempting to notify carriers.');
            console.error(e);
        } finally {
            setSelectedCarriersForNotifying([]);
            setSaving(false);
        }
    };

    const disableNotifyCarriersButton = useMemo(() => {
        if (saving) return { disabled: true, reason: 'Notifying carriers already currently in progress.' };
        if (order.order_status !== 'open')
            return { disabled: true, reason: `Cannot notify carriers unless order is in 'Open' order status` };
        if (!selectedCarriersForNotifying.length)
            return { disabled: true, reason: 'No new carriers have been selected for notifying.' };
        return false;
    }, [saving, order, selectedCarriersForNotifying]);

    const isMarketplaceOrder = useMemo(() => {
        if (!order) return false;
        return (
            ['pending', 'open'].includes(order?.order_status) &&
            !order?.oms &&
            !order.routes?.length &&
            !order.carrier_id
        );
    }, [order]);

    const { loading: trackedCommsLoading, tracked_communications } = useTrackedComms(
        order.order_id,
        !isMarketplaceOrder
    );

    const handleCopyLink = async (linkTo) => {
        let link;
        if (linkTo === 'loadboard') {
            link = `${window.location.host}/carrier/loads/ltl/${order.order_id}`;
        } else {
            link = `${window.location.host}/order/${order.order_id}`;
        }
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(link);
        } else {
            document.execCommand('copy', true, link);
        }
        if (linkTo === 'loadboard') {
            setSuccessMsg('Successfully copied order link to loadboard.');
        } else {
            setSuccessMsg('Successfully copied link to order details page.');
        }
    };

    const syncDateAndTimeframes = (newDate, oldWindowStart, oldWindowEnd) => {
        const newDateObj = new Date(newDate);
        const newWindowStart = new Date(oldWindowStart);
        newWindowStart.setDate(newDateObj.getUTCDate());
        newWindowStart.setMonth(newDateObj.getUTCMonth());
        newWindowStart.setFullYear(newDateObj.getFullYear());

        const newWindowEnd = new Date(oldWindowEnd);
        newWindowEnd.setDate(newDateObj.getUTCDate());
        newWindowEnd.setMonth(newDateObj.getUTCMonth());
        newWindowEnd.setFullYear(newDateObj.getUTCFullYear());

        return { newStartIso: newWindowStart.toISOString(), newEndIso: newWindowEnd.toISOString() };
    };
    let cantCopy = order.order_status !== 'open' || !!order?.carrier_id || !!order?.routes?.length || !!order?.oms;

    return (
        <>
            <Grid container direction="column" wrap="nowrap">
                <Row>
                    <StatusSelect
                        routed={Boolean(order.routes?.length)}
                        value={order.order_status}
                        disabled={['on_hold_awaiting_payment'].includes(order.order_status)}
                        onChange={(status) =>
                            callbacks.modifyOrder({
                                order_status: status,
                                ...(COMPLETE_STATUSES.includes(status) &&
                                !COMPLETE_STATUSES.includes(order.order_status)
                                    ? {
                                          completion_time: new Date().toISOString(),
                                          completion_source: 'WEB_ONWARD_ADMIN',
                                      }
                                    : {}),
                            })
                        }
                    />
                </Row>
                <Row>
                    <ClientSelect
                        value={order.shipper_id}
                        options={shippers}
                        onChange={(shipperId) => callbacks.modifyOrder({ shipper_id: shipperId })}
                        allowEmpty={false}
                        type="shipper"
                        css={css`
                            margin-right: 0.5rem;
                        `}
                    />
                    <ClientSelect
                        value={order.carrier_id}
                        options={carriers}
                        onChange={(carrierId) =>
                            callbacks.modifyOrder({
                                carrier_id: carrierId,
                                is_middle_mile: false,
                                ...(order.order_status === 'open' ? { order_status: 'claimed' } : {}),
                            })
                        }
                        disabled={!!order.oms}
                        allowEmpty={true}
                        type="carrier"
                        css={css`
                            margin-left: 0.5rem;
                        `}
                    />
                </Row>
                <Row>
                    <OnwardSwitch
                        label={'Marketplace Order'}
                        checked={!order.oms}
                        onChange={() => callbacks.modifyOrder({ oms: !order.oms })}
                        disabled={order.routes?.length}
                    />
                </Row>
                {isMarketplaceOrder && !trackedCommsLoading && (
                    <>
                        <Row>
                            <ClientMultiselect
                                clients={carriers}
                                lockedClients={[
                                    ...(tracked_communications?.map((i) => i.client) || []),
                                    ...lockedClients,
                                ]}
                                value={selectedCarriersForNotifying}
                                onChange={(newValue) => {
                                    setNotifyCarriersMessage('');
                                    setSelectedCarriersForNotifying([...newValue]);
                                }}
                            />
                        </Row>
                        <Row
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            {disableNotifyCarriersButton ? (
                                <CustomTooltip title={disableNotifyCarriersButton.reason}>
                                    <span>
                                        <PrimaryButton onClick={handleNotifyCarriers} disabled={true}>
                                            Notify Carriers
                                        </PrimaryButton>
                                    </span>
                                </CustomTooltip>
                            ) : (
                                <PrimaryButton onClick={handleNotifyCarriers}>Notify Carriers</PrimaryButton>
                            )}
                            {cantCopy ? (
                                <CustomTooltip
                                    title={
                                        'Order must be open and have no carrier assigned to copy the loadboard link.'
                                    }
                                >
                                    <span>
                                        <PrimaryButton disabled={true}>Copy Loadboard Link</PrimaryButton>
                                    </span>
                                </CustomTooltip>
                            ) : (
                                <PrimaryButton onClick={(e) => handleCopyLink('loadboard')}>
                                    Copy Loadboard Link
                                </PrimaryButton>
                            )}
                            <PrimaryButton onClick={(e) => handleCopyLink('order_details')}>
                                Copy Order Details Link
                            </PrimaryButton>
                        </Row>

                        {notifyCarriersMessage && (
                            <Row>
                                <span>{notifyCarriersMessage}</span>
                            </Row>
                        )}
                    </>
                )}
                {!isMarketplaceOrder && (
                    <Row
                        css={css`
                            justify-content: flex-end;
                        `}
                    >
                        <PrimaryButton onClick={(e) => handleCopyLink('order_details')}>
                            Copy Order Details Link
                        </PrimaryButton>
                    </Row>
                )}
                <Row>
                    <SectionSubtitle
                        css={css`
                            color: black;
                        `}
                    >
                        Rates
                    </SectionSubtitle>
                </Row>
                <Row>
                    <PriceInput
                        value={order.admin_shipper_rate_override}
                        label="Shipper Rate Override"
                        onChange={(shipperRate) => callbacks.modifyOrder({ admin_shipper_rate_override: shipperRate })}
                        css={css`
                            margin-right: 0.5rem;
                        `}
                    />
                    <PriceInput
                        value={order.admin_carrier_rate_override}
                        label="Carrier Rate Override"
                        onChange={(carrierRate) => callbacks.modifyOrder({ admin_carrier_rate_override: carrierRate })}
                        css={css`
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                        `}
                    />
                    <InfoInput
                        value={margin}
                        label="Margin"
                        css={css`
                            margin-left: 0.5rem;
                        `}
                    />
                </Row>
                <Row>
                    <InfoInput
                        value={order.shipper_rate ? order.shipper_rate.toFixed(2) : 'N/A'}
                        label="Calculated Shipper Rate"
                        css={css`
                            margin-right: 0.5rem;
                        `}
                    />
                    <InfoInput
                        value={order.carrier_rate ? order.carrier_rate.toFixed(2) : 'N/A'}
                        label="Calculated Carrier Rate"
                        css={css`
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                        `}
                    />
                    <SecondaryButton
                        onClick={() =>
                            callbacks.modifyOrder({
                                admin_carrier_rate_override: null,
                                admin_shipper_rate_override: null,
                            })
                        }
                        css={css`
                            margin-left: 0.5rem;
                            padding: 0;
                            width: 100%;
                        `}
                    >
                        Reset Rates
                    </SecondaryButton>
                </Row>
                {order.source_form === 'CUSTOMER' && (
                    <>
                        <Row>
                            <InfoInput
                                value={order.customer_rate ? order.customer_rate.toFixed(2) : 'N/A'}
                                label="Customer Authorized Amount"
                                css={css`
                                    margin-right: 0.5rem;
                                `}
                            />
                        </Row>
                        <Row>
                            <PrimaryButton
                                disabled={customerLoading}
                                onClick={() => {
                                    setCustomerLoading(true);
                                    post(CUSTOMER_REQUEST_PAYMENT, { order_id: order.order_id })
                                        .then((result) => {
                                            // todo: close edit modal / refetch ?
                                            setSuccessMsg(`${result?.data?.message || ''} Order placed on hold.`);
                                        })
                                        .catch((error) => {
                                            setErrorMsg(
                                                error?.response?.data?.error || 'Error notifying customer of new price.'
                                            );
                                        })
                                        .finally(() => {
                                            setCustomerLoading(false);
                                        });
                                }}
                            >
                                Request Customer Updated Payment
                            </PrimaryButton>
                        </Row>
                    </>
                )}
                <Row>
                    <SectionSubtitle
                        css={css`
                            color: black;
                        `}
                    >
                        Scheduling
                    </SectionSubtitle>
                </Row>
                {order.routes?.length > 0 && (
                    <Row>
                        <Alert severity="warning">Order is routed, update time frames in Planning instead.</Alert>
                    </Row>
                )}
                <Row>
                    <DateInput
                        value={order.pickup_date}
                        onChange={(newPickupDate) => {
                            if (order.pickup_window_start && order.pickup_window_end) {
                                const { newStartIso, newEndIso } = syncDateAndTimeframes(
                                    newPickupDate,
                                    order.pickup_window_start,
                                    order.pickup_window_end
                                );

                                callbacks.modifyOrder({
                                    pickup_date: newPickupDate,
                                    pickup_window_start: newStartIso,
                                    pickup_window_end: newEndIso,
                                });
                            } else {
                                callbacks.modifyOrder({
                                    pickup_date: newPickupDate,
                                });
                            }
                        }}
                        label="Pickup Date"
                        tz={orderTZ}
                        firstAvailable={order.first_available_date}
                        css={css`
                            margin-right: 0.5rem;
                        `}
                        disabled={order.routes?.length}
                        error={hasError.pickup_date}
                    />
                    <DateInput
                        value={order.delivery_date}
                        onChange={(newDeliveryDate) => {
                            if (order.del_window_start && order.del_window_end) {
                                const { newStartIso, newEndIso } = syncDateAndTimeframes(
                                    newDeliveryDate,
                                    order.del_window_start,
                                    order.del_window_end
                                );

                                callbacks.modifyOrder({
                                    delivery_date: newDeliveryDate,
                                    del_window_start: newStartIso,
                                    del_window_end: newEndIso,
                                });
                            } else {
                                callbacks.modifyOrder({
                                    delivery_date: newDeliveryDate,
                                });
                            }
                        }}
                        label="Delivery Date"
                        tz={orderTZ}
                        firstAvailable={order.first_available_date}
                        css={css`
                            margin-left: 0.5rem;
                        `}
                        disabled={order.routes?.length}
                        error={hasError.delivery_date}
                    />
                </Row>
                <Row>
                    <TimeSelect
                        value={order.pickup_window_start}
                        label="Pickup Start"
                        tz={orderTZ}
                        date={order.pickup_date}
                        onChange={(val) => callbacks.modifyOrder({ pickup_window_start: val })}
                        css={css`
                            margin-right: 0.5rem;
                        `}
                        disabled={order.routes?.length || !order.pickup_date}
                        error={hasError.pickup_window}
                    />
                    <TimeSelect
                        value={order.pickup_window_end}
                        label="Pickup End"
                        tz={orderTZ}
                        date={order.pickup_date}
                        onChange={(val) => callbacks.modifyOrder({ pickup_window_end: val })}
                        css={css`
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                        `}
                        disabled={order.routes?.length || !order.pickup_date}
                        error={hasError.pickup_window}
                    />
                    <TimeSelect
                        value={order.del_window_start}
                        label="Delivery Start"
                        tz={orderTZ}
                        date={order.delivery_date}
                        onChange={(val) => callbacks.modifyOrder({ del_window_start: val })}
                        css={css`
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                        `}
                        disabled={order.routes?.length || !order.delivery_date}
                        error={hasError.delivery_window}
                    />
                    <TimeSelect
                        value={order.del_window_end}
                        label="Delivery End"
                        tz={orderTZ}
                        date={order.delivery_date}
                        onChange={(val) => callbacks.modifyOrder({ del_window_end: val })}
                        css={css`
                            margin-left: 0.5rem;
                        `}
                        disabled={order.routes?.length || !order.delivery_date}
                        error={hasError.delivery_window}
                    />
                </Row>
                <Row>
                    <SectionSubtitle
                        css={css`
                            color: black;
                        `}
                    >
                        Admin Notes
                    </SectionSubtitle>
                </Row>
                <Row>
                    <TextField
                        label="Admin Notes"
                        placeholder="Admin Notes (only admins can see these)"
                        variant="outlined"
                        name="desc"
                        fullWidth
                        value={order.onward_notes || null}
                        onChange={(e) => callbacks.modifyOrder({ onward_notes: e.target.value })}
                        multiline
                        rows={5}
                    />
                </Row>
                <Row>
                    <SectionSubtitle css={black}>Additional Info</SectionSubtitle>
                </Row>
                <Row>
                    <Body2 css={black}>Source: {SOURCE_MAPPINGS[order.source_form] || 'Unknown'}</Body2>
                </Row>
                {order.source_form === 'CUSTOMER' && order.payment_id ? (
                    <Row>
                        <Body2 css={black}>Stripe PI: {order.payment_id}</Body2>
                    </Row>
                ) : null}
            </Grid>

            <Snackbar
                severity="success"
                open={!!successMsg}
                message={successMsg}
                autohide={2000}
                handleClose={() => setSuccessMsg('')}
                disableClose={true}
            />
            <Snackbar
                severity="error"
                open={!!errorMsg}
                message={errorMsg}
                autohide={2000}
                handleClose={() => setErrorMsg('')}
                disableClose={true}
            />
        </>
    );
};

export default AdminTab;
