import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { gql } from '@apollo/client';

export const UPDATE_CLIENT = gql`
    ${CLIENT_FIELDS}
    mutation UpdateClient($client_id: uuid!, $update: clients_set_input!) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: $update) {
            ...ClientFields
        }
    }
`;
